import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  updateDoc,
  deleteField,
  deleteDoc,
  onSnapshot,
} from 'firebase/firestore';
import { MatDialog } from '@angular/material/dialog';
import { DataStoreExport, Font } from '../interfaces';
import { EditFontComponent } from './dialog/edit-font/edit-font.component';
import { DeleteFontComponent } from './dialog/delete-font/delete-font.component';
import { dataStore, db } from '../app.component';

@Component({
  selector: 'app-fonts',
  templateUrl: './fonts.component.html',
  styleUrls: ['./fonts.component.scss'],
})
export class FontsComponent implements OnInit {
  dataStore: DataStoreExport = dataStore;
  townshipId = dataStore.township.id;
  organisationId = dataStore.organisation?.id;
  fonts: Font[] = [];

  constructor(
    public db: AngularFirestore,
    public afAuth: AngularFireAuth,
    public dialog: MatDialog
  ) {}

  async ngOnInit() {
    onSnapshot(
      collection(db, `township/${this.townshipId}/fonts`),
      (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          const data = { ...change.doc.data(), id: change.doc.id } as Font;
          if (change.type === 'added') {
            if (!data.default) {
              data.default = false;
            }
            this.fonts.push(data);
          }
          if (change.type === 'modified') {
            const index = this.fonts.findIndex((font) => font.id === data.id);
            this.fonts[index] = data;
          }
          if (change.type === 'removed') {
            this.fonts = this.fonts.filter((font) => font.id !== data.id);
          }
        });
        this.sortFonts();
      }
    );
    onSnapshot(
      collection(db, `globalSettings/global/fonts`),
      { includeMetadataChanges: true },
      (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          const data = {
            ...change.doc.data(),
            id: change.doc.id,
          } as Font;
          if (change.type === 'added') {
            this.fonts.push(data);
          }
          if (change.type === 'modified') {
            const index = this.fonts.findIndex((font) => font.id === data.id);
            this.fonts[index] = data;
          }
          if (change.type === 'removed') {
            this.fonts = this.fonts.filter((font) => font.id !== data.id);
          }
        });
        this.sortFonts();
      }
    );
  }

  openEditFont(event, font?) {
    event.stopPropagation();
    if (font?.default === true) {
      return;
    }
    this.dialog.open(EditFontComponent, {
      width: '500px',
      autoFocus: false,
      data: { font, townshipId: this.townshipId },
    });
  }

  async delete(event, font: Font) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(DeleteFontComponent, {
      width: '475px',
      autoFocus: false,
      data: { font },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        const q = query(
          collection(db, `township/${this.townshipId}/fonts`),
          where('Font', '==', font.id)
        );
        const allFonts = await getDocs(q);
        const promises: any[] = [];
        allFonts.forEach((Font) => {
          promises.push(
            updateDoc(
              doc(db, `township/${this.townshipId}/fonts`, `${Font.id}`),
              {
                Font: deleteField(),
              }
            )
          );
        });
        promises.push(
          deleteDoc(doc(db, `township/${this.townshipId}/fonts`, `${font.id}`))
        );
      }
    });
  }
  sortFonts() {
    this.fonts.sort((a, b) => {
      return (
        Number(b.default) - Number(a.default) || a.name.localeCompare(b.name)
      );
    });
  }
}
