import { Component, OnInit, Inject } from '@angular/core';
import { Theme, Township, VoucherGroup } from 'src/app/interfaces';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ShareThemedVoucherGroupComponent } from '../share-themed-voucher-group/share-themed-voucher-group.component';
import { ThemedVoucherGroupEditComponent } from '../themed-voucher-group-edit/themed-voucher-group-edit.component';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { encodeRouteParam, iframeUrl } from 'src/app/globals';

export interface DialogData {
  townshipId: string;
  voucherGroup: VoucherGroup;
  theme: Theme;
}
@Component({
  selector: 'app-voucher-group-detail',
  templateUrl: './voucher-group-detail.component.html',
  styleUrls: ['./voucher-group-detail.component.scss'],
})
export class VoucherGroupDetailComponent implements OnInit {
  env = environment;
  theme = this.data.theme;
  voucherGroupDoc: AngularFirestoreDocument<VoucherGroup>;
  voucherGroup: Observable<VoucherGroup>;
  voucherGroupName: string;
  tab: number = 1;
  shareUrl: string = '';
  comment: string = '';
  public chartScheme = {
    domain: [
      //Beschikbaar
      '#62C590',
      //Geclaimd/Betaald
      '#FC9F5B',
      //Niet Beschikbaar
      '#808080',
    ],
  };
  // public chartScheme = {
  //   domain: environment.chartScheme,
  //   // '#62C590' - Beschikbaar
  //   // '#cff584' - Gereserveerd
  //   // '#F5DC69' - Geactiveerd
  //   // '#FC9F5B' - Geclaimd
  //   // '#1A697F' - Betaald
  //   // '#808080' - Niet Beschikbaar
  // };
  public chartView = [246, 246];
  toegangsAvailable: number;
  vouchersAvailable: number;
  users: any = [];

  constructor(
    public db: AngularFirestore,
    public dialog: MatDialog,
    private snackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<VoucherGroupDetailComponent>
  ) {}

  async ngOnInit() {
    if (this.env.prefix != 'toegangs') {
      this.chartScheme = { domain: environment.chartScheme };
    }

    this.shareUrl = await this.getIframeUrl(this.data.voucherGroup);
    this.voucherGroupDoc = this.db.doc(
      `/township/${this.data.townshipId}/voucherGroups/${this.data.voucherGroup.id}`
    );
    this.voucherGroup = this.voucherGroupDoc.snapshotChanges().pipe(
      map((actions) => {
        const voucherGroup = actions.payload.data() as VoucherGroup;
        const availableVouchers =
          voucherGroup.statistics?.totalVouchers > voucherGroup.maxVouchers
            ? voucherGroup.maxVouchers -
              voucherGroup.statistics.totalActivatedVouchers
            : voucherGroup.statistics?.availableVouchers;
        if ((this.env.prefix = 'toegangs')) {
          const usedVouchers =
            voucherGroup.statistics?.paidVouchers ??
            0 + voucherGroup.statistics?.claimedVouchers ??
            0 + voucherGroup.statistics?.activatedVouchers ??
            0;

          voucherGroup.statisticsChart = [
            {
              name: 'Beschikbaar',
              value: availableVouchers ?? 0,
              show: true,
            },
            {
              name: 'Uitgegeven',
              value: usedVouchers,
              show: true,
            },
          ];
        } else {
          voucherGroup.statisticsChart = [
            {
              name: 'Beschikbaar',
              value: availableVouchers ?? 0,
              show: true,
            },
            {
              name: 'Gereserveerd',
              value: voucherGroup.reservedVouchers ?? 0,
              show: true,
            },
            {
              name: 'Geactiveerd',
              value: voucherGroup.statistics?.activatedVouchers ?? 0,
              show: true,
            },
            {
              name: 'Geclaimd',
              value: voucherGroup.statistics?.claimedVouchers ?? 0,
              show: true,
            },
            {
              name: 'Betaald',
              value: voucherGroup.statistics?.paidVouchers ?? 0,
              show: true,
            },
          ];
        }
        if (voucherGroup.statistics?.totalVouchers < voucherGroup.maxVouchers) {
          voucherGroup.statisticsChart.push({
            name: 'Niet beschikbaar',
            value:
              voucherGroup.maxVouchers - voucherGroup.statistics?.totalVouchers,
            show: true,
          });
        }
        return { ...voucherGroup };
      })
    );
    this.voucherGroup.subscribe((voucherGroup) => {
      this.voucherGroupName = this.capitalizeFirstLetter(voucherGroup.name);
      console.log('voucherGroup', voucherGroup);
    });

    const q = query(
      collection(
        this.db.firestore,
        `township/${localStorage.getItem('township')}/vouchers`
      ),
      where('voucherGroupId', '==', this.data.voucherGroup.id)
    );
    const voucherCol = await getDocs(q);
    voucherCol.forEach((voucher) => {
      let voucherData = voucher.data();
      if (voucherData.name) {
        this.users.push(voucherData);
      }
    });
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  changeTab(number) {
    this.tab = number;
  }

  async openShareThemedVoucherGroup() {
    this.dialog.open(ShareThemedVoucherGroupComponent, {
      width: '500px',
      data: {
        townshipId: this.data.townshipId,
        voucherGroup: this.data.voucherGroup,
        theme: this.theme,
        details: true,
      },
      autoFocus: false,
    });
  }

  editVoucher() {
    this.dialogRef.close();
    let panelClass = [];
    panelClass.push('fullscreen-dialog');
    this.dialog.open(ThemedVoucherGroupEditComponent, {
      width: '500px',
      data: {
        voucherGroup: this.data.voucherGroup,
        townshipId: this.data.townshipId,
        theme: this.data.theme,
        newGroup: false,
      },
      disableClose: true,
      panelClass,
    });
  }

  copyLink() {
    navigator.clipboard.writeText(this.shareUrl);
    this.snackbar.open('Link gekopieerd.', 'X', {
      duration: 3000,
    });
  }

  async getIframeUrl(voucherGroup: VoucherGroup, language?: string) {
    let url = await iframeUrl(
      this.data.townshipId,
      this.db.firestore,
      language
    );
    let type = 'activate-tb';
    if (voucherGroup.type === 'sell') {
      type = 'buy';
    }
    const encodeTownshipId = encodeRouteParam(localStorage.getItem('township'));
    const encodeVoucherGroupId = encodeRouteParam(voucherGroup.id);
    url = `${url}/${type}/${encodeTownshipId}/${encodeVoucherGroupId}`;
    return url;
  }
}
