// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.report-container {
  display: flex;
  flex-direction: column;
  height: 90vh;
}

.report-frame {
  flex: 1;
  width: 100%;
  height: 100%;
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/app/statistics/statistics.component.scss"],"names":[],"mappings":"AAeA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;AAdF;;AAiBA;EACE,OAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;AAdF","sourcesContent":["// .page {\n// }\n// .widgets-wrapper {\n//   display: flex;\n//   flex-direction: row;\n//   flex-wrap: wrap;\n//   width: 100%;\n// }\n\n// @media only screen and (max-width: 649px) and (min-width: 0px) {\n//   .widget-wrapper {\n//     width: 100% !important;\n//   }\n// }\n\n.report-container {\n  display: flex;\n  flex-direction: column;\n  height: 90vh;\n}\n\n.report-frame {\n  flex: 1;\n  width: 100%;\n  height: 100%;\n  border: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
