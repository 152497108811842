import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  doc,
  getDoc,
  collection,
  getDocs,
  query,
  where,
  onSnapshot,
  QueryConstraint,
  orderBy,
} from 'firebase/firestore';
import {
  OrganisationTag,
  OrgUser,
  Tag,
  User,
  Voucher,
  VoucherGroup,
} from '../interfaces';
import { ManageAssignmentComponent } from './dialogs/manage-assignment/manage-assignment.component';
import { VoucherStatus } from '../enums';
import { dataStore, db } from '../app.component';
// import { OrderComponent } from './dialogs/order/order.component';

export interface AssignmentVoucher extends Voucher {
  voucherGroupName?: string;
  voucherGroupQuestionaireLink?: string;
  voucherGroupQuestionaireText?: string;
  assignmentStatus?: 'new' | 'in_progress' | 'done' | 'declined' | 'expired';
}

@Component({
  selector: 'app-assignments',
  templateUrl: './assignments.component.html',
  styleUrls: ['./assignments.component.scss'],
})
export class AssignmentsComponent implements OnInit {
  user: User = dataStore.rootUser;
  orgUser: OrgUser;
  vouchers: AssignmentVoucher[] = [];
  filterForm: UntypedFormGroup;
  rights: string;
  filteredVouchers: AssignmentVoucher[] = [];
  totalFilteredVouchers: number;
  additionalContraints: QueryConstraint[] = [];
  statuses: any[] = [
    { val: 'new', name: 'Nieuw', bool: true, amount: 0 },
    { val: 'in_progress', name: 'In behandeling', bool: true, amount: 0 },
    { val: 'done', name: 'Afgehandeld', bool: true, amount: 0 },
    { val: 'declined', name: 'Geweigerd', bool: true, amount: 0 },
    { val: 'expired', name: 'Verlopen', bool: true, amount: 0 },
  ];
  valuesToCheck = [
    'number',
    'name',
    'voucherGroupName',
    'email',
    'activateOrganisation',
    'claimOrganisation',
    'responsibles',
    'contactedOn',
    'appointmentDate',
    'materials',
    'notes',
    'phone',
    'postal',
    'street',
  ];
  orgTags?: Map<string, OrganisationTag> = new Map();
  voucherGroups: Map<string, VoucherGroup> = new Map();
  voucherGroupsWithRights = [];
  loaded = false;

  constructor(
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar
  ) {}

  async ngOnInit() {
    this.filterForm = this.fb.group({
      search: [''],
      new: true,
      in_progress: true,
      done: false,
      declined: false,
      expired: false,
    });

    const voucherGroupDocs = await getDocs(
      collection(db, `township/${this.user.township}/voucherGroups`)
    );
    voucherGroupDocs.forEach((voucherGroupDoc) => {
      const voucherGroup = voucherGroupDoc.data() as VoucherGroup;
      voucherGroup.id = voucherGroupDoc.id;
      this.voucherGroups.set(voucherGroup.id, voucherGroup);
    });
    if (dataStore.organisation) {
      this.orgUser = (
        await getDoc(
          doc(
            db,
            `township/${this.user.township}/organisations/${this.user.organisation}/users/${this.user.id}`
          )
        )
      ).data() as OrgUser;
      if (this.orgUser.name) {
        this.filterForm.addControl('only_yours', new FormControl(true));
        this.statuses.push({
          val: 'only_yours',
          name: 'Alleen jouw opdrachten inzien',
          bool: true,
          primaryColor: true,
        });
      }
      this.rights = this.orgUser.rights;
      const orgTagsDocs = await getDocs(
        collection(
          db,
          `township/${this.user.township}/organisations/${this.user.organisation}/tags`
        )
      );
      orgTagsDocs.forEach((orgTagDoc) => {
        const orgTag = orgTagDoc.data() as OrganisationTag;
        orgTag.id = orgTagDoc.id;
        this.orgTags.set(orgTag.id, orgTag);
      });
      let voucherGroupsWithRights = [];
      this.voucherGroups.forEach((voucherGroup) => {
        if (voucherGroup.tagIds && voucherGroup.tagIds.length > 0) {
          voucherGroup.tagIds.forEach((tagId) => {
            const tag = this.orgTags.get(tagId);
            if (tag && tag.claimRights) {
              voucherGroupsWithRights.push(voucherGroup.id);
            }
          });
        }
      });
      if (voucherGroupsWithRights.length > 0) {
        this.voucherGroupsWithRights = voucherGroupsWithRights;
        this.additionalContraints.push(
          where('voucherGroupId', 'in', voucherGroupsWithRights)
        );
      } else {
        this.additionalContraints.push(where('voucherGroupId', 'in', [null])); // organisation(user) has no rights to any voucherGroup
      }
    }
    const vouchersQuery = query(
      collection(db, '/township/' + dataStore.township.id + '/vouchers'),
      where('distributed', '==', true),
      ...this.additionalContraints,
      orderBy('activateDate')
    );
    onSnapshot(vouchersQuery, (querySnapshot) => {
      this.vouchers = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data() as AssignmentVoucher;
        let assignmentStatus = 'new';
        if (data.assignmentStatus) {
          assignmentStatus = data.assignmentStatus;
        }
        if (data.claimDate && data.voucherGroupId !== 'expired') {
          assignmentStatus = 'done';
        }
        if (data.status === VoucherStatus.expired) {
          assignmentStatus = 'expired';
        }
        data.assignmentStatus = assignmentStatus as any;
        const voucherGroup = this.voucherGroups.get(data.voucherGroupId);

        data.voucherGroupName = voucherGroup?.name;
        data.voucherGroupQuestionaireLink = voucherGroup?.questionaireLink;
        data.voucherGroupQuestionaireText = voucherGroup?.questionaireLinkText;
        this.vouchers.push({ id: doc.id, ...data });
      });
      let statusIndex = 0;
      this.statuses.forEach((status) => {
        let vouchersWithCorrectStatus = this.vouchers.filter((voucher) => {
          let passesFilter = true;
          if (voucher.assignmentStatus !== status.val) {
            passesFilter = false;
          }
          if (passesFilter) {
            return true;
          } else {
            return false;
          }
        });
        this.statuses[statusIndex].amount = vouchersWithCorrectStatus.length;
        statusIndex = statusIndex + 1;
      });
      this.filter();
      this.filterForm.valueChanges.subscribe(() => {
        this.filter();
      });
      this.loaded = true;
    });
  }

  filter() {
    const filters = this.filterForm.value;
    const filteredVouchers = this.vouchers.filter((voucher) => {
      let passesSearchFilter = true;
      let passesStatusFilter = true;
      let passesResponsibleFilter = true;
      if (filters.search.length > 0) {
        passesSearchFilter = false;
        const searchVal = filters.search.toLowerCase();
        this.valuesToCheck.forEach((element) => {
          if (this.search(voucher, element, searchVal)) {
            passesSearchFilter = true;
          }
        });
      }
      if (filters.only_yours) {
        if (!voucher.responsibles) {
          passesResponsibleFilter = false;
        } else if (!voucher.responsibles.includes(this.orgUser.name)) {
          passesResponsibleFilter = false;
        }
      }
      if (!filters[voucher.assignmentStatus]) {
        passesStatusFilter = false;
      }
      if (passesSearchFilter && passesStatusFilter && passesResponsibleFilter) {
        return true;
      } else {
        return false;
      }
    });
    this.filteredVouchers = filteredVouchers;
    this.totalFilteredVouchers = filteredVouchers.length;
  }

  search(obj: any, valueToCheck: string, searchVal: string) {
    if (!obj[valueToCheck]) {
      return false;
    } else if (typeof obj[valueToCheck] === 'string') {
      if (obj[valueToCheck].toLowerCase().includes(searchVal)) {
        return true;
      }
    } else if (obj[valueToCheck] instanceof Array) {
      let foundString = false;
      obj[valueToCheck].forEach((string: string) => {
        if (string.toLowerCase().includes(searchVal)) {
          return (foundString = true);
        }
      });
      return foundString;
    } else {
      return false;
    }
  }

  getCurrencyString(number) {
    if (number.toString().indexOf('.') == -1) {
      return `€${number.toString()},-`;
    }
    return `€${number.toFixed(2).replace('.', ',')}`;
  }
  async openVoucher(voucher: AssignmentVoucher) {
    const voucherRef = doc(
      db,
      `township/${this.user.township}/vouchers/${voucher.number}`
    );
    const voucherGroup = this.voucherGroups.get(voucher.voucherGroupId);
    const voucherGroupTagsRef = collection(
      db,
      `township/${this.user.township}/voucherGroups/${voucher.voucherGroupId}/tags`
    );
    const voucherGroupTagsDocs = await getDocs(voucherGroupTagsRef);
    let orgHasClaimRights = !voucherGroupTagsDocs.empty ? false : true;
    voucherGroupTagsDocs.forEach((voucherGroupTagDoc) => {
      const voucherGroupTag = voucherGroupTagDoc.data() as Tag;
      voucherGroupTag.id = voucherGroupTagDoc.id;
      const orgTag = this.orgTags.get(voucherGroupTag.id);
      if (orgTag && orgTag.claimRights) {
        orgHasClaimRights = true;
      }
    });
    const dialogRef = this.dialog.open(ManageAssignmentComponent, {
      width: '560px',
      data: {
        voucher: { ...voucher },
        voucherRef,
        voucherGroup,
        org: dataStore.organisation ?? null,
        orgHasClaimRights,
        userRights: this.rights,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (!result.from) {
          result.from = 'new';
        }
        if (result.from !== result.to) {
          this.snackBar.open(
            `De bon #${voucher.number} is gewijzigd van ${this.getStatusName(
              result.from
            ).toLowerCase()} naar ${this.getStatusName(
              result.to
            ).toLowerCase()} en is mogelijk niet meer zichtbaar.`,
            '',
            {
              duration: 3000,
            }
          );
        }
      }
    });
  }
  getStatusName(statusVal: string) {
    const status = this.statuses.find((status) => {
      return status.val === statusVal;
    });
    return status.name;
  }
  openQuestionaireLink(Link: string) {
    window.open(Link, '_blank');
  }
}
