import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { setDoc, doc } from 'firebase/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Font } from 'src/app/interfaces';
import { db } from 'src/app/app.component';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface DialogData {
  font: Font;
  townshipId?: string;
}

@Component({
  selector: 'app-edit-font',
  templateUrl: './edit-font.component.html',
  styleUrls: ['./edit-font.component.scss'],
})
export class EditFontComponent implements OnInit {
  saving = false;
  newFont = false;
  fontId: string;
  fontStorageRefPath: string;
  fontForm: UntypedFormGroup;
  fontFile: File;

  constructor(
    public db: AngularFirestore,
    private storage: AngularFireStorage,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: UntypedFormBuilder,
    private snackbar: MatSnackBar,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditFontComponent>
  ) {}

  ngOnInit(): void {
    this.fontForm = this.fb.group({
      name: [, Validators.required],
    });
    if (this.data.font) {
      this.fontId = this.data.font.id;
      this.fontStorageRefPath = this.data.font.storageRefPath;
      this.fontForm.patchValue(this.data.font);
    } else {
      this.fontId = this.db.createId();
      this.newFont = true;
    }
  }

  async save() {
    if (this.saving) {
      return;
    }
    this.saving = true;
    const saveObj = { ...this.fontForm.value } as Font;
    if (this.newFont) {
      saveObj.id = this.fontId;
      saveObj.storageRefPath = `${this.data.townshipId}/fonts/${this.fontFile.name}`;
      // Save values to tb :)
      const uploadTask = await this.storage.upload(
        saveObj.storageRefPath,
        this.fontFile
      );
      console.log('downloadUrl', await uploadTask.ref.getDownloadURL());
    } else {
      saveObj.storageRefPath = this.fontStorageRefPath;
    }

    await setDoc(
      doc(db, `/township/${this.data.townshipId}/fonts/${this.fontId}`),
      { ...saveObj },
      { merge: true }
    );
    this.dialogRef.close();
  }

  openFileInput(id) {
    const element: HTMLElement = document.getElementById(id) as HTMLElement;
    element.click();
  }
  uploadedFile(event, id) {
    const file = event.target.files[0] as File;
    if (!file) {
      return;
    }
    if (file.name.includes('.ttf') || file.name.includes('.otf')) {
      if (id === 'font') {
        if (file.name) {
          this.fontFile = file;
          this.fontForm.controls.name.setValue(file.name.split('.', 1)[0]);
        } else {
          console.error('file has no name?');
        }
      }
    } else {
      this.snackbar.open(
        `Ongeldig lettertypebestand. Het gekozen bestand moet OTF of TTF zijn.`,
        'X',
        {
          duration: 10000,
        }
      );
      event.target.value = null;
      return;
    }
  }

  getError(name) {
    const field = this.fontForm.get(name);
    if (field.touched || !field.pristine) {
      if (field.hasError('required')) {
        return 'Dit veld moet ingevuld zijn.';
      }
      return '';
    }
  }
}
