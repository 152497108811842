import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  DocumentReference,
  arrayUnion,
  deleteDoc,
  deleteField,
  doc,
  getDoc,
  increment,
  updateDoc,
} from 'firebase/firestore';
import {
  BurdenOfProofForm,
  BurdenOfProofHistory,
  MailDataObj,
  Township,
  Voucher,
  VoucherGroup,
} from 'src/app/interfaces';
import {
  BurdenOfProofStatus,
  BadlyInsulatedParts,
  VoucherCheckStatus,
} from 'src/app/enums';
import { SettingsComponent } from 'src/app/settings/settings.component';
import moment from 'moment';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { encodeRouteParam, iframeUrl } from 'src/app/globals';
import { lastValueFrom } from 'rxjs';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { numberInput } from 'angular-custom-validators';
import { httpsCallable } from 'firebase/functions';
import { functions } from 'src/app/app.component';

@Component({
  selector: 'app-burden-of-proof-dialog',
  templateUrl: './burden-of-proof-dialog.component.html',
  styleUrls: ['./burden-of-proof-dialog.component.scss'],
  animations: [
    trigger('slide', [
      state(
        'in',
        style({
          opacity: '0',
          overflow: 'hidden',
          height: '0px',
        })
      ),
      state(
        'out',
        style({
          overflow: 'hidden',
          height: '*',
        })
      ),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out')),
    ]),
  ],
})
export class BurdenOfProofDialogComponent implements OnInit {
  env = environment;
  voucherNumber: string;
  voucher: Voucher;
  voucherGroup: VoucherGroup;
  awaitingResponse = false;
  proof: BurdenOfProofForm = {
    'picturesAfter': [],
    'picturesBefore': [],
    'picturesDuring': [],
    'receipts': [],
    'status': '',
    'subsidyOptions': [],
    'energyLabel': '',
    'badlyInsulatedParts': [],
  };
  proofHistory: BurdenOfProofHistory[] = [];
  burdenOfProofForm: FormGroup = new FormGroup([]);
  voucherInfoForm: FormGroup = new FormGroup({
    number: new FormControl({ value: '', disabled: true }),
    voucherGroupName: new FormControl({ value: '', disabled: true }),
    value: new FormControl({ value: '', disabled: true }),
    name: new FormControl({ value: '', disabled: true }),
    phone: new FormControl({ value: '', disabled: true }),
    email: new FormControl({ value: '', disabled: true }),
    postal: new FormControl({ value: '', disabled: true }),
    houseNumber: new FormControl({ value: '', disabled: true }),
    houseNumberAddition: new FormControl({ value: '', disabled: true }),
    amountToPayOrg: new FormControl({ value: '', disabled: true }),
    activateDate: new FormControl({ value: '', disabled: true }),
    claimDate: new FormControl({ value: '', disabled: true }),
    paidDate: new FormControl({ value: '', disabled: true }),
    validUntilDate: new FormControl({ value: '', disabled: true }),
    burdenOfProofFillInBeforeDate: new FormControl({
      value: '',
      disabled: true,
    }),
    voucherCheckStatus: new FormControl({ value: '', disabled: true }),
  });
  status = BurdenOfProofStatus;
  saving: boolean = false;
  badlyInsulatedParts = BadlyInsulatedParts;
  moment = moment;
  isCollapsed: string[] = [];
  resendButtonDisabled: boolean = true;
  sendingBOP: boolean = false;
  sendingVoucher: boolean = false;
  tabIndex: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<SettingsComponent>,
    private fb: UntypedFormBuilder,
    private db: AngularFirestore,
    public afAuth: AngularFireAuth,
    private http: HttpClient,
    private snackBar: MatSnackBar
  ) {}

  async ngOnInit(): Promise<void> {
    this.voucherNumber = this.data.voucherId;
    this.voucher = (
      await getDoc(
        doc(
          this.db.firestore,
          `township/${this.data.townshipId}/vouchers/${this.data.voucherId}`
        )
      )
    ).data() as Voucher;
    console.log('this.voucher', this.voucher);

    this.voucherGroup = (
      await getDoc(
        doc(
          this.db.firestore,
          `/township/${this.data.townshipId}/voucherGroups/${this.voucher.voucherGroupId}`
        )
      )
    ).data() as VoucherGroup;

    this.burdenOfProofForm = this.fb.group({
      complete: [, Validators.required],
      completeRemarks: [],
      status: [, Validators.required],
      reasonOfRejection: [],
    });

    this.voucherInfoForm.patchValue({
      ...this.voucher,
      voucherGroupName: this.voucherGroup.name,
      activateDate: this.voucher.activateDate
        ? moment(this.voucher.activateDate.toDate()).format('DD/MM/yyyy')
        : '',
      claimDate: this.voucher.claimDate
        ? moment(this.voucher.claimDate.toDate()).format('DD/MM/yyyy')
        : '',
      paidDate: this.voucher.paidDate
        ? moment(this.voucher.paidDate.toDate()).format('DD/MM/yyyy')
        : '',
      validUntilDate: this.voucher.validUntilDate
        ? moment(this.voucher.validUntilDate.toDate()).format('DD/MM/yyyy')
        : '',
      burdenOfProofFillInBeforeDate: this.voucher.burdenOfProofFillInBeforeDate
        ? moment(this.voucher.burdenOfProofFillInBeforeDate.toDate()).format(
            'DD/MM/yyyy'
          )
        : '',
      voucherCheckStatus: this.getVoucherStatus(),
    });

    if (
      this.voucherGroup.cashback ||
      (!this.voucherGroup.cashback && this.voucher.activateDate)
    ) {
      this.resendButtonDisabled = false;
    }
    if (this.voucher.burdenOfProofFormHistory) {
      this.proofHistory = [...this.voucher.burdenOfProofFormHistory];
      this.proofHistory.forEach((history) => {
        history.manualControl = history.manualControl ?? true;
      });
    }
    if (this.voucher.burdenOfProofForm) {
      this.proof = { ...this.voucher.burdenOfProofForm };
      this.voucher.burdenOfProofForm.subsidyOptions?.forEach(
        (subsidyOption) => {
          this.burdenOfProofForm.addControl(
            `${subsidyOption.name}MainUnit`,
            new FormControl(
              { value: subsidyOption.mainUnit ?? '', disabled: true },
              [Validators.required]
            )
          );
          this.burdenOfProofForm.addControl(
            `${subsidyOption.name}DIY`,
            new FormControl(
              { value: subsidyOption.diy ?? null, disabled: true },
              [Validators.required]
            )
          );
          if (typeof subsidyOption.code !== 'undefined') {
            this.burdenOfProofForm.addControl(
              `${subsidyOption.name}Code`,
              new FormControl(
                { value: subsidyOption.code ?? '', disabled: true },
                [Validators.pattern('^[a-zA-Z0-9]*$')]
              )
            );
          }
          if (typeof subsidyOption.secondaryUnit !== 'undefined') {
            this.burdenOfProofForm.addControl(
              `${subsidyOption.name}SecondaryUnit`,
              new FormControl(
                { value: subsidyOption.secondaryUnit ?? '', disabled: true },
                [numberInput(false, false, 2)]
              )
            );
          }
        }
      );
      this.voucher.burdenOfProofForm.receipts?.forEach((receiptOption) => {
        this.burdenOfProofForm.addControl(
          `receiptOption${receiptOption.name}`,
          new FormControl(
            { value: receiptOption.receiptValue ?? '', disabled: true },
            [numberInput(false, false, 2), Validators.required]
          )
        );
      });
    }
    for (let item of this.proofHistory) {
      this.isCollapsed.push('in');
    }

    this.burdenOfProofForm.patchValue(this.proof);
    this.burdenOfProofForm.controls.status.valueChanges.subscribe((value) => {
      if (value === 'Rejected') {
        this.burdenOfProofForm.get('reasonOfRejection').enable();
        this.burdenOfProofForm
          .get('reasonOfRejection')
          .addValidators(Validators.required);
        this.burdenOfProofForm
          .get('reasonOfRejection')
          .updateValueAndValidity();
      } else {
        this.burdenOfProofForm.get('reasonOfRejection').disable();
        this.burdenOfProofForm
          .get('reasonOfRejection')
          .removeValidators(Validators.required);
        this.burdenOfProofForm.get('reasonOfRejection').setValue(null);
        this.burdenOfProofForm
          .get('reasonOfRejection')
          .updateValueAndValidity();
      }
    });
  }

  openPicture(url: string) {
    window.open(url, '_blank');
  }

  async resendBOPForm() {
    this.sendingBOP = true;

    const townshipData = {
      ...(
        await getDoc(doc(this.db.firestore, `township/${this.data.townshipId}`))
      ).data(),
      id: this.data.townshipId,
    } as Township;
    try {
      if (townshipData.uniqueName) {
        // mailType voucherBurdenOfProofNew
        const dataObjBoP: MailDataObj = {
          orgData: {
            township: townshipData,
            voucherGroup: this.voucherGroup,
            voucher: this.voucher,
          },
          mailData: {
            mailType: 'voucherBurdenOfProofNew',
            downloadUrl: `${await iframeUrl(
              townshipData.id,
              this.db.firestore
            )}/burden-of-proof/${townshipData.uniqueName}/${encodeRouteParam(
              this.voucher.number
            )}/${this.voucher.postal}/${this.voucher.houseNumber}`,
          },
          userData: { email: [this.voucher.email] },
        };

        const sendMailRes = (await lastValueFrom(
          this.http.post(`${environment.functionsUrl}/httpSendMail`, {
            data: dataObjBoP,
          })
        )) as any;

        if (sendMailRes.status != 'success') {
          throw Error(sendMailRes.status);
        }
        this.snackBar.open('Bewijslast opnieuw verstuurd', 'X', {
          duration: 5000,
        });
      } else {
        throw Error('Either no townshipName doc or multiple found');
      }
    } catch (e) {
      console.log(e);
      this.snackBar.open('Er is iets misgegaan', 'X', {
        duration: 5000,
      });
    }
    this.sendingBOP = false;
  }

  async sendVoucher() {
    this.sendingVoucher = true;
    if (!this.awaitingResponse) {
      this.awaitingResponse = true;
      const callable = httpsCallable(functions, 'httpSendVoucherMail');
      await callable({
        email: this.voucher.email,
        code: this.data.voucherId,
        townshipId: this.data.townshipId,
        type: 'voucher',
      }).then((response) => {
        if (response.data['status'] == 'success') {
          this.snackBar.open('De mail is verstuurd', 'X', {
            duration: 5000,
          });
          return this.dialogRef.close();
        } else {
          this.snackBar.open(
            'Het versturen van de mail is mislukt, probeer opnieuw.',
            '',
            {
              duration: 5000,
            }
          );
          this.awaitingResponse = false;
        }
      });
    }
    this.sendingVoucher = false;
  }

  async save() {
    this.burdenOfProofForm.markAllAsTouched();
    if (!this.burdenOfProofForm.valid) {
      console.log('this.burdenOfProofForm', this.burdenOfProofForm);
      return;
    }
    this.saving = true;
    const dateNow = new Date();
    const status = this.burdenOfProofForm.value.status;
    const newHistory = {
      'email': (await this.afAuth.currentUser).email,
      'date': dateNow,
      'status': status,
      'claimDate': status == 'Accepted' ? dateNow : null,
      'reasonOfRejection':
        this.burdenOfProofForm.value.reasonOfRejection ?? null,
      'completeRemarks': this.burdenOfProofForm.value.completeRemarks,
      'manualControl': true,
    };
    const arrayToSaveSubsidyOptions = [];
    const arrayToSaveReceiptOptions = [];

    this.proof.subsidyOptions.forEach((option) => {
      const subsidyOption: any = {
        id: option.id,
        name: option.name,
        mainUnit:
          this.burdenOfProofForm.getRawValue()[`${option.name}MainUnit`],
        mainUnitLabel: option.mainUnitLabel,
        diy: this.burdenOfProofForm.getRawValue()[`${option.name}DIY`],
      };
      if (this.burdenOfProofForm.controls[`${option.name}Code`]) {
        subsidyOption.code = this.burdenOfProofForm
          .getRawValue()
          [`${option.name}Code`].toUpperCase();
      }
      if (this.burdenOfProofForm.controls[`${option.name}SecondaryUnit`]) {
        subsidyOption.secondaryUnit =
          this.burdenOfProofForm.getRawValue()[`${option.name}SecondaryUnit`];
        subsidyOption.secondaryUnitLabel = option.secondaryUnitLabel;
      }
      arrayToSaveSubsidyOptions.push(subsidyOption);
    });

    this.proof.subsidyOptions = arrayToSaveSubsidyOptions;

    Object.keys(this.burdenOfProofForm.getRawValue()).forEach((controlKey) => {
      console.log(controlKey);
      if (controlKey.includes('receiptOption')) {
        const previousReceipt = this.proof.receipts.find(
          (object) => object.name === controlKey.replace(/receiptOption/, '')
        );
        arrayToSaveReceiptOptions.push({
          name: controlKey.replace(/receiptOption/, ''),
          receiptValue: Number(
            this.burdenOfProofForm.getRawValue()[controlKey]
          ),
          downloadUrl: previousReceipt.downloadUrl,
          file: previousReceipt.file ?? null,
        });
      }
    });

    this.proof.receipts = arrayToSaveReceiptOptions;

    let originalVoucherDoc: DocumentReference;
    let originalVoucher: Voucher;

    if (this.voucher.originalVoucherNumber) {
      originalVoucherDoc = doc(
        this.db.firestore,
        `/township/${this.data.townshipId}/vouchers/${this.voucher.originalVoucherNumber}`
      );
      console.log('originalVoucherDoc', originalVoucherDoc.path);
      originalVoucher = (await getDoc(originalVoucherDoc)).data() as Voucher;
    }

    const saveObj = {
      voucherCheckStatus:
        status == 'Accepted'
          ? VoucherCheckStatus.accepted
          : VoucherCheckStatus.declined,
      burdenOfProofForm: {
        ...this.proof,
        complete: this.burdenOfProofForm.value.complete,
        completeRemarks: this.burdenOfProofForm.value.completeRemarks,
        status: status,
        reasonOfRejection:
          this.burdenOfProofForm.value.reasonOfRejection ?? null,
      },
      burdenOfProofFormHistory: arrayUnion(newHistory),
      email: originalVoucher?.email ?? this.voucher.email,
    } as any;
    console.log('saveObj', saveObj);

    const voucherDoc = doc(
      this.db.firestore,
      `/township/${this.data.townshipId}/vouchers/${this.voucher.number}`
    );
    await updateDoc(voucherDoc, saveObj);

    if (originalVoucher && status == 'Rejected') {
      // Refund amount to original voucher and delete partial voucher
      console.log('originalVoucher', originalVoucher);
      console.log('voucherDoc', voucherDoc.path);
      await updateDoc(originalVoucherDoc, {
        value: originalVoucher.value + this.voucher.value,
        burdenOfProofForm: saveObj.burdenOfProofForm,
        burdenOfProofPartialVoucherId: deleteField(),
        transactionCounter: increment(-1),
      });
      await deleteDoc(voucherDoc);
    }
    this.saving = false;
    this.dialogRef.close();
  }

  toggleReason(index: number): void {
    this.isCollapsed[index] = this.isCollapsed[index] === 'out' ? 'in' : 'out';
  }

  getFileExtension(fileName: string) {
    const regexp: RegExp = /(?:\.([^.]+))?$/;
    return regexp.exec(fileName)[1];
  }

  getVoucherStatus() {
    switch (this.voucher.voucherCheckStatus) {
      case VoucherCheckStatus.accepted:
        if (this.voucher.paidDate) {
          return 'Goedgekeurd en uitbetaald';
        }
        return 'Goedgekeurd';
      case VoucherCheckStatus.declined:
        return 'Afgekeurd';
      case VoucherCheckStatus.todo:
        return 'Ongecontroleerd';
      default:
        return 'Geen';
    }
  }

  onTabChange(event: MatTabChangeEvent) {
    this.tabIndex = event.index;
  }
}
