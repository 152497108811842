import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import moment from 'moment';
import {
  Appointment,
  AppointmentStatisticUser,
  AppointmentType,
  DayPart,
  DayPartStats,
  DayPartException,
  PlanningUser,
  Township,
  Voucher,
  VoucherGroup,
  DistanceLabel,
  AppointmentUserDistance,
} from 'src/app/interfaces';
import { db, functions } from 'src/app/app.component';
import {
  capitalizeFirstLetter,
  getAvailabilityCirclePercentageForWeek,
  getAvailableTimeString,
  getPlanningUsersStatistics,
} from '../../helper';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  ActionType,
  TeamUserRights,
  PlanningUserRights,
  RepeatValue,
  VoucherStatus,
} from 'src/app/enums';
import { httpsCallable } from 'firebase/functions';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  arrayNotEmpty,
  numberInput,
  postalValidator,
} from 'angular-custom-validators';

export interface DialogData {
  appointment?: Appointment;
  planningUsers: PlanningUser[];
}

@Component({
  selector: 'app-manage-appointment',
  templateUrl: './manage-appointment.component.html',
  styleUrls: ['./manage-appointment.component.scss'],
})
export class ManageAppointmentComponent implements OnInit {
  step: number = 1;
  initialized: boolean = false;
  saving: boolean = false;
  editing = false;
  townshipId = localStorage.getItem('township') as string;
  moment = moment;

  appointmentId: string = this.db.createId();

  whoForm: FormGroup = this.fb.group({
    postal: new FormControl('', [Validators.required, postalValidator]),
    houseNumber: new FormControl('', [
      Validators.required,
      numberInput(false, false, 0),
    ]),
    houseNumberAddition: new FormControl(''),
    appointmentTypeId: new FormControl(null, [Validators.required]),
    voucherId: new FormControl(null, [Validators.required]),
    selectedTeamUsers: new FormControl({ value: [], disabled: true }, [
      arrayNotEmpty(),
    ]),
  });

  dateForm: FormGroup = this.fb.group({
    start: new FormControl(null, [Validators.required]),
    end: new FormControl(null, [Validators.required]),
    dayPartIds: new FormControl([], [Validators.required, arrayNotEmpty()]),
  });
  selectedDayParts: DayPart[] = [];
  viewDate: Date = new Date(new Date().setHours(0, 0, 0, 0));
  viewWeekNumber = moment(this.viewDate).week();
  viewDates = [];

  planningUsersForm: FormGroup = this.fb.group({
    planningUserIds: new FormControl([], [arrayNotEmpty()]),
    planningUserNames: new FormControl([], [arrayNotEmpty()]),
    planningUserDistance: new FormControl([], [arrayNotEmpty()]),
  });

  whereForm: FormGroup = this.fb.group({
    postal: new FormControl('', [Validators.required, postalValidator]),
    houseNumber: new FormControl('', [
      Validators.required,
      numberInput(false, false, 0),
    ]),
    houseNumberAddition: new FormControl(''),
    street: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    // repeat: new FormControl(false),
    // repeatValue: new FormControl(''),
    // repeatEndDate: new FormControl(null, [this.maxDateValidator()]),
  });

  appointmentTypes: AppointmentType[] = [];
  actionType: ActionType = ActionType.created;
  voucherGroups: VoucherGroup[] = [];
  vouchers: Voucher[] = [];
  errorFindingVouchers = false;
  dayParts: DayPart[] = [];
  dayPartStats: DayPartStats[] = [];
  dayPartsExceptions: DayPartException[] = [];
  suggestedTimes: TimeSuggestion[] = [];
  planningUsers: PlanningUser[] = this.data.planningUsers;
  planningUsersStatisticsYearWeek: string;
  planningUsersStatistics: AppointmentStatisticUser[] = [];
  usersDistanceLabels = {};
  distanceLabels = {};
  planningUserDistanceData: AppointmentUserDistance[] = [];
  particularities = {};
  availableUsers: TimeSuggestionUser[];
  simulatedAppointment: Appointment;
  calendarSequence: number = 0;
  allDayException: DayPartException;
  callingDistanceApi: boolean = false;
  distanceApiFailed: boolean = false;
  distanceError = {};
  lastSelectedTime = { start: 0, end: 0 };
  lastDestination: string = '';
  internalAppointment: boolean = false;
  planningUserRights = { ...TeamUserRights, ...PlanningUserRights };
  repeatValues = { ...RepeatValue };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogRef: MatDialogRef<ManageAppointmentComponent>,
    private fb: FormBuilder,
    public db: AngularFirestore,
    private snackBar: MatSnackBar
  ) {}

  async ngOnInit() {
    moment.locale('nl');
    await this.getAppointmentTypes();
    await this.getVoucherGroups();
    await this.getDayParts();
    await this.getDayPartExceptions();
    await this.getDistanceLabels();
    await this.getParticularities();
    this.getViewDays();

    if (this.data.appointment) {
      this.appointmentId = this.data.appointment.id;
      this.actionType = ActionType.edited;
      this.internalAppointment =
        this.data.appointment.internalAppointment ?? false;
      await this.initializeEdit();
    }
    this.initialized = true;
  }

  async initializeEdit() {
    this.editing = true;
    this.viewDate = new Date(
      new Date(this.data.appointment.start).setHours(0, 0, 0, 0)
    );
    // Fill step 1
    this.whoForm.patchValue(this.data.appointment);
    await this.findVouchers();
    await this.checkIfInternal();
    if (this.internalAppointment) {
      this.data.appointment.planningUserIds.forEach((userId) => {
        const user = this.planningUsers.find((user) => user.id == userId);
        if (user) {
          this.whoForm.value.selectedTeamUsers.push(user);
        }
      });
    }
    // Fill step 2
    this.dateForm.patchValue(this.data.appointment);
    const start = moment(this.data.appointment.start);
    await this.calculateSuggestedTimes();
    const timeSuggestion: TimeSuggestion = this.suggestedTimes.find((time) => {
      return moment(time.start).isSame(start);
    });
    if (timeSuggestion) {
      this.setTimeSuggestion(timeSuggestion, true);
    }
    // Fill step 3
    this.planningUsersForm.patchValue(this.data.appointment);

    if (!this.internalAppointment) {
      this.step = 2;
    } else {
      this.whereForm.patchValue(this.data.appointment);
      // this.whereForm.controls.repeatEndDate.patchValue(
      //   this.data.appointment?.repeatEndDate?.toDate()
      // );
      // await this.toggleAppointmentRepeat();
      this.step = 1;
    }
  }

  async getAppointmentTypes() {
    const appointmentTypesRef = collection(
      this.db.firestore,
      `township/${this.townshipId}/appointmentTypes`
    );
    const appointmentTypesDocs = await getDocs(
      query(appointmentTypesRef, orderBy('name'))
    );
    appointmentTypesDocs.forEach((appointmentDoc) => {
      const appointmentType = appointmentDoc.data() as AppointmentType;
      appointmentType.id = appointmentDoc.id;
      this.appointmentTypes.push(appointmentType);
    });
  }

  async getVoucherGroups() {
    const voucherGroupsRef = collection(
      db,
      `township/${this.townshipId}/voucherGroups`
    );
    const voucherGroupsDocs = await getDocs(
      query(voucherGroupsRef, orderBy('name'))
    );
    voucherGroupsDocs.forEach((voucherGroupDoc) => {
      const voucherGroup = voucherGroupDoc.data() as VoucherGroup;
      voucherGroup.id = voucherGroupDoc.id;
      this.voucherGroups.push(voucherGroup);
    });
  }

  async getDayParts() {
    const dayPartsRef = collection(
      this.db.firestore,
      `township/${this.townshipId}/dayParts`
    );
    const dayPartsDocs = await getDocs(query(dayPartsRef, orderBy('day')));
    dayPartsDocs.forEach((dayPartDoc) => {
      const dayPart = dayPartDoc.data() as DayPart;
      dayPart.id = dayPartDoc.id;
      this.dayParts.push(dayPart);
    });
    // console.log('this.dayParts', [...this.dayParts]);
  }

  async getDayPartExceptions() {
    const dayPartsExceptionsRef = collection(
      this.db.firestore,
      `township/${this.townshipId}/dayPartsExceptions`
    );
    const dayPartsExceptionsDocs = await getDocs(query(dayPartsExceptionsRef));
    dayPartsExceptionsDocs.forEach((dayPartsExceptionDoc) => {
      const dayPartsException = dayPartsExceptionDoc.data() as DayPartException;
      dayPartsException.id = dayPartsException.id;
      this.dayPartsExceptions.push(dayPartsException);
    });
  }

  async getDistanceLabels() {
    const distanceLabelsRef = collection(
      this.db.firestore,
      `township/${this.townshipId}/distanceLabels`
    );
    const distanceLabelsDocs = await getDocs(
      query(distanceLabelsRef, orderBy('rangeStart'))
    );
    distanceLabelsDocs.forEach((distanceLabelDoc) => {
      const distanceLabel = distanceLabelDoc.data() as DistanceLabel;
      this.distanceLabels[distanceLabelDoc.id] = distanceLabel;
    });
  }

  async getParticularities() {
    const particularitiesRef = collection(
      this.db.firestore,
      `township/${this.townshipId}/planningParticularities`
    );
    const particularitiesDocs = await getDocs(query(particularitiesRef));
    particularitiesDocs.forEach((particularityDoc) => {
      const particularity = particularityDoc.data();
      this.particularities[particularityDoc.id] = particularity;
    });
  }

  getVoucherGroupById(id: string) {
    const voucherGroup = this.voucherGroups.find((group) => group.id === id);
    return voucherGroup;
  }

  getVoucherById(id: string) {
    const voucher = this.vouchers.find((voucher) => voucher.id === id);
    return voucher;
  }

  getAppointmentTypeById(id: string) {
    const appointmentType = this.appointmentTypes.find(
      (appointmentType) => appointmentType.id === id
    );
    return appointmentType;
  }

  async nextStep() {
    // console.log('this.step', this.step);
    if (this.step === 1) {
      if (!this.whoForm.valid) {
        return this.whoForm.markAllAsTouched();
      }
      await this.calculateSuggestedTimes();
    }
    if (this.step === 2) {
      if (!this.dateForm.valid) {
        return this.dateForm.markAllAsTouched();
      }
      if (!this.internalAppointment) {
        this.getDistances();
      }
    }
    if (this.step === 3) {
      if (!this.planningUsersForm.valid && !this.internalAppointment) {
        return this.planningUsersForm.markAllAsTouched();
      } else if (!this.whereForm.valid && this.internalAppointment) {
        return this.whereForm.markAllAsTouched();
      }
      this.simulatedAppointment = this.appointmentData();
    }
    // console.log('appointmentData', this.appointmentData());
    this.step++;
  }

  prevStep() {
    if (this.step === 4) {
      this.simulatedAppointment = null;
    }
    this.step--;
  }

  async findVouchers() {
    if (
      !this.whoForm.controls.postal.valid ||
      !this.whoForm.controls.houseNumber.valid ||
      !this.whoForm.controls.appointmentTypeId.valid
    ) {
      // console.log('form invalid');
      return this.whoForm.markAllAsTouched();
    }
    this.errorFindingVouchers = false;
    const form = this.whoForm.value;
    const vouchersRef = collection(
      this.db.firestore,
      `township/${this.townshipId}/vouchers`
    );
    let vouchersQuery = query(
      vouchersRef,
      where('status', '==', VoucherStatus.activated),
      where('postal', '==', form.postal.toUpperCase().replace(' ', '')),
      where('houseNumber', '==', form.houseNumber)
    );
    if (form.houseNumberAddition) {
      vouchersQuery = query(
        vouchersRef,
        where('status', '==', VoucherStatus.activated),
        where('postal', '==', form.postal.toUpperCase().replace(' ', '')),
        where('houseNumber', '==', form.houseNumber),
        where(
          'houseNumberAddition',
          '==',
          form.houseNumberAddition.toLowerCase()
        )
      );
    }
    const voucherDocs = await getDocs(vouchersQuery);
    const vouchers = [];
    voucherDocs.forEach((voucherDoc) => {
      const voucher = voucherDoc.data();
      voucher.id = voucherDoc.id;
      voucher.activateDate = voucher.activateDate.toDate();
      // console.log('voucher', voucher);
      vouchers.push(voucher);
    });
    if (vouchers.length == 0) {
      this.errorFindingVouchers = true;
    }
    this.vouchers = vouchers;
  }

  setVoucher(voucher: Voucher) {
    this.whoForm.controls.voucherId.setValue(voucher.number);
  }

  async calculateSuggestedTimes() {
    await this.getPlanningUsersStatistics();
    const currentDay = this.viewDate.getDay();
    // console.log('day', currentDay);
    const dayPartsToday = this.dayParts
      .filter((dayPart) => {
        return dayPart.day === currentDay && dayPart.enabled;
      })
      .sort((a, b) => {
        if (a.startHour > b.startHour) {
          return 1;
        }
        return -1;
      });
    const dayPartsTodayIds = [];
    dayPartsToday.forEach((dayPart) => {
      dayPartsTodayIds.push(dayPart.id);
    });
    const exceptions = this.getExceptionsForDay();
    if (exceptions == 'allDay' || dayPartsToday.length == 0) {
      this.suggestedTimes = [];
      return;
    }
    // console.log('dayPartsToday', dayPartsToday);
    const appointmentType = this.getAppointmentTypeById(
      this.whoForm.value.appointmentTypeId
    );
    // console.log('appointmentType', appointmentType);
    const suggestedTimes = [];
    let totalMinutesInDay = 0;
    const duration =
      appointmentType.durationMinutes + appointmentType.durationHours * 60;
    for (const dayPart of dayPartsToday) {
      const dayPartMinutes =
        (dayPart.endHour - dayPart.startHour) * 60 +
        (dayPart.endMinutes - dayPart.startMinutes);
      totalMinutesInDay = totalMinutesInDay + dayPartMinutes;
      if (dayPartMinutes < duration) {
        continue;
      }
      let minutesRemaining = dayPartMinutes;
      // console.log('minutesRemaining', minutesRemaining);
      // console.log('appointmentType.duration', duration);
      while (minutesRemaining > duration) {
        const startTimeInMinutes =
          dayPart.startHour * 60 +
          dayPart.startMinutes +
          (dayPartMinutes - minutesRemaining);
        const startDate = moment(this.viewDate).add(
          startTimeInMinutes,
          'minutes'
        );
        const endDate = moment(this.viewDate).add(
          startTimeInMinutes + duration,
          'minutes'
        );
        const availableUsers: TimeSuggestionUser[] = [];
        this.planningUsers.forEach((user) => {
          const available: boolean =
            this.getPlanningUserStatisticsAvailable(
              user,
              [dayPart.id],
              appointmentType.id,
              startDate,
              endDate
            ) &&
            this.getUserAvailability(user, [dayPart.id], startDate, endDate);
          if (available) {
            const circlePercentage = getAvailabilityCirclePercentageForWeek(
              user,
              this.planningUsersStatistics
            );
            const degrees = 360 * circlePercentage;
            const circularProgress = `conic-gradient(var(--primary) ${degrees}deg, transparent 0deg)`;
            const maxWorkMinutes = user?.workData?.maxWeeklyHours
              ? user.workData.maxWeeklyHours * 60
              : 0;
            const timeSuggestionUser: TimeSuggestionUser = {
              id: user.id,
              user: user,
              circularProgress: circularProgress,
              maxTime: getAvailableTimeString(maxWorkMinutes),
              timeUsed: getAvailableTimeString(
                maxWorkMinutes,
                circlePercentage
              ),
            };
            if (
              (this.internalAppointment &&
                this.whoForm.value.selectedTeamUsers.indexOf(user) != -1) ||
              (!this.internalAppointment && user.rights != 'planner')
            ) {
              availableUsers.push(timeSuggestionUser);
            }
          }
        });

        // console.log('startTimeInMinutes', startTimeInMinutes);
        const timeSuggestion: TimeSuggestion = {
          start: startDate.toDate(),
          end: endDate.toDate(),
          dayParts: [dayPart],
          day: capitalizeFirstLetter(startDate.format('dd')),
          month: startDate.format('MMM').replace('.', ''),
          availableUsers: availableUsers,
        };
        const remainingExceptions = exceptions.filter((exception) => {
          if (
            (exception.start <= timeSuggestion.start &&
              exception.end >= timeSuggestion.end) ||
            (exception.start <= timeSuggestion.start &&
              exception.end >= timeSuggestion.start) ||
            (exception.start <= timeSuggestion.end &&
              exception.end >= timeSuggestion.end)
          ) {
            return exception;
          }
        });
        if (remainingExceptions.length == 0) {
          suggestedTimes.push(timeSuggestion);
        }
        minutesRemaining = minutesRemaining - duration - 15;
      }
    }
    //check if appointmentType can fit in combined dayparts if no timeslots are available
    if (suggestedTimes.length == 0 && totalMinutesInDay >= duration) {
      let minutesRemaining = totalMinutesInDay;
      let minutesBetweenDayParts = 0;
      for (let i = 0; i < dayPartsToday.length - 1; i++) {
        const dayPartOneEndMinutes =
          dayPartsToday[i].endHour * 60 + dayPartsToday[i].endMinutes;
        const dayPartTwoStartMinutes =
          dayPartsToday[i + 1].startHour * 60 +
          dayPartsToday[i + 1].startMinutes;
        minutesBetweenDayParts =
          minutesBetweenDayParts +
          (dayPartTwoStartMinutes - dayPartOneEndMinutes);
      }
      const startTimeInMinutes =
        dayPartsToday[0].startHour * 60 +
        dayPartsToday[0].startMinutes +
        (totalMinutesInDay - minutesRemaining);
      const startDate = moment(this.viewDate).add(
        startTimeInMinutes,
        'minutes'
      );
      const endDate = moment(this.viewDate).add(
        startTimeInMinutes + duration + minutesBetweenDayParts,
        'minutes'
      );
      const availableUsers: TimeSuggestionUser[] = [];
      this.planningUsers.forEach((user) => {
        const available: boolean =
          this.getPlanningUserStatisticsAvailable(
            user,
            dayPartsTodayIds,
            appointmentType.id,
            startDate,
            endDate
          ) &&
          this.getUserAvailability(user, dayPartsTodayIds, startDate, endDate);
        if (available) {
          const circlePercentage = getAvailabilityCirclePercentageForWeek(
            user,
            this.planningUsersStatistics
          );
          const degrees = 360 * circlePercentage;
          const circularProgress = `conic-gradient(var(--primary) ${degrees}deg, transparent 0deg)`;
          const maxWorkMinutes = user?.workData?.maxWeeklyHours
            ? user.workData.maxWeeklyHours * 60
            : 0;
          const timeSuggestionUser: TimeSuggestionUser = {
            id: user.id,
            user: user,
            circularProgress: circularProgress,
            maxTime: getAvailableTimeString(maxWorkMinutes),
            timeUsed: getAvailableTimeString(maxWorkMinutes, circlePercentage),
          };
          if (
            (this.internalAppointment &&
              this.whoForm.value.selectedTeamUsers.indexOf(user) != -1) ||
            (!this.internalAppointment && user.rights != 'planner')
          ) {
            availableUsers.push(timeSuggestionUser);
          }
        }
      });
      const timeSuggestion: TimeSuggestion = {
        start: startDate.toDate(),
        end: endDate.toDate(),
        dayParts: dayPartsToday,
        day: capitalizeFirstLetter(startDate.format('dd')),
        month: startDate.format('MMM').replace('.', ''),
        availableUsers: availableUsers,
      };
      const remainingExceptions = exceptions.filter((exception) => {
        if (
          (exception.start <= timeSuggestion.start &&
            exception.end >= timeSuggestion.end) ||
          (exception.start <= timeSuggestion.start &&
            exception.end >= timeSuggestion.start) ||
          (exception.start <= timeSuggestion.end &&
            exception.end >= timeSuggestion.end)
        ) {
          return exception;
        }
      });
      if (remainingExceptions.length == 0) {
        suggestedTimes.push(timeSuggestion);
      }
    }

    console.log('suggestedTimes', suggestedTimes);
    this.suggestedTimes = suggestedTimes;
  }

  async getPlanningUsersStatistics() {
    if (
      this.planningUsersStatisticsYearWeek !=
      moment(this.viewDate).format('YYYY-ww')
    ) {
      this.planningUsersStatisticsYearWeek = moment(this.viewDate).format(
        'YYYY-ww'
      );
      this.planningUsersStatistics = await getPlanningUsersStatistics(
        this.planningUsersStatisticsYearWeek
      );
    }
  }

  getPlanningUserStatisticsAvailable(
    planningUser: PlanningUser,
    dayPartIds: string[],
    appointmentId: string,
    start: moment.Moment,
    end: moment.Moment
  ) {
    if (
      !this.internalAppointment &&
      !planningUser.workData?.appointmentTypes?.includes(appointmentId)
    ) {
      return false; // User doesn't have required expertise?
    }
    const userStatistic = this.planningUsersStatistics.find(
      (user) => user.id === planningUser.id
    );
    console.log('userStatistic', userStatistic);
    start = moment(start).add(-7.5, 'minutes');
    end = moment(end).add(7.5, 'minutes');

    if (planningUser?.workData?.maxWeeklyHours) {
      const newWorkMinutes =
        moment.duration(end.diff(start)).asMinutes() +
        (userStatistic ? userStatistic.workMinutes : 0);
      const maxWorkMinutes = planningUser.workData?.maxWeeklyHours * 60;
      if (newWorkMinutes > maxWorkMinutes) {
        return false;
      }
    }
    if (!userStatistic || !planningUser) {
      return true;
    }
    const appointment = userStatistic.appointments.find((appointment) => {
      let isBetween = false;
      const appointmentStart = moment(appointment.start.toDate());
      const appointmentEnd = moment(appointment.end.toDate());
      if (
        appointmentStart.isBetween(start, end, null, '()') ||
        appointmentEnd.isBetween(start, end, null, '()') ||
        start.isBetween(appointmentStart, appointmentEnd, null, '()') ||
        end.isBetween(appointmentStart, appointmentEnd, null, '()')
      ) {
        isBetween = true;
      }
      return isBetween;
    });
    // console.log('appointmentFound:', appointment);
    if (!appointment) {
      return true;
    } else {
      return false;
    }
  }

  getUserAvailability(
    user: PlanningUser,
    dayPartIds: string[],
    start: moment.Moment,
    end: moment.Moment
  ) {
    if (user.dayPartsUnavailable) {
      const dayPartIdFound = dayPartIds.filter((dayPartId) => {
        return user.dayPartsUnavailable.indexOf(dayPartId) != -1;
      });
      if (dayPartIdFound.length > 0) {
        return false;
      }
    }
    if (user.exceptions) {
      const exception = user.exceptions.find((exception) => {
        let isBetween = false;
        const exceptionStart = moment(exception.startDate.toDate());
        const exceptionEnd = moment(exception.endDate.toDate());
        if (
          exceptionStart.isBetween(start, end, null, '()') ||
          exceptionEnd.isBetween(start, end, null, '()') ||
          start.isBetween(exceptionStart, exceptionEnd, null, '()') ||
          end.isBetween(exceptionStart, exceptionEnd, null, '()')
        ) {
          isBetween = true;
        }
        return isBetween;
      });
      if (exception) {
        return false;
      }
    }
    return true;
  }

  getExceptionsForDay() {
    const currentWeek = moment(this.viewDate).week();
    const currentMonth = moment(this.viewDate).month();
    const currentYear = moment(this.viewDate).year();
    const compareDate = moment(this.viewDate);
    const exceptions = [];
    for (const exception of this.dayPartsExceptions) {
      const exceptionDate = moment(exception.date.toDate());
      if (exception.repeat) {
        switch (exception.repeatValue) {
          case 'week':
            exceptionDate.week(currentWeek);
            exceptionDate.month(currentMonth);
            exceptionDate.year(currentYear);
            break;
          case 'month':
            exceptionDate.month(currentMonth);
            exceptionDate.year(currentYear);
            break;
          case 'year':
            exceptionDate.year(currentYear);
            break;
        }
      }
      if (
        exceptionDate.format('YYYY-MM-DD') != compareDate.format('YYYY-MM-DD')
      ) {
        continue;
      }
      if (exception.allDay) {
        this.allDayException = exception;
        return 'allDay';
      }
      const start = moment(exceptionDate)
        .hours(exception.startHour)
        .minutes(exception.startMinutes)
        .toDate();
      const end = moment(exceptionDate)
        .hours(exception.endHour)
        .minutes(exception.endMinutes)
        .toDate();
      exceptions.push({
        start: start,
        end: end,
        exception: exception,
      });
    }
    return exceptions;
  }

  setTimeSuggestion(time: TimeSuggestion, onInitialize: boolean) {
    const dayPartIds = [];
    time.dayParts.forEach((dayPart) => {
      dayPartIds.push(dayPart.id);
    });
    this.dateForm.patchValue({
      start: time.start,
      end: time.end,
      dayPartIds: dayPartIds,
    });
    this.selectedDayParts = time.dayParts;
    this.availableUsers = time.availableUsers;
    if (!onInitialize) this.nextStep();
  }

  togglePlanningUser(user: PlanningUser) {
    const userName = `${user.firstName} ${user.lastName}`;
    const form = this.planningUsersForm.value;
    const appointmentPlannedUser = this.planningUserDistanceData.find(
      (appointmentUserData) => {
        if (appointmentUserData.userId == user.id) {
          return appointmentUserData;
        }
      }
    );
    const appointmentType = this.getAppointmentTypeById(
      this.whoForm.value.appointmentTypeId
    );
    console.log('appointmentType', appointmentType);
    const userIdsArray = appointmentType.multipleCoaches
      ? form.planningUserIds
      : ([] as string[]); // Start with empty array when you're only allowed to select 1 coach
    const userNamesArray = appointmentType.multipleCoaches
      ? form.planningUserNames
      : ([] as string[]); // Start with empty array when you're only allowed to select 1 coach
    let planningUserDistanceArray = appointmentType.multipleCoaches
      ? form.planningUserDistance ?? []
      : ([] as AppointmentUserDistance[]);
    if (userIdsArray.includes(user.id)) {
      userIdsArray.splice(userIdsArray.indexOf(user.id), 1);
      userNamesArray.splice(userNamesArray.indexOf(userName), 1);
      console.log(planningUserDistanceArray.indexOf(appointmentPlannedUser));
      planningUserDistanceArray = planningUserDistanceArray.filter(
        (appointmentUser) => {
          return appointmentUser.userId != user.id;
        }
      );
    } else {
      userIdsArray.push(user.id);
      userNamesArray.push(userName);
      planningUserDistanceArray.push(appointmentPlannedUser);
    }
    this.planningUsersForm.controls.planningUserIds.setValue(userIdsArray);
    this.planningUsersForm.controls.planningUserNames.setValue(userNamesArray);
    this.planningUsersForm.controls.planningUserDistance.setValue(
      planningUserDistanceArray
    );
    if (!appointmentType.multipleCoaches) {
      this.nextStep(); // Automatically go to next step if only 1 coach is allowed
    }
  }

  getViewDays() {
    // console.log('viewDate', this.viewDate);
    const weekday = moment(this.viewDate).weekday();
    // console.log('weekday', weekday);
    const currentDayOfWeek = moment(this.viewDate).subtract(weekday, 'days'); // Starts with first day of the week
    // console.log('currentDayOfWeek', currentDayOfWeek.toDate());

    const viewDates = [];
    for (let i = 0; i <= 6; i++) {
      // console.log('weekday', currentDayOfWeek.weekday());
      viewDates.push({
        date: currentDayOfWeek.toDate(),
        moment: currentDayOfWeek,
        day: capitalizeFirstLetter(currentDayOfWeek.format('dd')),
        month: currentDayOfWeek.format('MMM').replace('.', ''),
      });
      currentDayOfWeek.add(1, 'days').toDate();
    }

    this.viewDates = viewDates;
    // console.log('viewDates', this.viewDates);
  }

  setDate(date: Date) {
    this.viewDate = date;
    this.calculateSuggestedTimes();
  }

  nextWeek() {
    this.viewDate = moment(this.viewDate).add(7, 'days').toDate();
    this.viewWeekNumber = moment(this.viewDate).week();
    this.getViewDays();
    this.calculateSuggestedTimes();
  }

  prevWeek() {
    this.viewDate = moment(this.viewDate).subtract(7, 'days').toDate();
    this.viewWeekNumber = moment(this.viewDate).week();
    this.getViewDays();
    this.calculateSuggestedTimes();
  }

  async checkIfInternal() {
    const appointmentType = this.appointmentTypes.find(
      (type) => this.whoForm.value.appointmentTypeId == type.id
    );
    if (appointmentType?.type == 'internal') {
      this.internalAppointment = true;
      this.whoForm.disable();
      this.whoForm.controls.appointmentTypeId.enable();
      this.whoForm.controls.selectedTeamUsers.enable();
    } else {
      this.internalAppointment = false;
      this.whoForm.enable();
      this.whoForm.controls.selectedTeamUsers.disable();
    }
  }

  manageTeamUserList(planningUser) {
    const index = this.whoForm.value.selectedTeamUsers.indexOf(planningUser);
    if (index == -1) {
      this.whoForm.value.selectedTeamUsers.push(planningUser);
    } else {
      this.whoForm.value.selectedTeamUsers.splice(index, 1);
    }
    this.whoForm.controls.selectedTeamUsers.updateValueAndValidity();
  }

  toggleAllTeamUsers() {
    const control = this.whoForm.controls.selectedTeamUsers;
    const planningForm = this.planningUsersForm.value;
    if (control.value.length == this.planningUsers.length) {
      control.setValue([]);
      control.updateValueAndValidity();
    } else {
      this.planningUsers.forEach((planningUser) => {
        if (control.value.indexOf(planningUser) == -1) {
          control.updateValueAndValidity();
          control.value.push(planningUser);
          planningForm.planningUserIds.push(planningUser.id);
          planningForm.planningUserNames.push(
            planningUser.firstName + ' ' + planningUser.lastName
          );
        }
      });
    }
  }

  teamUserAvailable(planningUser) {
    const userFound = this.availableUsers.find(
      (user) => user.id == planningUser.id
    );
    if (userFound) {
      return true;
    }
    return false;
  }

  async toggleAppointmentRepeat() {
    const controls = this.whereForm.controls;
    if (controls.repeat.value) {
      controls.repeatValue.addValidators(Validators.required);
      controls.repeatEndDate.addValidators(Validators.required);
    } else {
      controls.repeatValue.removeValidators(Validators.required);
      controls.repeatEndDate.removeValidators(Validators.required);
      controls.repeatValue.updateValueAndValidity();
      controls.repeatEndDate.updateValueAndValidity();
    }
  }

  appointmentData() {
    const voucher = this.getVoucherById(this.whoForm.value.voucherId);
    const appointmentType = this.getAppointmentTypeById(
      this.whoForm.value.appointmentTypeId
    );
    this.whoForm.controls.postal.setValue(
      this.whoForm.controls.postal.value.toUpperCase().replace(' ', '')
    );
    if (this.internalAppointment) {
      this.planningUsersForm.controls.planningUserIds.setValue([]);
      this.planningUsersForm.controls.planningUserNames.setValue([]);
      this.whoForm.value.selectedTeamUsers.forEach((planningUser) => {
        this.planningUsersForm.value.planningUserIds.push(planningUser.id);
        this.planningUsersForm.value.planningUserNames.push(
          planningUser.firstName + ' ' + planningUser.lastName
        );
      });
    }
    let appointment = {
      ...this.whoForm.value,
      ...this.dateForm.value,
      ...this.planningUsersForm.value,
      userName: voucher?.name ?? null,
      userPhone: voucher?.phone ?? null,
      userEmail: voucher?.email ?? null,
      street: voucher?.street ?? null,
      city: voucher?.city ?? null,
      appointmentTypeName: appointmentType.name,
      internalAppointment: this.internalAppointment,
      id: this.appointmentId,
      status: ActionType.created,
    } as Appointment;
    if (this.internalAppointment) {
      const availableUsers = [];
      if (this.availableUsers) {
        this.availableUsers.forEach((user) => {
          availableUsers.push(user.id);
        });
      }
      delete appointment['selectedTeamUsers'];
      appointment = {
        ...appointment,
        ...this.whereForm.value,
      } as Appointment;
      if (!appointment.repeat) {
        appointment['repeatValue'] = null;
      }
    }
    // console.log('appointment', appointment);
    return appointment;
  }

  async getDistances() {
    const destination = `${this.whoForm.controls.houseNumber.value}${this.whoForm.controls.houseNumberAddition.value}, ${this.whoForm.controls.postal.value}, Netherlands`;
    const userLocations = {};
    if (
      this.callingDistanceApi ||
      (this.dateForm.controls.start.value == this.lastSelectedTime.start &&
        this.dateForm.controls.end.value == this.lastSelectedTime.end &&
        this.lastDestination == destination)
    ) {
      return;
    }
    this.callingDistanceApi = true;
    const origins = [];
    this.distanceError = {};
    for (const timeSuggestionUser of this.availableUsers) {
      const userData = timeSuggestionUser.user;
      if (userData.workData?.distanceLabels.length == 0) {
        this.distanceError[userData.id] = 'noLabel';
        continue;
      }
      const userAppointments = this.planningUsersStatistics.filter(
        (userStatistic) => {
          return userStatistic.id == userData.id;
        }
      );
      let lastAppointment = null;
      if (userAppointments.length > 0) {
        for (const appointment of userAppointments[0].appointments) {
          const appointmentDate = appointment.end.toDate();
          const day = moment(appointmentDate).day();
          const selectedDate = this.dateForm.value.start;
          if (
            day != this.selectedDayParts[0].day ||
            moment(appointmentDate).hour() > moment(selectedDate).hour() ||
            (moment(appointmentDate).hour() == moment(selectedDate).hour() &&
              moment(appointmentDate).minutes() >
                moment(selectedDate).minutes())
          ) {
            continue;
          }
          if (
            lastAppointment == null ||
            lastAppointment.start.toDate() < appointmentDate
          ) {
            lastAppointment = appointment;
          }
        }
      }
      if (lastAppointment != null && lastAppointment.address) {
        origins.push(
          `${lastAppointment.address.street ?? ''} ${
            lastAppointment.address.houseNumber
          }${lastAppointment.address.houseNumberAddition}, ${
            lastAppointment.address.postal
          } ${lastAppointment.address?.place ?? ''}, Netherlands`
        );
        userLocations[userData.id] = lastAppointment.address;
      } else if (userData.address) {
        origins.push(
          `${userData.address?.street ?? ''} ${userData.address.houseNumber}${
            userData.address.houseNumberAddition
          }, ${userData.address.postal} ${
            userData.address?.place ?? ''
          }, Netherlands`
        );
        userLocations[userData.id] = userData.address;
      }
    }
    if (origins.length > 0) {
      console.log('calling API');
      const callable = httpsCallable(
        functions,
        'httpGetDistancesFromGoogleMatrixApi'
      );
      const result: any = await callable({
        origins: origins,
        destinations: [destination],
      });
      const resultData = result.data?.distances;
      if (resultData && resultData.status == 'OK') {
        const rows = resultData.rows;
        let i = 0;
        for (const timeSuggestionUser of this.availableUsers) {
          const userData = timeSuggestionUser.user;
          if (this.distanceError[userData.id]) {
            continue;
          }
          const element = rows[i].elements[0] as any;
          if (element.status != 'OK') {
            this.distanceError[userData.id] = 'noDistance';
            continue;
          }
          const distance = element.distance.value;
          for (const labelId of userData.workData?.distanceLabels) {
            const label = this.distanceLabels[labelId];
            const rangeStart = label.rangeStart
              ? label.rangeStart * 1000
              : null;
            const rangeEnd = label.rangeEnd ? label.rangeEnd * 1000 : null;
            if (
              (!rangeStart && rangeEnd > distance) ||
              (!rangeEnd && rangeStart < distance) ||
              (rangeStart <= distance && rangeEnd >= distance)
            ) {
              this.usersDistanceLabels[userData.id] = label;
              this.planningUserDistanceData.push({
                userId: userData.id,
                lastAddress: userLocations[userData.id],
                distanceLabel: this.distanceLabels[labelId],
              } as AppointmentUserDistance);
              break;
            }
          }
          if (!this.usersDistanceLabels[userData.id]) {
            this.distanceError[userData.id] = 'noLabel';
          }
          i++;
        }
        this.lastSelectedTime.start = this.dateForm.controls.start.value;
        this.lastSelectedTime.end = this.dateForm.controls.end.value;
        this.lastDestination = destination;
        this.distanceApiFailed = false;
      } else {
        // console.log(result);
        this.distanceApiFailed = true;
        this.snackBar.open(
          'Het berekenen van de afstanden tussen de klant en de coaches is mislukt.',
          'X',
          {
            duration: 5000,
          }
        );
      }
    }
    this.callingDistanceApi = false;
  }

  async save() {
    if (this.saving) {
      return;
    }
    this.saving = true;

    const appointmentData = this.appointmentData();
    await this.notifyUsers(appointmentData);
    appointmentData.calendarSequence = this.calendarSequence;

    const appointmentRef = doc(
      db,
      `township/${this.townshipId}/appointments/${this.appointmentId}`
    );
    // console.log(appointmentData);
    await setDoc(appointmentRef, appointmentData, { merge: true });
    const callable = httpsCallable(functions, 'pipedriveCreateActivity');
    const result = await callable({
      townshipId: this.townshipId,
      appointmentId: this.appointmentId,
      actionType: this.actionType,
    });
    this.dialogRef.close(true);
  }

  async notifyUsers(appointmentData: Appointment) {
    const previousNotificationData = await getDoc(
      doc(
        db,
        `township/${this.townshipId}/appointmentNotificationQueue/${this.appointmentId}`
      )
    );

    const planningSettings = (
      await getDoc(doc(db, `township/${this.townshipId}/settings/planning`))
    ).data();

    const township = (
      await getDoc(doc(db, `township/${this.townshipId}`))
    ).data() as Township;

    const planningUsersToMail = [];
    appointmentData.planningUserIds.forEach((planningUserId) => {
      planningUsersToMail.push(
        this.planningUsers.find((element) => element.id === planningUserId)
      );
    });

    let notificationType = 'new';
    if (this.data.appointment) {
      notificationType = 'edit';
    }
    if (previousNotificationData.exists()) {
      notificationType = previousNotificationData.data().notificationType;
    }

    if (notificationType === 'edit' && !previousNotificationData.exists()) {
      this.calendarSequence = this.data.appointment.calendarSequence ?? 0 + 1;
    }

    const notificationData = {
      appointmentUserToMail: {
        name: appointmentData.userName,
        email: appointmentData.userEmail,
        phone: appointmentData.userPhone,
      },
      planningUsersToMail: planningUsersToMail,
      postal: appointmentData.postal,
      houseNumber: appointmentData.houseNumber,
      houseNumberAddition: appointmentData.houseNumberAddition ?? null,
      street: appointmentData.street ?? null,
      city: appointmentData.city ?? null,
      appointmentStart: appointmentData.start,
      appointmentEnd: appointmentData.end,
      voucherId: appointmentData?.voucherId ?? null,
      notificationCreationDate: new Date(),
      notificationType: notificationType,
      calendarSequence: this.calendarSequence,
      appointmentType: appointmentData.appointmentTypeName,
      internalAppointment: appointmentData?.internalAppointment ?? false,
      appointmentRepeatValue: appointmentData?.repeatValue ?? null,
      coachesRemoved: [],
      coachesAdded: [],
      planningPhone: planningSettings.phoneNumber,
      voucher: appointmentData?.voucherId
        ? this.getVoucherById(appointmentData.voucherId)
        : null,
      township: township,
    };

    if (
      this.data.appointment &&
      !previousNotificationData.exists() &&
      this.data.appointment.planningUserIds !== appointmentData.planningUserIds
    ) {
      const coachesRemoved = [];
      const coachesAdded = [];

      this.data.appointment.planningUserIds.forEach((userId) => {
        if (
          appointmentData.planningUserIds.findIndex((id) => id === userId) ===
          -1
        ) {
          coachesRemoved.push(userId);
        }
      });
      appointmentData.planningUserIds.forEach((userId) => {
        if (
          this.data.appointment.planningUserIds.findIndex(
            (id) => id === userId
          ) === -1
        ) {
          coachesAdded.push(userId);
        }
      });
      notificationData['coachesRemoved'] = coachesRemoved;
      notificationData['coachesAdded'] = coachesAdded;
    }
    await setDoc(
      doc(
        db,
        `township/${this.townshipId}/appointmentNotificationQueue/${this.appointmentId}`
      ),
      notificationData,
      { merge: true }
    );
  }

  errorMessage(control, form = this.whoForm) {
    switch (control) {
      case 'postal':
        if (form.controls.postal.hasError('postalValidator')) {
          return 'De ingevulde postcode is incorrect';
        }
        if (form.controls.postal.hasError('required')) {
          return 'Een postcode is vereist';
        }
        break;
      case 'houseNumber':
        if (form.controls.houseNumber.hasError('numberValidator')) {
          return 'Het ingevulde huisnummer is incorrect';
        }
        if (form.controls.houseNumber.hasError('required')) {
          return 'Een huisnummer is vereist';
        }
        break;
      case 'street':
        if (form.controls.street.hasError('required')) {
          return 'Een straatnaam is vereist';
        }
        break;
      case 'city':
        if (form.controls.city.hasError('required')) {
          return 'Een plaatsnaam is vereist';
        }
        break;
      case 'repeatEndDate':
        if (form.controls.repeatEndDate.hasError('maxDateExceeded')) {
          return `De eindatum mag niet later dan ${moment()
            .add(2, 'y')
            .format('DD-MM-YYYY')} plaatsvinden`;
        }
        if (form.controls.repeatEndDate.hasError('required')) {
          return 'Een einddatum voor de herhaling is vereist';
        }
        break;
      case 'repeatValue':
        if (form.controls.repeatValue.hasError('required')) {
          return 'Dit veld is verplicht';
        }
        break;
    }
  }

  getChipToolTip(userId) {
    if (
      this.distanceApiFailed ||
      (this.distanceError[userId] && this.distanceError[userId] == 'noDistance')
    ) {
      return 'Het ophalen van de afstand is mislukt';
    } else if (
      this.distanceError[userId] &&
      this.distanceError[userId] == 'noLabel'
    ) {
      return 'De gebruiker wil deze afstand niet reizen';
    }
    return '';
  }

  capitalizeFirstLetter(val: string) {
    return String(val).charAt(0).toUpperCase() + String(val).slice(1);
  }

  maxDateValidator(): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      if (!c.value) return null;
      const value = moment(c.value).set({ hour: 0, minutes: 0 });
      const max = moment().set({ hour: 0, minutes: 0 }).add(2, 'y');

      return value.isAfter(max) ? { maxDateExceeded: true } : null;
    };
  }
}

interface TimeSuggestion {
  start: Date;
  end: Date;
  dayParts: DayPart[];
  day: string;
  month: string;
  availableUsers: TimeSuggestionUser[];
}

interface TimeSuggestionUser {
  id: string;
  user: PlanningUser;
  circularProgress: string;
  // maxNumberOfAppointments: number;
  // amountOfAppointments: number;
  maxTime: string;
  timeUsed: string;
}
