import { Component, OnInit } from '@angular/core';
import { dataStore } from '../app.component';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
})
export class StatisticsComponent implements OnInit {
  township = dataStore.township;
  reportUrl;
  constructor(
    private router: Router,
    private sanitizer: DomSanitizer,
    private snackbar: MatSnackBar
  ) {}

  async ngOnInit() {
    if (!this.township.lookerStudioReportUrl) {
      this.router.navigate(['/dashboard-township']);
      this.snackbar.open(
        'De statistieken zijn nog niet ingesteld voor uw gemeente. Wilt u de statistiekenpagina gebruiken? Neem dan contact op met weMaron.',
        'X',
        {
          duration: 5000,
        }
      );
    }
    this.reportUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.township.lookerStudioReportUrl
    );
  }
}
