import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { doc, DocumentSnapshot, getDoc, getDocs } from 'firebase/firestore';
import {
  BadlyInsulatedPartsTitles,
  Reports,
  SubsidyOptionNames,
  VoucherStatus,
} from 'src/app/enums';
import { CellStyleOptions, Township, Voucher } from 'src/app/interfaces';
import * as ExcelJS from 'exceljs';
import moment from 'moment';

@Component({
  selector: 'app-export-report-dialog-component',
  templateUrl: './export-report-dialog-component.html',
  styleUrls: ['./export-report-dialog-component.scss'],
})
export class ExportReportDialogComponent implements OnInit {
  loadingPage: boolean = true;
  township: Township;
  subsidyOptionsNameList: string[] = Object.values(SubsidyOptionNames);
  reports = Reports;
  currentYear: number = new Date().getFullYear();
  voucherGroupFormList: [
    {
      id: string;
      name: string;
      formControl: string;
    }?
  ] = [];

  exportReportForm: FormGroup = new FormGroup({
    selectedReport: new FormControl('', Validators.required),
    startDate: new FormControl('', Validators.required),
    endDate: new FormControl('', Validators.required),
  });
  taxImage: number;
  monumentAddresses: any[] = [];

  constructor(
    private snackbar: MatSnackBar,
    private db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  async ngOnInit(): Promise<any> {
    await this.getAndPrepareData();
    this.loadingPage = false;
  }

  async getAndPrepareData() {
    const townshipDoc = await getDoc(
      doc(this.db.firestore, `township/${this.data.townshipId}`)
    );
    this.township = { ...townshipDoc.data(), id: townshipDoc.id } as Township;

    const voucherGroupDocs = await getDocs(
      this.db.firestore
        .collection(`township/${this.data.townshipId}/voucherGroups`)
        .where('sendBurdenOfProof', '==', true)
    );

    voucherGroupDocs.forEach((voucherGroupDoc) => {
      const voucherGroupData = {
        ...voucherGroupDoc.data(),
        id: voucherGroupDoc.id,
      } as Township;
      const pushObject = {
        id: voucherGroupData.id,
        name: voucherGroupData.name,
        formControl: voucherGroupData.id,
      };

      this.exportReportForm.addControl(
        pushObject.formControl,
        new FormControl(false)
      );

      this.voucherGroupFormList.push(pushObject);
    });

    const monumentAddressesDocs = await getDocs(
      this.db.firestore
        .collection(`township/${this.data.townshipId}/addresses`)
        .where('type', 'array-contains', 'Monument')
    );
    monumentAddressesDocs.forEach((doc) => {
      this.monumentAddresses.push({ id: doc.id, ...doc.data() });
    });
  }

  checkCheckboxes() {
    if (this.voucherGroupFormList.length == 0) {
      return true;
    }
    let returnBoolean: boolean = false;
    this.voucherGroupFormList.forEach((voucherGroupForm) => {
      if (this.exportReportForm.controls[voucherGroupForm.formControl].value) {
        returnBoolean = true;
      }
    });
    return returnBoolean;
  }

  exportReport() {
    if (this.exportReportForm.invalid) {
      return this.exportReportForm.markAllAsTouched();
    }

    switch (this.exportReportForm.controls.selectedReport.value) {
      case Reports.sisaReport:
        this.sisaReport();
        break;
    }
  }

  async sisaReport() {
    const startDate = this.exportReportForm.controls.startDate.value;
    const endDate = this.exportReportForm.controls.endDate.value;

    if (startDate > endDate) {
      return this.snackbar.open(
        'Startdatum mag niet later zijn dat de einddatum',
        'X',
        {
          duration: 5000,
        }
      );
    }

    const voucherGroupIdsToCheck = [];

    this.voucherGroupFormList.forEach((voucherGroupForm) => {
      if (this.exportReportForm.controls[voucherGroupForm.formControl].value) {
        voucherGroupIdsToCheck.push(voucherGroupForm.id);
      }
    });

    try {
      const voucherDocs = await getDocs(
        this.db.firestore
          .collection(`township/${this.data.townshipId}/vouchers`)
          .where('paidDate', '>=', startDate)
          .where('paidDate', '<', endDate)
          .where('voucherGroupId', 'in', voucherGroupIdsToCheck)
          .orderBy('paidDate', 'desc')
      );

      const vouchersToExport = this.shouldExportSubsidyOptionInSisaReport(
        voucherDocs.docs
      );

      if (vouchersToExport.length === 0) {
        return this.snackbar.open(
          'Geen bonnen gevonden binnen de gegeven begindatum en einddatum',
          'X',
          {
            duration: 5000,
          }
        );
      } else {
        this.snackbar.open('Genereer rapport…', 'X', {
          duration: 5000,
        });
        this.insertDataInSisaReport(vouchersToExport);
      }
    } catch (e) {
      this.snackbar.open('Oeps! Er is iets misgegaan', 'X', {
        duration: 5000,
      });
    }
  }

  async insertDataInSisaReport(vouchers: Voucher[]) {
    const workbook = new ExcelJS.Workbook();

    this.taxImage = workbook.addImage({
      base64: await this.getImageAsBase64(
        '/assets/images/excel-images/netherlands-tax-full.png'
      ),
      extension: 'jpeg',
    });

    const targetedSupportWorksheet = workbook.addWorksheet(
      'Gerichte ondersteuning'
    );

    this.targetedSupportWorkSheet(targetedSupportWorksheet, vouchers.length);

    const improvedHousingWorksheet = workbook.addWorksheet(
      'Verbeterde woningen'
    );

    this.improvedHousingWorksheet(improvedHousingWorksheet, vouchers);

    this.downloadExcelFile(
      workbook,
      'Monitoringsbestand SpUk Lokale Aanpak Isolatie.xlsx'
    );
  }

  targetedSupportWorkSheet(worksheet: ExcelJS.Worksheet, voucherCount) {
    worksheet.views = [{ showGridLines: false }];

    worksheet.columns = [
      { key: 'A', width: 80 },
      { key: 'B', width: 80 },
      { key: 'C', width: 8 },
      { key: 'D', width: 25 },
      { key: 'E', width: 25 },
      { key: 'F', width: 25 },
      { key: 'G', width: 40 },
      { key: 'H', width: 20 },
      { key: 'I', width: 60 },
      { key: 'J', width: 50 },
    ];

    worksheet.mergeCells('D2:F2');
    worksheet.mergeCells('D3:F3');
    worksheet.mergeCells('D4:F4');
    worksheet.mergeCells('D5:F5');
    worksheet.mergeCells('D6:F6');
    worksheet.mergeCells('D7:F7');
    worksheet.mergeCells('D8:F8');
    worksheet.mergeCells('H2:I2');

    worksheet.getRow(1).height = 180;

    worksheet.addImage(this.taxImage, {
      tl: { col: 4, row: 0 },
      ext: { width: 303, height: 167 },
      editAs: 'absolute',
    });

    worksheet.getCell('A3').value = 'Monitoring SpUk Lokale Aanpak Isolatie:';
    worksheet.getCell('A3').style = this.getCellStyle({
      bold: true,
      size: 12,
      cellColor: 'F9F9F9',
    });

    worksheet.getCell('A4').value =
      "Gemiddelde WOZ waarde van de koopwoningen (in euro's):";
    worksheet.getCell('A4').style = this.getCellStyle({ cellColor: 'F9F9F9' });

    worksheet.getCell('A5').value =
      'Totaal aantal koopwoningen in uw gemeente in 2022:';
    worksheet.getCell('A5').style = this.getCellStyle({ cellColor: 'F9F9F9' });

    worksheet.getCell('A6').value =
      'Aantal aangevraagde woningen in 2023/2024:';
    worksheet.getCell('A6').style = this.getCellStyle({ cellColor: 'F9F9F9' });

    worksheet.getCell('A7').value = 'Monitoringsperiode:';
    worksheet.getCell('A7').style = this.getCellStyle({ cellColor: 'F9F9F9' });

    worksheet.getCell('B3').value = this.township.name;
    worksheet.getCell('B3').style = this.getCellStyle({
      bold: true,
      size: 12,
      horizontalAlignment: 'center',
      cellColor: 'F9F9F9',
    });

    worksheet.getCell('B4').value = '';
    worksheet.getCell('B4').style = this.getCellStyle({
      size: 16,
      horizontalAlignment: 'right',
    });

    worksheet.getCell('B5').value = '';
    worksheet.getCell('B5').style = this.getCellStyle({
      size: 16,
      horizontalAlignment: 'right',
    });

    worksheet.getCell('B6').value = '';
    worksheet.getCell('B6').style = this.getCellStyle({
      size: 16,
      horizontalAlignment: 'right',
    });

    worksheet.getCell('B7').value = `${moment(
      this.exportReportForm.controls.startDate.value
    ).format('DD/MM/YYYY')} - ${moment(
      this.exportReportForm.controls.endDate.value
    ).format('DD/MM/YYYY')}`;
    worksheet.getCell('B7').style = this.getCellStyle({
      bold: true,
      horizontalAlignment: 'center',
    });

    worksheet.getCell('D2').value =
      'Monitoring: Gerichte ondersteuning in de periode:';
    worksheet.getCell('D2').style = this.getCellStyle({
      bold: true,
      size: 12,
      cellColor: 'F9F9F9',
    });

    worksheet.getCell('D3').style = this.getCellStyle({ cellColor: 'F9F9F9' });

    worksheet.getCell('D4').value =
      "Totaal aantal geïnformeerde/benaderde eigenaar-bewoners/VvE's:";
    worksheet.getCell('D4').style = this.getCellStyle({ cellColor: 'F9F9F9' });

    worksheet.getCell('D5').value = 'Aantal afgegeven energie-adviezen:';
    worksheet.getCell('D5').style = this.getCellStyle({ cellColor: 'F9F9F9' });

    worksheet.getCell('D6').value =
      'Aantal begeleide subsidieaanvragen, zowel ISDE als SVVE:';
    worksheet.getCell('D6').style = this.getCellStyle({ cellColor: 'F9F9F9' });

    worksheet.getCell('D7').value =
      'Aantal afgegeven financiële adviezen/ondersteuning bij financiering:';
    worksheet.getCell('D7').style = this.getCellStyle({ cellColor: 'F9F9F9' });

    worksheet.getCell('D8').value =
      'Aantal georganiseerde straat/wijk/grootschalige aanpakken:';
    worksheet.getCell('D8').style = this.getCellStyle({ cellColor: 'F9F9F9' });

    worksheet.getCell('E2').style = this.getCellStyle();

    worksheet.getCell('E3').style = this.getCellStyle();

    worksheet.getCell('E4').style = this.getCellStyle();

    worksheet.getCell('E5').style = this.getCellStyle();

    worksheet.getCell('E6').style = this.getCellStyle();

    worksheet.getCell('E7').style = this.getCellStyle();

    worksheet.getCell('E8').style = this.getCellStyle();

    worksheet.getCell('F2').style = this.getCellStyle();

    worksheet.getCell('F3').style = this.getCellStyle();

    worksheet.getCell('F4').style = this.getCellStyle();

    worksheet.getCell('F5').style = this.getCellStyle();

    worksheet.getCell('F6').style = this.getCellStyle();

    worksheet.getCell('F7').style = this.getCellStyle();

    worksheet.getCell('F8').style = this.getCellStyle();

    worksheet.getCell('G2').value = 'Grondgebonden woningen';
    worksheet.getCell('G2').style = this.getCellStyle({
      bold: true,
      size: 12,
      horizontalAlignment: 'center',
      cellColor: 'F9F9F9',
    });

    worksheet.getCell('G3').value = '';
    worksheet.getCell('G3').style = this.getCellStyle({
      size: 16,
      horizontalAlignment: 'right',
      cellColor: 'F9F9F9',
    });

    worksheet.getCell('G4').value = '';
    worksheet.getCell('G4').style = this.getCellStyle({
      size: 16,
      horizontalAlignment: 'right',
    });

    worksheet.getCell('G5').value = '';
    worksheet.getCell('G5').style = this.getCellStyle({
      size: 16,
      horizontalAlignment: 'right',
    });

    worksheet.getCell('G6').value = '';
    worksheet.getCell('G6').style = this.getCellStyle({
      size: 16,
      horizontalAlignment: 'right',
    });

    worksheet.getCell('G7').value = '';
    worksheet.getCell('G7').style = this.getCellStyle({
      size: 16,
      horizontalAlignment: 'right',
    });

    worksheet.getCell('G8').value = '';
    worksheet.getCell('G8').style = this.getCellStyle({
      size: 16,
      horizontalAlignment: 'right',
    });

    worksheet.getCell('H2').value = "VvE's";
    worksheet.getCell('H2').style = this.getCellStyle({
      bold: true,
      size: 12,
      horizontalAlignment: 'center',
      cellColor: 'F9F9F9',
    });

    worksheet.getCell('H3').value = "Aantal VvE's";
    worksheet.getCell('H3').style = this.getCellStyle({
      bold: true,
      size: 12,
      horizontalAlignment: 'center',
      cellColor: 'F9F9F9',
    });

    worksheet.getCell('H4').value = '';
    worksheet.getCell('H4').style = this.getCellStyle({
      size: 16,
      horizontalAlignment: 'right',
    });

    worksheet.getCell('H5').value = '';
    worksheet.getCell('H5').style = this.getCellStyle({
      size: 16,
      horizontalAlignment: 'right',
    });

    worksheet.getCell('H6').value = '';
    worksheet.getCell('H6').style = this.getCellStyle({
      size: 16,
      horizontalAlignment: 'right',
    });

    worksheet.getCell('H7').value = '';
    worksheet.getCell('H7').style = this.getCellStyle({
      size: 16,
      horizontalAlignment: 'right',
    });

    worksheet.getCell('H8').value = '';
    worksheet.getCell('H8').style = this.getCellStyle({
      size: 16,
      horizontalAlignment: 'right',
    });

    worksheet.getCell('I2').style = this.getCellStyle({ cellColor: 'F9F9F9' });

    worksheet.getCell('I3').value = "Totaal aantal woningen in die VvE's";
    worksheet.getCell('I3').style = this.getCellStyle({
      bold: true,
      size: 12,
      horizontalAlignment: 'center',
      cellColor: 'F9F9F9',
    });

    worksheet.getCell('I4').value = '';
    worksheet.getCell('I4').style = this.getCellStyle({
      size: 16,
      horizontalAlignment: 'right',
    });

    worksheet.getCell('I5').value = '';
    worksheet.getCell('I5').style = this.getCellStyle({
      size: 16,
      horizontalAlignment: 'right',
    });

    worksheet.getCell('I6').value = '';
    worksheet.getCell('I6').style = this.getCellStyle({
      size: 16,
      horizontalAlignment: 'right',
    });

    worksheet.getCell('I7').value = '';
    worksheet.getCell('I7').style = this.getCellStyle({
      size: 16,
      horizontalAlignment: 'right',
    });

    worksheet.getCell('I8').value = '';
    worksheet.getCell('I8').style = this.getCellStyle({
      size: 16,
      horizontalAlignment: 'right',
    });

    worksheet.getCell('J2').value = 'Totaal aantal woningen*';
    worksheet.getCell('J2').style = this.getCellStyle({
      bold: true,
      size: 12,
      horizontalAlignment: 'center',
      cellColor: 'F9F9F9',
    });

    worksheet.getCell('J3').value = '';
    worksheet.getCell('J3').style = this.getCellStyle({
      size: 16,
      horizontalAlignment: 'center',
      cellColor: 'F9F9F9',
    });

    worksheet.getCell('J4').value = { formula: 'SUM(G4,I4)' };
    worksheet.getCell('J4').style = this.getCellStyle({
      size: 16,
      horizontalAlignment: 'right',
    });

    worksheet.getCell('J5').value = { formula: 'SUM(G5,I5)' };
    worksheet.getCell('J5').style = this.getCellStyle({
      size: 16,
      horizontalAlignment: 'right',
    });

    worksheet.getCell('J6').value = { formula: 'SUM(G6,I6)' };
    worksheet.getCell('J6').style = this.getCellStyle({
      size: 16,
      horizontalAlignment: 'right',
    });

    worksheet.getCell('J7').value = { formula: 'SUM(G7,I7)' };
    worksheet.getCell('J7').style = this.getCellStyle({
      size: 16,
      horizontalAlignment: 'right',
    });

    worksheet.getCell('J8').value = { formula: 'SUM(G8,I8)' };
    worksheet.getCell('J8').style = this.getCellStyle({
      size: 16,
      horizontalAlignment: 'right',
    });

    worksheet.getCell('J9').value = '';
    worksheet.getCell('J9').style = this.getCellStyle({
      size: 16,
      horizontalAlignment: 'right',
      hideBorder: true,
    });

    worksheet.getCell('J10').value = '* Is het totaal van kolom G en I';
    worksheet.getCell('J10').style = this.getCellStyle({
      bold: true,
      size: 12,
      hideBorder: true,
    });
    worksheet.getCell('A12').value = {
      text: 'Zie ook tabblad Verbeterde woningen',
      hyperlink: "#'Verbeterde woningen'!A1",
    };

    worksheet.getCell('A12').style.font = {
      color: { argb: 'FF0000FF' },
      underline: true,
    } as ExcelJS.Font;
  }

  async improvedHousingWorksheet(
    worksheet: ExcelJS.Worksheet,
    vouchers: Voucher[]
  ) {
    worksheet.views = [{ showGridLines: false }];

    worksheet.columns = [
      { key: 'A', width: 20 },
      { key: 'B', width: 10 },
      { key: 'C', width: 20 },
      { key: 'D', width: 20 },
      { key: 'E', width: 20 },
      { key: 'F', width: 25 },
      { key: 'G', width: 25 },
      { key: 'H', width: 25 },
      { key: 'I', width: 25 },
      { key: 'J', width: 25 },
      { key: 'K', width: 25 },
      { key: 'L', width: 25 },
      { key: 'M', width: 20 },
      { key: 'N', width: 15 },
      { key: 'O', width: 15 },
      { key: 'P', width: 15 },
      { key: 'Q', width: 15 },
      { key: 'R', width: 15 },
      { key: 'S', width: 15 },
      { key: 'T', width: 15 },
      { key: 'U', width: 15 },
      { key: 'V', width: 15 },
      { key: 'W', width: 15 },
      { key: 'X', width: 15 },
      { key: 'Y', width: 15 },
      { key: 'Z', width: 15 },
      { key: 'AA', width: 15 },
      { key: 'AB', width: 15 },
      { key: 'AC', width: 15 },
      { key: 'AD', width: 15 },
      { key: 'AE', width: 15 },
      { key: 'AF', width: 15 },
      { key: 'AG', width: 15 },
      { key: 'AH', width: 15 },
      { key: 'AI', width: 15 },
      { key: 'AJ', width: 15 },
      { key: 'AK', width: 15 },
    ];

    worksheet.getRow(1).height = 120;
    worksheet.getRow(9).height = 50;
    worksheet.getRow(10).height = 80;
    worksheet.getRow(11).height = 30;
    worksheet.getRow(12).height = 50;
    worksheet.getRow(13).height = 50;
    worksheet.getRow(14).height = 30;
    worksheet.getRow(17).height = 30;

    worksheet.mergeCells('A1:R1');
    worksheet.mergeCells('T1:U1');
    worksheet.mergeCells('A2:R2');
    worksheet.mergeCells('A3:AK3');
    worksheet.mergeCells('A4:AK4');
    worksheet.mergeCells('A5:AK5');
    worksheet.mergeCells('A6:AK6');
    worksheet.mergeCells('A7:AK7');
    worksheet.mergeCells('A8:AK8');
    worksheet.mergeCells('N10:Q10');
    worksheet.mergeCells('R10:U10');
    worksheet.mergeCells('V10:Y10');
    worksheet.mergeCells('Z10:AG10');
    worksheet.mergeCells('AH10:AK10');

    worksheet.addImage(this.taxImage, {
      tl: { col: 18, row: 0 },
      ext: { width: 303, height: 167 },
      editAs: 'absolute',
    });

    worksheet.getCell(
      'A1'
    ).value = `Woningbestand Lokale Aanpak Isolatie 2023 en volgende jaren `;
    worksheet.getCell('A1').style = this.getCellStyle({
      bold: true,
      size: 28,
      color: 'FF007BC7',
      hideBorder: true,
      verticalAlignment: 'bottom',
    });

    worksheet.getCell('A3').value =
      'Deze Excel-tabel dient als monitoringsoverzicht voor de SPUk Lokale Aanpak Isolatie. Bij welke (koop)woningen zijn welke energiebesparende isolatiemaatregelen of is er een eventueel energiezuinig ventilatiesysteem aangebracht door derden of via de Doe-Het-Zelf-aanpak.';
    worksheet.getCell('A3').style = this.getCellStyle({
      hideBorder: true,
      bold: true,
    });
    worksheet.getCell('A4').value =
      'Twee of meer maatregelen uitgevoerd aan dezelfde woning telt als 1 unieke woning. Meerdere maatregelen aan dezelfde woning uitgevoerd in het zelfde jaar mogen achter elkaar geschreven worden,  bij uitvoering in een ander jaar kan er onderaan in het bestand een nieuwe regel worden ingevuld.  ';
    worksheet.getCell('A4').style = this.getCellStyle({
      hideBorder: true,
      bold: true,
    });
    worksheet.getCell('A5').value =
      "Een maatregel telt pas mee als maatregel voor het halen van het aantal woningen als men voldoet aan de isolatie- en minimale oppervlakte-eisen van de ISDE voor eigenaar/bewoners en aan de SVVE voor verenigingen (o.a. VvE's). (Let op: uitzondering van maximaal 10 % van het aantal woningen bij niet voldoen aan minimale oppervlakte-eis) ";
    worksheet.getCell('A5').style = this.getCellStyle({
      hideBorder: true,
      bold: true,
    });
    worksheet.getCell('A6').value =
      'Bij glas, kozijnpanelen en isolerende deuren is iedere combinatie van isolatiewaarden mogelijk (bijvoorbeeld een aantal m2 HR++ glas i.c.m. een aantal m2 triple glas incl. nieuw isolerend kozijn elders in de woning óf een aantal m2 HR++ glas in combinatie met een aantal m2 isolerende deuren), dit komt overeen met de bestaande subsidieregelingen (ISDE, SVVE). ';
    worksheet.getCell('A6').style = this.getCellStyle({
      hideBorder: true,
      bold: true,
    });
    worksheet.getCell('A7').value =
      'De kolommen F, G en K t/m AK zijn voorzien van een dropdown menu.';
    worksheet.getCell('A7').style = this.getCellStyle({
      hideBorder: true,
      bold: true,
    });
    worksheet.getCell('A8').value =
      'Kolom G en H zijn niet verplicht om in te vullen.';
    worksheet.getCell('A8').style = this.getCellStyle({
      hideBorder: true,
      bold: true,
    });

    worksheet.columns.forEach((column) => {
      let cellValue = '';
      if (
        column.key !== 'A' &&
        column.key !== 'B' &&
        column.key !== 'C' &&
        column.key !== 'D' &&
        column.key !== 'E' &&
        column.key !== 'F' &&
        column.key !== 'G' &&
        column.key !== 'H' &&
        column.key !== 'I' &&
        column.key !== 'J' &&
        column.key !== 'K' &&
        column.key !== 'M' &&
        column.key !== 'L'
      ) {
        worksheet.getCell(`${column.key}10`).style = this.getCellStyle({
          horizontalAlignment: 'center',
        });
      }
      let boldText: boolean = false;
      switch (column.key) {
        case 'N':
          cellValue =
            'Beide uitvoeringsvormen zijn varianten van dezelfde maatregel, de bewoner kiest voor of dakisolatie of zolder/vlieringvloerisolatie  (een combinatie hiervan kan in een enkel geval aan de orde zijn, maar het blijft 1 maatregel)';
          break;
        case 'R':
          cellValue =
            'Beide uitvoeringsvormen zijn naast elkaar mogelijk, zoals bij de ISDE en de SVVE';
          break;
        case 'V':
          cellValue =
            'Beide uitvoeringsvormen zijn varianten van dezelfde maatregel, de bewoner kiest voor vloerisolatie of voor  bodemisolatie (een combinatie hiervan kan in een enkel geval aan de orde zijn, maar het blijft 1 maatregel)';
          break;
        case 'Z':
          cellValue =
            'Vanaf 1 januari 2025 mag glas, panelen in kozijnen en isolerende deuren maximaal tweemaal worden aangevraagd in de ISDE en de SVVE';
          boldText = true;
          break;
        case 'AH':
          cellValue =
            'Mits van toepassing kiezen voor 1 van de 2 ventilatiesystemen, beiden kan niet.';
          break;
      }
      if (cellValue !== '') {
        worksheet.getCell(`${column.key}10`).value = cellValue;
        worksheet.getCell(`${column.key}10`).style = this.getCellStyle({
          bold: boldText,
          cellColor: 'F9F9F9',
          verticalAlignment: 'top',
          horizontalAlignment: 'center',
        });
      }
    });

    worksheet.columns.forEach((column) => {
      let main, supporting, hint, optional, mergeWithColumn, subsidyOption;
      switch (column.key) {
        case 'A':
          main = 'Straat';
          break;
        case 'B':
          main = 'nr.';
          break;
        case 'C':
          main = 'Toevoeging';
          break;
        case 'D':
          main = 'Postcode';
          break;
        case 'E':
          main = 'Woonplaats';
          break;
        case 'F':
          main =
            'WOZ-waarde boven of onder gemiddelde WOZ-waarde van de koopwoningen in de betreffende gemeente of de NHG-grens';
          break;
        case 'G':
          main = 'Evt. aanwezig energielabel';
          hint = '(sleepmenu D t/m G)';
          optional = 'invullen optioneel';
          break;
        case 'H':
          main = 'Opsomming van energetisch slechte schil-elementen';
          hint = '(minimaal 2)';
          optional = 'invullen optioneel';
          break;
        case 'I':
          main = 'Jaar uitvoering maatregel(en)';
          break;
        case 'J':
          main = 'Toegekend bedrag';
          hint = "(in euro's)";
          break;
        case 'K':
          main = 'Voldoet aan de minimale oppervlakte-eis';
          hint = '(ja/nee)';
          break;
        case 'L':
          main = 'Voldoet aan de minimale isolatiewaarden';
          hint = '(ja/nee)';
          break;
        case 'M':
          main = 'Monument';
          hint = '(ja/nee)';
          break;
        case 'N':
          main = 'Dakisolatie';
          hint = '(vul ja/nee in \r\nOF het aantal m2)';
          mergeWithColumn = 'O';
          subsidyOption = true;
          break;
        case 'P':
          main = 'Zolder-/vlieringvloerisolatie';
          hint = '(vul ja/nee in \r\nOF het aantal m2)';
          mergeWithColumn = 'Q';
          subsidyOption = true;
          break;
        case 'R':
          main = 'Spouwmuurisolatie';
          hint = '(vul ja/nee in \r\nOF het aantal m2)';
          mergeWithColumn = 'S';
          subsidyOption = true;
          break;
        case 'T':
          main = 'Gevelisolatie (zowel binnen- als buitengevel-isolatie)';
          hint = '(vul ja/nee in \r\nOF het aantal m2)';
          mergeWithColumn = 'U';
          subsidyOption = true;
          break;
        case 'V':
          main = 'Vloerisolatie';
          hint = '(vul ja/nee in \r\nOF het aantal m2)';
          mergeWithColumn = 'W';
          subsidyOption = true;
          break;
        case 'X':
          main = 'Bodemisolatie';
          hint = '(vul ja/nee in \r\nOF het aantal m2)';
          mergeWithColumn = 'Y';
          subsidyOption = true;
          break;
        case 'Z':
          main = 'Glas en panelen in kozijnen';
          supporting = ' Ug en Up ≤ 1,2 W/m2K';
          hint = '(vul ja/nee in \r\nOF het aantal m2)';
          mergeWithColumn = 'AA';
          subsidyOption = true;
          break;
        case 'AB':
          main = 'Isolerende deuren';
          supporting = 'Ud ≤ 1,5 W/m2K';
          hint = '(vul ja/nee in \r\nOF het aantal m2)';
          mergeWithColumn = 'AC';
          subsidyOption = true;
          break;
        case 'AD':
          main = 'Glas en panelen in kozijnen met nieuwe isolerende kozijnen';
          supporting = 'Ug en Up ≤ 0,7 W/m2K, Uf ≤ 1,5 W/m2K';
          hint = '(vul ja/nee in \r\nOF het aantal m2)';
          mergeWithColumn = 'AE';
          subsidyOption = true;
          break;
        case 'AF':
          main =
            'Isolerende deuren, Ud ≤ 1,0 W/m2K, i.c.m. nieuwe isolerende kozijnen, Uf ≤ 1,5 W/m2K';
          hint = '(vul ja/nee in \r\nOF het aantal m2)';
          mergeWithColumn = 'AG';
          subsidyOption = true;
          break;
        case 'AH':
          main = 'CO2-gestuurde ventilatie';
          hint = '(ja/nee)';
          mergeWithColumn = 'AI';
          subsidyOption = true;
          break;
        case 'AJ':
          main =
            'Balansventilatie met WTW (evt. in combinatie met CO2-sturing)';
          hint = '(ja/nee)';
          mergeWithColumn = 'AK';
          subsidyOption = true;
          break;
      }
      if (mergeWithColumn) {
        worksheet.mergeCells(`${column.key}11:${mergeWithColumn}11`);
        worksheet.mergeCells(`${column.key}15:${mergeWithColumn}15`);
        worksheet.mergeCells(`${column.key}16:${mergeWithColumn}16`);
        if (subsidyOption) {
          worksheet.mergeCells(`${column.key}12:${mergeWithColumn}12`);
          worksheet.mergeCells(`${column.key}13:${mergeWithColumn}13`);
          worksheet.mergeCells(`${column.key}14:${mergeWithColumn}14`);
        }
      }
      let mergeSupporting = false;
      if (main) {
        worksheet.getCell(`${column.key}12`).value = main;
        if (supporting) {
          worksheet.getCell(`${column.key}13`).value = supporting;
        } else {
          mergeSupporting = true;
        }
        if (mergeSupporting && !subsidyOption) {
          worksheet.mergeCells(
            `${column.key}12:${mergeWithColumn ?? column.key}13`
          );
        }
        worksheet.getCell(`${column.key}15`).value = optional ?? '';
        worksheet.getCell(`${column.key}14`).value = hint ?? '';
      }
      for (let i = 11; i <= 16; i++) {
        worksheet.getCell(column.key + i).style = this.getCellStyle({
          bold: true,
          italic: i == 15,
          cellColor: 'F9F9F9',
          border: {
            top: i == 11 ? { style: 'medium' } : {},
            left: { style: 'medium' },
            bottom: i == 16 ? { style: 'medium' } : {},
            right: { style: 'medium' },
          },
        });
      }
    });

    let dhzOrNot: boolean = true;
    worksheet.columns.forEach((column) => {
      worksheet.getCell(`${column.key}17`).style = this.getCellStyle({
        cellColor: 'F9F9F9',
        horizontalAlignment: 'center',
        verticalAlignment: 'middle',
      });
      if (
        column.key !== 'A' &&
        column.key !== 'B' &&
        column.key !== 'C' &&
        column.key !== 'D' &&
        column.key !== 'E' &&
        column.key !== 'F' &&
        column.key !== 'G' &&
        column.key !== 'H' &&
        column.key !== 'I' &&
        column.key !== 'J' &&
        column.key !== 'K' &&
        column.key !== 'L' &&
        column.key !== 'M'
      ) {
        worksheet.getCell(`${column.key}17`).value = dhzOrNot
          ? column.key == 'N'
            ? 'Doe het zelf-aanpak, DHZ'
            : 'DZH'
          : 'Derden';
        dhzOrNot = !dhzOrNot;
      }
    });

    let rowIndex = 18;

    vouchers.forEach((doc: Voucher) => {
      let typeValue = 'Onder';

      if (doc.type) {
        doc.type.forEach((type) => {
          if (type.toLowerCase() == 'woz boven') {
            typeValue = 'Boven';
          }
        });
      }

      let badlyInsulatedParts = [];

      doc.burdenOfProofForm?.badlyInsulatedParts?.forEach(
        (badlyInsulatedPart) => {
          switch (badlyInsulatedPart) {
            case 'flatRoof':
              badlyInsulatedParts.push(BadlyInsulatedPartsTitles.flatRoof);
              break;
            case 'roofAttic':
              badlyInsulatedParts.push(BadlyInsulatedPartsTitles.roofAttic);
              break;
            case 'facade':
              badlyInsulatedParts.push(BadlyInsulatedPartsTitles.facade);
              break;
            case 'floor':
              badlyInsulatedParts.push(BadlyInsulatedPartsTitles.floor);
              break;
            case 'glas':
              badlyInsulatedParts.push(BadlyInsulatedPartsTitles.glas);
              break;
            default:
              badlyInsulatedParts.push('');
          }
        }
      );

      worksheet.columns.forEach((column) => {
        let columnValue;
        let dropdown;
        let dropdownValues;
        switch (column.key) {
          case 'A':
            columnValue = doc.street;
            break;
          case 'B':
            columnValue = Number(doc.houseNumber);
            break;
          case 'C':
            columnValue = doc.houseNumberAddition;
            break;
          case 'D':
            columnValue = doc.postal;
            break;
          case 'E':
            columnValue = doc.city;
            break;
          case 'F':
            columnValue = typeValue;
            dropdown = true;
            dropdownValues = ['Boven', 'Onder'];
            break;
          case 'G':
            columnValue = doc.burdenOfProofForm?.energyLabel;
            dropdown = true;
            dropdownValues = ['D', 'E', 'F', 'G', 'Niet Aanwezig'];
            break;
          case 'H':
            columnValue = badlyInsulatedParts.join(', ');
            break;
          case 'I':
            columnValue = doc.paidDate.toDate().getFullYear();
            break;
          case 'J':
            const amount = doc.claimAmount ?? doc.amountToPayOrg;
            columnValue = amount
              ? new Intl.NumberFormat('nl-NL', {
                  style: 'currency',
                  currency: 'EUR',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(amount)
              : '';
            break;
          case 'K':
            columnValue = 'Ja';
            dropdown = true;
            break;
          case 'L':
            columnValue = 'Ja';
            dropdown = true;
            break;
          case 'M':
            const addressString =
              doc.postal + doc.houseNumber + doc.houseNumberAddition;
            const monument = this.monumentAddresses.filter((address) => {
              return address.id == addressString;
            });
            columnValue = monument?.length > 0 ? 'Ja' : 'Nee';
            dropdown = true;
            break;
        }
        worksheet.getCell(`${column.key}${rowIndex}`).value = columnValue;
        worksheet.getCell(`${column.key}${rowIndex}`).style =
          this.getCellStyle();
        if (dropdown) {
          worksheet.getCell(`${column.key}${rowIndex}`).dataValidation =
            this.getCellDropdown(dropdownValues);
        }
      });

      // add dropdown for subsidyOptions
      worksheet.columns.forEach((column) => {
        if (
          column.key !== 'A' &&
          column.key !== 'B' &&
          column.key !== 'C' &&
          column.key !== 'D' &&
          column.key !== 'E' &&
          column.key !== 'F' &&
          column.key !== 'G' &&
          column.key !== 'H' &&
          column.key !== 'I' &&
          column.key !== 'J' &&
          column.key !== 'K' &&
          column.key !== 'L' &&
          column.key !== 'M'
        ) {
          worksheet.getCell(`${column.key}${rowIndex}`).dataValidation =
            this.getCellDropdown();
        }
      });
      this.filterSubsidyOptions(doc, worksheet, rowIndex);
      rowIndex++;
    });

    // sum values of all subsidy options + total
    // worksheet.columns.forEach((column) => {
    //   let columnStyle = this.getCellStyle({
    //     bold: true,
    //     size: 16,
    //     horizontalAlignment: 'right',
    //     cellColor: 'FF90CCE4',
    //   });
    //   let columnValue = {
    //     formula: `SUM(${column.key}7:${column.key}${rowIndex - 1})`,
    //   };
    //   if (column.key === 'M') {
    //     columnStyle = this.getCellStyle({
    //       bold: true,
    //       size: 16,
    //       cellColor: 'FF90CCE4',
    //     });
    //     columnValue = {
    //       formula: '"Totaal: "&SUM(N9:AI9)',
    //     };
    //   }

    //   if (
    //     column.key !== 'A' &&
    //     column.key !== 'B' &&
    //     column.key !== 'C' &&
    //     column.key !== 'D' &&
    //     column.key !== 'E' &&
    //     column.key !== 'F' &&
    //     column.key !== 'G' &&
    //     column.key !== 'H' &&
    //     column.key !== 'I' &&
    //     column.key !== 'J' &&
    //     column.key !== 'K' &&
    //     column.key !== 'L'
    //   ) {
    //     worksheet.getCell(`${column.key}9`).style = columnStyle;
    //     worksheet.getCell(`${column.key}9`).value = columnValue;
    //   }
    // });
  }

  getCellStyle(options?: CellStyleOptions) {
    return {
      font: {
        name: 'Verdana',
        bold: options?.bold ?? false,
        italic: options?.italic ?? false,
        size: options?.size ?? 10,
        color: { argb: options?.color ?? '' },
      },
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: options?.cellColor ?? '' },
      },
      alignment: {
        horizontal: options?.horizontalAlignment ?? 'left',
        vertical: options?.verticalAlignment ?? 'middle',
        wrapText: true,
      },
      border: options?.hideBorder
        ? {}
        : options?.border ?? {
            top: { style: 'medium' },
            left: { style: 'medium' },
            bottom: { style: 'medium' },
            right: { style: 'medium' },
          },
    } as ExcelJS.Cell;
  }

  getCellDropdown(dropdownValues?: string[]) {
    return {
      type: 'list',
      allowBlank: true,
      formulae: dropdownValues
        ? ['"' + dropdownValues.join(',') + '"']
        : ['"Ja,Nee"'],
    } as ExcelJS.DataValidation;
  }

  shouldExportSubsidyOptionInSisaReport(voucherDocs: DocumentSnapshot[]) {
    const vouchersToExport = [];
    voucherDocs.forEach((doc: DocumentSnapshot) => {
      const voucher = doc.data() as Voucher;
      voucher.burdenOfProofForm?.subsidyOptions.forEach((subsidyOption) => {
        if (
          this.subsidyOptionsNameList.includes(subsidyOption.name) &&
          voucher.burdenOfProofForm?.status === 'Accepted'
        ) {
          if (
            !vouchersToExport.find(
              (voucherObj) => voucherObj.number === voucher.number
            ) &&
            voucher.status != VoucherStatus.expired
          ) {
            vouchersToExport.push(voucher);
          }
        }
      });
    });
    return vouchersToExport;
  }

  async getImageAsBase64(imagePath: string) {
    return new Promise<string>((resolve, reject) => {
      const img = new Image();

      img.src = imagePath;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext('2d');

        ctx.drawImage(img, 0, 0);

        const base64String = canvas.toDataURL('image/jpeg');

        resolve(base64String.split(',')[1]);
      };

      img.onerror = reject;
    });
  }

  filterSubsidyOptions(
    doc: Voucher,
    workSheet: ExcelJS.Worksheet,
    rowIndex: number
  ) {
    doc.burdenOfProofForm?.subsidyOptions.forEach((subsidyOption) => {
      let columnKey;
      switch (subsidyOption.name) {
        case SubsidyOptionNames.exteriorRoofInsulation:
        case SubsidyOptionNames.innerRoofInsulation:
          columnKey = subsidyOption.diy ? 'N' : 'O';
          break;
        case SubsidyOptionNames.atticLoftFloorInsulation:
          columnKey = subsidyOption.diy ? 'P' : 'Q';
          break;
        case SubsidyOptionNames.cavityWallInsulation:
          columnKey = subsidyOption.diy ? 'R' : 'S';
          break;
        case SubsidyOptionNames.interiorFacadeInsulation:
        case SubsidyOptionNames.exteriorFacadeInsulation:
          columnKey = subsidyOption.diy ? 'T' : 'U';
          break;
        case SubsidyOptionNames.insulationGroundFloor:
          columnKey = subsidyOption.diy ? 'V' : 'W';
          break;
        case SubsidyOptionNames.insulationBottom:
          columnKey = subsidyOption.diy ? 'X' : 'Y';
          break;
        case SubsidyOptionNames.insulatingGlassAndFramePanelsHigh:
          columnKey = subsidyOption.diy ? 'Z' : 'AA';
          break;
        case SubsidyOptionNames.insulatingDoorsHigh:
          columnKey = subsidyOption.diy ? 'AB' : 'AC';
          break;
        case SubsidyOptionNames.insulatingGlassAndFramePanelsLow:
          columnKey = subsidyOption.diy ? 'AD' : 'AE';
          break;
        case SubsidyOptionNames.insulatingDoorsLow:
          columnKey = subsidyOption.diy ? 'AF' : 'AG';
          break;
        case SubsidyOptionNames.co2Ventilation:
          columnKey = subsidyOption.diy ? 'AH' : 'AI';
          break;
        case SubsidyOptionNames.balanceVentilationWithHeatRecovery:
          columnKey = subsidyOption.diy ? 'AJ' : 'AK';
      }
      if (columnKey) {
        this.insertSubsidyOptionValues(
          columnKey,
          doc,
          subsidyOption.mainUnit,
          rowIndex,
          workSheet
        );
      }
    });
  }

  insertSubsidyOptionValues(
    columnKey: string,
    doc,
    subsidyOptionMainUnit: string,
    rowIndex: number,
    workSheet: ExcelJS.Worksheet
  ) {
    if (subsidyOptionMainUnit) {
      try {
        const mainUnitSeparated = subsidyOptionMainUnit
          .replace(/[.]/g, ',')
          .match(/^([0-9]{1,3}){1}([,.]?[0-9]{3})*([,.]{1}[0-9]{1,2})?$/);
        let mainUnit: any = mainUnitSeparated[0];
        if (mainUnitSeparated[3]) {
          mainUnit = `${mainUnit.substring(
            0,
            mainUnit.length - 3
          )}${mainUnitSeparated[3].replace(/[,]/g, '.')}`;
        }

        mainUnit = Number(mainUnit.replace(/[,]/g, ''));

        workSheet.getCell(columnKey + rowIndex).value =
          Number(workSheet.getCell(columnKey + rowIndex).value ?? 0) + mainUnit;
      } catch (error) {
        console.log('error :', error);
        console.log('Voucher number: ', doc.number);
        console.log('Faulty data: ', subsidyOptionMainUnit);
      }
    }
  }

  async downloadExcelFile(workbook: ExcelJS.Workbook, fileName: string) {
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(url);
  }
}
