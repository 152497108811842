import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { MailDataObj, Township, Voucher, VoucherGroup } from '../interfaces';
import { ManageVoucherComponent } from '../voucher-management/manage-voucher/manage-voucher.component';
import { MatDialog } from '@angular/material/dialog';
import { SendVoucherComponent } from '../voucher-management/send-voucher/send-voucher.component';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { VouchergroupsDialogComponent } from './dialogs/vouchergroups-dialog/vouchergroups-dialog.component';
import {
  deleteField,
  arrayUnion,
  doc,
  getDoc,
  setDoc,
  deleteDoc,
  collection,
  query,
  where,
  getCountFromServer,
} from 'firebase/firestore';

import { AngularFireAuth } from '@angular/fire/compat/auth';
import { EmailDataDialogComponent } from './dialogs/email-data-dialog/email-data-dialog.component';
import { lastValueFrom } from 'rxjs';
import { iframeUrl } from '../globals';
import { httpsCallable } from 'firebase/functions';
import { db, functions } from '../app.component';
import { VoucherStatus } from '../enums';

@Component({
  selector: 'app-helpdesk',
  templateUrl: './helpdesk.component.html',
  styleUrls: ['./helpdesk.component.scss'],
})
export class HelpdeskComponent implements OnInit {
  townshipId = localStorage.getItem('township') as string;
  searchForm: UntypedFormGroup;
  searching: boolean;
  showLoadingSpinner: boolean = false;
  vouchers: Voucher[] = [];
  displayedColumns = [
    'number',
    'email',
    'phone',
    'address',
    'voucherGroupName',
    'notes',
    'buttons',
  ];
  env = environment;
  vouchersError: any[] = [];
  hasSearched = false;
  email: string;
  activatingVoucher: boolean;
  dateNow = new Date();
  externalVoucherGroupIds = ['interSolve'];

  constructor(
    public fb: UntypedFormBuilder,
    private http: HttpClient,
    public dialog: MatDialog,
    private snackbar: MatSnackBar,
    private db: AngularFirestore,
    private auth: AngularFireAuth,
    private fns: AngularFireFunctions
  ) {}

  async ngOnInit(): Promise<any> {
    this.searchForm = this.fb.group({
      number: [''],
      postal: [''],
      houseNumber: [''],
      houseNumberAddition: [''],
      email: [''],
      phone: [''],
      notes: [''],
      name: [''],
      activatedOnly: true,
    });

    this.email = await (await this.auth.currentUser).email;
  }

  async openSendVoucherDialog() {
    this.dialog.open(SendVoucherComponent, {
      width: '450px',
      // data: { townshipId: this.userData.township },
    });
  }

  openEditVoucherDialog(type: string) {
    this.dialog.open(ManageVoucherComponent, {
      width: '450px',
      data: { type, isHelpdesk: true },
    });
  }

  async search() {
    this.vouchersError = [];
    this.hasSearched = true; // check if employee has searched at least 1 time
    if (this.searching) {
      return;
    }
    this.searching = true;
    this.showLoadingSpinner = true;
    if (this.searchForm.value.postal) {
      this.searchForm.controls.postal.patchValue(
        this.searchForm.value.postal.replace(/\s/g, '').toUpperCase()
      );
    }
    if (this.searchForm.value.number) {
      this.searchForm.controls.number.patchValue(
        this.searchForm.value.number.toUpperCase()
      );
    }

    const form = this.searchForm.value;
    if (
      form.number ||
      form.postal ||
      form.houseNumber ||
      form.houseNumberAddition ||
      form.email ||
      form.phone ||
      form.notes ||
      form.name
    ) {
      const callable = this.fns.httpsCallable('httpSearchVoucher');
      const result = await lastValueFrom(
        callable({
          townshipId: this.townshipId.replace(/\s/g, ''),
          number: form.number.replace(/\s/g, '').toUpperCase(),
          postal: form.postal.replace(/\s/g, '').toUpperCase(),
          houseNumber: form.houseNumber.replace(/\s/g, ''),
          houseNumberAddition: form.houseNumberAddition
            .replace(/\s/g, '')
            .toLowerCase(),
          email: form.email.replace(/\s/g, ''),
          phone: form.phone.replace(/\s/g, ''),
          notes: form.notes.replace(/\s/g, ''),
          name: form.name,
          activatedOnly: form.activatedOnly,
        })
      );

      if (result.status !== 'done') {
        if (result.status == 'too_many_results') {
          this.snackbar.open(
            'Er zijn te veel resultaten, verfijn je zoekopdracht.',
            'X',
            {
              duration: 5000,
            }
          );
        } else {
          this.snackbar.open('Er is een onbekende fout opgetreden.', 'X', {
            duration: 5000,
          });
        }
        this.searching = false;
        this.showLoadingSpinner = false;
      }

      if (result.status === 'error_searching') {
        this.searching = false;
        this.showLoadingSpinner = false;
        return this.snackbar.open(
          'Er is iets misgegaan met zoeken naar bonnen',
          'X',
          {
            duration: 50000,
          }
        );
      }
      this.vouchers = result.vouchers;
      if (result.addressesErrors) {
        for (const addressError of result.addressesErrors) {
          this.vouchersError.push(addressError);
        }
      }
      this.searching = false;
      this.showLoadingSpinner = false;
    } else {
      this.searching = false;
      this.showLoadingSpinner = false;
      this.snackbar.open('Vul 1 van de velden in', 'X', {
        duration: 5000,
      });
    }
  }

  editVoucher(voucher) {
    this.dialog
      .open(ManageVoucherComponent, {
        width: '450px',
        data: { type: 'edit', voucherNumber: voucher.number, isHelpdesk: true },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res && res.voucher) {
          if (
            res.voucher.validUntilDate &&
            new Date() <= res.voucher.validUntilDate
          ) {
            if (!voucher.claimDate && voucher.activateDate) {
              voucher.isValid = true;
            }
          }
          if (voucher.email != res.voucher.email) {
            voucher.email = res.voucher.email;
          }
          if (voucher.phone != res.voucher.phone) {
            voucher.phone = res.voucher.phone;
          }
          if (voucher.postal != res.voucher.postal) {
            voucher.postal = res.voucher.postal;
          }
          if (voucher.houseNumber != res.voucher.houseNumber) {
            voucher.houseNumber = res.voucher.houseNumber;
          }
          if (voucher.houseNumberAddition != res.voucher.houseNumberAddition) {
            voucher.houseNumberAddition = res.voucher.houseNumberAddition;
          }
          if (voucher.notes != res.voucher.notes) {
            voucher.notes = res.voucher.notes;
          }
        }
      });
  }

  async sendVoucher(voucher) {
    const voucherGroup = (
      await getDoc(
        doc(
          this.db.firestore,
          `township/${this.townshipId}/voucherGroups/${voucher.voucherGroupId}`
        )
      )
    ).data() as VoucherGroup;

    // claim voucher where vouchergroup is set to claim
    // for fysical coupons is this neccesary
    if (
      voucherGroup.type === 'claim' &&
      !voucher.acitvateDate &&
      !voucher.claimDate
    ) {
      await setDoc(
        doc(
          this.db.firestore,
          `township/${this.townshipId}/vouchers/${voucher.number}`
        ),
        {
          activateDate: new Date(),
        },
        { merge: true }
      );
    }
    this.dialog.open(SendVoucherComponent, {
      width: '373px',
      data: { code: voucher.number, email: voucher.email },
    });
  }

  requestVoucher() {
    const formValue = this.searchForm.value as Voucher;
    if (
      formValue.email &&
      formValue.name &&
      formValue.postal &&
      formValue.houseNumber &&
      this.hasSearched
    ) {
      const dialogRef = this.dialog.open(VouchergroupsDialogComponent, {
        width: '375px',
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        if (!result) {
          return;
        }
        const voucherGroupId = result.voucherGroup.id;
        if (result.voucherGroup.applicantMustCompleteRequest) {
          const township = (
            await getDoc(doc(this.db.firestore, `township/${this.townshipId}`))
          ).data() as Township;
          let addressParam = `${formValue.postal}*${formValue.houseNumber}`;
          if (formValue.houseNumberAddition)
            addressParam = `${addressParam}*${formValue.houseNumberAddition}`;
          let activateVoucherUrl = `${await iframeUrl(
            township.id,
            this.db.firestore
          )}/activate/${
            township.uniqueName
          }/${voucherGroupId}/${addressParam}/email=${formValue.email}`;

          if (formValue.phone) {
            activateVoucherUrl = `${activateVoucherUrl}&phone=${formValue.phone}`;
          }
          if (formValue.name) {
            activateVoucherUrl = `${activateVoucherUrl}&name=${formValue.name}`;
          }

          const dataObj: MailDataObj = {
            orgData: {
              township: township,
              voucherGroup: result.voucherGroup,
            },
            mailData: {
              mailType: 'preFilledActivation',
              downloadUrl: activateVoucherUrl,
            },
            userData: { email: [formValue.email], name: formValue.name },
          };
          try {
            const sendMailRes = (await lastValueFrom(
              this.http.post(`${environment.functionsUrl}/httpSendMail`, {
                data: dataObj,
              })
            )) as any;
            if (sendMailRes.status != 'success') {
              throw Error(sendMailRes.status);
            }
            this.snackbar.open(
              'Het aanvraagformulier is successvol verstuurd.',
              'X',
              {
                duration: 5000,
              }
            );
          } catch (e) {
            console.error(e);
            this.snackbar.open(
              'Er is iets misgegaan met verzenden van de bon',
              'X',
              {
                duration: 5000,
              }
            );
          }
        } else {
          this.activatingVoucher = true;
          this.showLoadingSpinner = true;
          const voucherGroupRef = this.db.firestore.doc(
            `township/${this.townshipId}/voucherGroups/${voucherGroupId}`
          );
          const vouchersRef = this.db.collection(
            `township/${this.townshipId}/vouchers`,
            (ref) =>
              ref
                .where('activateDate', '==', null)
                .where('voucherGroupId', '==', voucherGroupId)
                .where('distributed', '==', null)
                .limit(10)
          );

          const unactivatedVouchers = await lastValueFrom(
            vouchersRef.get()
          ).then((colSnap) => {
            const vouchers: Voucher[] = [];
            colSnap.forEach((doc) => {
              const data: any = doc.data() as Voucher;
              data.ref = doc.ref;
              vouchers.push(data as Voucher);
            });
            return vouchers;
          });

          //start with first voucher try to activate, if error, try next one.
          let foundVoucherWithoutPostalCode = false;
          let activatedVoucherCode = null;

          for (const voucher of unactivatedVouchers) {
            const postalCode = voucher.postal;

            if (postalCode) {
              continue;
            }
            foundVoucherWithoutPostalCode = true;

            try {
              const transactionResult = await this.db.firestore.runTransaction(
                async (t) => {
                  const doc = await t.get(voucher.ref as any);
                  if (!doc.exists) {
                    return Promise.reject('doesnt_exist');
                  }
                  const voucherData: any = doc.data();
                  if (voucherData) {
                    if (
                      !voucherData.activateDate ||
                      voucherData.activateDate === null
                    ) {
                      if (voucherData.activateFromType) {
                        return Promise.reject('already_activated');
                      }
                      const voucherGroupDoc = await t.get(voucherGroupRef);
                      const voucherGroup =
                        voucherGroupDoc.data() as VoucherGroup;

                      const maxVouchers = voucherGroup.maxVouchers;
                      const totalVouchersRef = query(
                        collection(db, `township/${this.townshipId}/vouchers`),
                        where('voucherGroupId', '==', voucherGroupId),
                        where('partial', '==', false),
                        where('status', '!=', VoucherStatus.expired)
                      );
                      const totalAvailableVouchersRef = query(
                        collection(db, `township/${this.townshipId}/vouchers`),
                        where('voucherGroupId', '==', voucherGroupId),
                        where('status', '==', VoucherStatus.available)
                      );

                      if (maxVouchers) {
                        const totalVouchers = (
                          await getCountFromServer(totalVouchersRef)
                        ).data().count;
                        const totalAvailableVouchers = (
                          await getCountFromServer(totalAvailableVouchersRef)
                        ).data().count;
                        const totalActivatedVouchers =
                          totalVouchers - totalAvailableVouchers;

                        console.log('maxVouchers:', maxVouchers);
                        console.log(
                          'totalActivatedVouchers:',
                          totalActivatedVouchers
                        );
                        if (totalActivatedVouchers >= maxVouchers) {
                          return Promise.reject('no_vouchers_left');
                        }
                      }
                      let activateDate;
                      if (voucherGroup.activateFromType) {
                        if (
                          voucherGroup.activateFromType === 'activateOnDate'
                        ) {
                          if (voucherGroup.usableFrom) {
                            activateDate = new Date(
                              voucherGroup.usableFrom.toDate()
                            );
                          }
                        } else if (
                          voucherGroup.activateFromType === 'activateOnRequest'
                        ) {
                          activateDate = new Date();
                        } else {
                          activateDate = null;
                        }
                      } else {
                        activateDate = new Date();
                      }
                      t.update(voucher.ref as any, {
                        distributed: true,
                        activateFromType: voucherGroup.activateFromType
                          ? true
                          : false,
                        activateDate: activateDate,
                        email: formValue.email,
                        postal: formValue.postal.replace(' ', ''),
                        houseNumber: formValue.houseNumber,
                        houseNumberAddition: formValue.houseNumberAddition
                          ? formValue.houseNumberAddition
                          : deleteField(),
                        name: formValue.name,
                        phone: formValue.phone
                          ? formValue.phone
                          : deleteField(),
                        groupLink: voucherGroup.groupLink
                          ? voucherGroup.groupLink
                          : deleteField(),
                        activatedBy: {
                          email: this.email,
                          date: new Date(),
                        },
                      });
                      voucherData.activateDate = activateDate;
                      return Promise.resolve(voucherData.number);
                    } else {
                      return Promise.reject('already_activated');
                    }
                  } else {
                    return Promise.reject('doesnt_exist');
                  }
                }
              );
              activatedVoucherCode = transactionResult;
              break;
            } catch (err) {
              if (err === 'no_vouchers_left') {
                break;
              }
            }
          }

          if (activatedVoucherCode) {
            try {
              const callable = httpsCallable(functions, 'httpSendVoucherMail');
              await callable({
                email: formValue.email,
                code: activatedVoucherCode,
                townshipId: this.townshipId,
              }).then((response) => {
                if (response.data['status'] == 'success') {
                  this.snackbar.open(`Email is verzonden`, 'X', {
                    duration: 5000,
                  });
                  this.activatingVoucher = false;
                  this.showLoadingSpinner = false;
                } else {
                  this.snackbar.open(
                    'Er is iets misgegaan met verzenden van de bon',
                    'X',
                    {
                      duration: 5000,
                    }
                  );
                  this.activatingVoucher = false;
                  this.showLoadingSpinner = false;
                }
              });
            } catch (e) {
              console.error(e);
              this.activatingVoucher = false;
              this.showLoadingSpinner = false;
              this.snackbar.open(
                'Er is iets misgegaan met verzenden van de bon',
                'X',
                {
                  duration: 5000,
                }
              );
            }
          } else if (!foundVoucherWithoutPostalCode) {
            this.snackbar.open(
              'Er zijn geen bonnen meer beschikbaar voor deze campagne',
              'X',
              {
                duration: 5000,
              }
            );
          }
        }

        this.activatingVoucher = false;
        this.showLoadingSpinner = false;
      });
      // call allVoucherGroup dialog and set coupon
    } else if (!this.hasSearched) {
      this.snackbar.open('Probeer eerst te zoeken', 'X', {
        duration: 5000,
      });
      this.activatingVoucher = false;
      this.showLoadingSpinner = false;
    } else {
      this.snackbar.open(
        'Vul de volgende velden in om een bon te verzenden: postcode, huisnummer, toevoeging (indien van toepassing), naam, e-mail',
        'X',
        {
          duration: 7500,
        }
      );
      this.activatingVoucher = false;
      this.showLoadingSpinner = false;
    }
  }

  async deleteBlacklistAddress(voucherNumber: string, index: number) {
    const voucher = (
      await getDoc(
        doc(
          this.db.firestore,
          `township/${this.townshipId}/vouchers/${voucherNumber}`
        )
      )
    ).data() as Voucher;
    let docIdToDelete = `${voucher.postal.replace(' ', '')}${
      voucher.houseNumber
    }`;
    if (voucher.houseNumberAddition) {
      docIdToDelete += voucher.houseNumberAddition;
    }
    await deleteDoc(
      doc(
        this.db.firestore,
        `township/${this.townshipId}/addressExceptions/${docIdToDelete}`
      )
    );
    this.snackbar.open('Uitzondering verwijderd', 'X', {
      duration: 5000,
    });
    this.vouchersError.splice(index, 1);
  }

  async addAddress(voucherNumber: string, index: number) {
    const form = this.searchForm.value;
    const saveObj = {
      houseNumber: '',
      houseNumberAddition: '',
      postal: '',
      type: form.notes ? arrayUnion(form.notes) : deleteField(),
      addedBy: {
        email: this.email,
        date: new Date(),
      },
    };
    let postalToAdd;
    // address can be added
    if (voucherNumber && !form.postal && !form.houseNumber) {
      const voucher = (
        await getDoc(
          doc(
            this.db.firestore,
            `township/${this.townshipId}/vouchers/${voucherNumber}`
          )
        )
      ).data() as Voucher;
      postalToAdd = `${voucher.postal.replace(' ', '')}${voucher.houseNumber}`;
      if (voucher.houseNumberAddition) {
        postalToAdd += voucher.houseNumberAddition.toLowerCase();
      }
      saveObj.houseNumber = voucher.houseNumber.toString();

      saveObj.postal = voucher.postal.toUpperCase();
      saveObj.houseNumberAddition = voucher.houseNumberAddition
        ? voucher.houseNumberAddition.toLowerCase()
        : (deleteField() as any);
    } else {
      // if user has filled in postal and housenumber postal / housenumber should be overwritten
      if (form.postal && form.houseNumber) {
        postalToAdd = `${form.postal.replace(' ', '')}${form.houseNumber}`;
        if (form.houseNumberAddition) {
          postalToAdd += form.houseNumberAddition.toLowerCase();
        }
        saveObj.houseNumber = form.houseNumber.toString();
        saveObj.postal = form.postal.toUpperCase();
        saveObj.houseNumberAddition = form.houseNumberAddition
          ? form.houseNumberAddition.toLowerCase()
          : deleteField();
      }
    }

    if (!saveObj.postal || !saveObj.houseNumber) {
      this.snackbar.open('Er is geen postcode / huisnummer bekend', 'X', {
        duration: 5000,
      });
    }
    saveObj.postal = saveObj.postal.replace(' ', '');
    await setDoc(
      doc(
        this.db.firestore,
        `township/${this.townshipId}/addresses/${postalToAdd}`
      ),
      saveObj,
      { merge: true }
    );

    if (form.notes) {
      await setDoc(
        doc(
          this.db.firestore,
          `township/${this.townshipId}/addresTypes/${form.notes}`
        ),
        { type: form.notes },
        { merge: true }
      );
    }

    this.snackbar.open('Addressenlijst bijgewerkt', 'X', {
      duration: 5000,
    });
    this.vouchersError.splice(index, 1);
  }

  async blockVoucher(voucher: Voucher) {
    await setDoc(
      doc(
        this.db.firestore,
        `township/${this.townshipId}/vouchers/${voucher.number}`
      ),
      {
        status:
          voucher.value !== voucher.originalValue
            ? VoucherStatus.paid
            : VoucherStatus.expired,
        claimDate: new Date(),
        paidDate: new Date(),
        groupLink: deleteField(),
        originalGroupLink: voucher.groupLink ?? deleteField(),
        validUntilDate: new Date(),
      },
      { merge: true }
    );

    voucher.isValid = false;
    this.snackbar.open('Bon is geblokkeerd', 'X', {
      duration: 5000,
    });
  }

  async unblockIntersolveVoucher(voucher: Voucher) {
    try {
      this.showSnackbar('We gaan de bon deblokkeren, even geduld aub');
      const result = (await lastValueFrom(
        this.http.post(
          `${this.env.functionsUrl}/intersolveUnblockIntersolveCard`,
          {
            externalVoucherGroupId: this.externalVoucherGroupIds[0],
            voucherNumber: voucher.number,
            unblockReason: 'default',
          }
        )
      )) as any;
      // const result = (await this.http
      //   .post(`${this.env.functionsUrl}/intersolveUnblockIntersolveCard`, {
      //     externalVoucherGroupId: this.externalVoucherGroupIds[0],
      //     voucherNumber: voucher.number,
      //     unblockReason: 'default',
      //   })
      //   .toPromise()) as any;
      if (result.message === 'succeed') {
        voucher.status = VoucherStatus.activated;
        this.showSnackbar(
          `Bonnummer ${voucher.number} is vrijgegeven voor Intersolve`
        );
      } else {
        this.showSnackbar(
          `Er is iets missgegaan. Probeer het later opnieuw of neem contact op`
        );
      }
    } catch (e) {
      this.showSnackbar(
        'Er is iets missgegaan. Probeer het later opnieuw of neem contact op'
      );
      console.error(e);
    }
  }

  showSnackbar(txt: string) {
    this.snackbar.open(txt, 'X', {
      duration: 5000,
    });
  }

  resetForm() {
    const activatedOnly = this.searchForm.controls.activatedOnly.value;
    this.searchForm.reset({
      number: '',
      postal: '',
      houseNumber: '',
      houseNumberAddition: '',
      email: '',
      phone: '',
      notes: '',
      name: '',
      activatedOnly: activatedOnly,
    });
    this.hasSearched = false;
  }

  openEmailDataDialog(voucher: Voucher) {
    const dialogRef = this.dialog.open(EmailDataDialogComponent, {
      width: '575px',
      data: {
        voucher,
      },
      autoFocus: false,
    });
  }
}
