// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-wrapper {
  width: 85%;
  margin: 0 auto;
}
.content-wrapper .filter-form {
  margin: 0;
  display: flex;
  justify-content: space-between;
}
.content-wrapper .filter-form .search {
  width: 100%;
  max-height: 55px;
}
.content-wrapper .filter-form .filters mat-chip-option {
  border-radius: 10px;
}
.content-wrapper .inbox-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 25px 0 0 0;
  height: calc(100vh - 9.5rem - 196px);
}
.content-wrapper .inbox-list .inbox-card {
  padding: 10px;
  border-left: 4px solid #296a48;
  background-color: #adf2c6;
  border-radius: 8px;
  margin: 10px 0;
  box-shadow: none;
}
.content-wrapper .inbox-list .inbox-card .title {
  margin-bottom: 16px;
}
.content-wrapper .inbox-list .inbox-card.result:hover {
  background-color: #d4f7e0;
  cursor: pointer;
}
.content-wrapper .inbox-list .inbox-card.read {
  border-color: #70787c;
  background-color: rgba(27, 28, 21, 0.12);
  cursor: default;
}

@media only screen and (max-width: 659px) and (min-width: 0px) {
  .content-wrapper {
    width: 100%;
  }
  .content-wrapper .filter-form {
    margin: 0;
  }
  .inbox-subtitle {
    font-size: 14px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/inbox/inbox.component.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,cAAA;AACF;AAAE;EACE,SAAA;EACA,aAAA;EACA,8BAAA;AAEJ;AADI;EACE,WAAA;EACA,gBAAA;AAGN;AAAM;EACE,mBAAA;AAER;AAEE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,kBAAA;EACA,oCAAA;AAAJ;AACI;EACE,aAAA;EACA,8BAAA;EACA,yBAAA;EACA,kBAAA;EACA,cAAA;EACA,gBAAA;AACN;AAAM;EACE,mBAAA;AAER;AACI;EACE,yBAAA;EACA,eAAA;AACN;AACI;EACE,qBAAA;EACA,wCAAA;EACA,eAAA;AACN;;AAIA;EACE;IACE,WAAA;EADF;EAEE;IACE,SAAA;EAAJ;EAGA;IACE,eAAA;EADF;AACF","sourcesContent":[".content-wrapper {\n  width: 85%;\n  margin: 0 auto;\n  .filter-form {\n    margin: 0;\n    display: flex;\n    justify-content: space-between;\n    .search {\n      width: 100%;\n      max-height: 55px;\n    }\n    .filters {\n      mat-chip-option {\n        border-radius: 10px;\n      }\n    }\n  }\n  .inbox-list {\n    display: flex;\n    flex-direction: column;\n    gap: 15px;\n    margin: 25px 0 0 0;\n    height: calc(100vh - 9.5rem - 196px);\n    .inbox-card {\n      padding: 10px;\n      border-left: 4px solid #296a48;\n      background-color: #adf2c6;\n      border-radius: 8px;\n      margin: 10px 0;\n      box-shadow: none;\n      .title {\n        margin-bottom: 16px;\n      }\n    }\n    .inbox-card.result:hover {\n      background-color: #d4f7e0;\n      cursor: pointer;\n    }\n    .inbox-card.read {\n      border-color: #70787c;\n      background-color: rgba(27, 28, 21, 0.12);\n      cursor: default;\n    }\n  }\n}\n\n@media only screen and (max-width: 659px) and (min-width: 0px) {\n  .content-wrapper {\n    width: 100%;\n    .filter-form {\n      margin: 0;\n    }\n  }\n  .inbox-subtitle {\n    font-size: 14px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
