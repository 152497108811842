import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  setDoc,
  doc,
  deleteField,
  addDoc,
  collection,
  query,
  where,
  updateDoc,
  getDocs,
} from 'firebase/firestore';
import { db } from 'src/app/app.component';
import { GroupLink } from 'src/app/interfaces';

export interface DialogData {
  groupLink: GroupLink;
  townshipId?: string;
}

@Component({
  selector: 'app-edit-group-link-dialog',
  templateUrl: './edit-group-link-dialog.component.html',
  styleUrls: ['./edit-group-link-dialog.component.scss'],
})
export class EditGroupLinkDialogComponent implements OnInit {
  newGroupLink: boolean = true;
  waitingForResponse: boolean = false;
  groupLinkForm: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<EditGroupLinkDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    public db: AngularFirestore,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit() {
    this.groupLinkForm = this.formBuilder.group({
      name: [, Validators.required],
      text: [],
      order: [],
    });
    if (this.data.groupLink) {
      this.groupLinkForm.patchValue(this.data.groupLink);
      this.newGroupLink = false;
    }
  }

  async save() {
    this.waitingForResponse = true;
    let groupLinkObj;
    const form = this.groupLinkForm.value;
    try {
      if (this.data.groupLink) {
        await setDoc(
          doc(
            db,
            `township/${this.data.townshipId}/groupLinks/${this.data.groupLink.id}`
          ),
          {
            name: form.name,
            text: form.text ? form.text : deleteField(),
            order: form.order ? form.order : deleteField(),
          },
          { merge: true }
        );
        groupLinkObj = {
          id: this.data.groupLink.id,
          name: form.name,
          text: form.text,
          order: form.order,
        };
        if (this.data.groupLink.name !== form.name) {
          const q = query(
            collection(db, `township/${this.data.townshipId}/voucherGroups`),
            where('groupLink', '==', this.data.groupLink.name)
          );
          const allGroupLinks = await getDocs(q);
          allGroupLinks.forEach(async (groupLink) => {
            await updateDoc(
              doc(
                db,
                `township/${this.data.townshipId}/voucherGroups`,
                `${groupLink.id}`
              ),
              {
                groupLink: form.name,
              }
            );
          });
        }
      } else {
        await addDoc(
          collection(db, `township/${this.data.townshipId}/groupLinks/`),
          {
            name: form.name,
            text: form.text,
            order: form.order,
          }
        );
        groupLinkObj = {
          name: form.name,
          text: form.text,
          order: form.order,
        };
      }

      this.dialogRef.close(groupLinkObj);
      this.waitingForResponse = false;
    } catch (error) {
      this._snackBar.open(
        'Er is iets fout gegaan, probeer later opnieuw.',
        'X',
        {
          duration: 5000,
        }
      );
      this.waitingForResponse = false;
    }
  }
}
