import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { dataStore, db } from '../app.component';
import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
} from 'firebase/firestore';
import { InboxMessage } from '../interfaces';

@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.scss'],
})
export class InboxComponent implements OnInit {
  loaded: boolean = false;
  filterForm = this.fb.group({
    filters: new FormControl(['unread', 'read']),
  });
  filters: any[] = [
    { val: 'unread', name: 'Ongelezen', counter: 0 },
    { val: 'read', name: 'Gelezen', counter: 0 },
  ];
  inbox: InboxMessage[] = [];
  filteredInbox: InboxMessage[] = [];
  markingAllAsRead: boolean = false;

  constructor(private fb: FormBuilder, public dialog: MatDialog) {}

  async ngOnInit() {
    await this.getInbox();
    this.loaded = true;
  }

  async getInbox() {
    const inboxQuery = query(
      collection(db, `users/${dataStore.rootUser.id}/inbox`),
      orderBy('date', 'desc')
    );
    onSnapshot(inboxQuery, (querySnapshot) => {
      const inbox = [];
      let readCounter = 0;
      let unreadCounter = 0;
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const inboxMessage = {
          id: doc.id,
          ...data,
          date: data.date.toDate(),
        } as InboxMessage;
        inbox.push(inboxMessage);
        if (inboxMessage.read) {
          readCounter++;
        } else {
          unreadCounter++;
        }
      });
      this.inbox = inbox;
      this.filters[0].counter = unreadCounter; // Unread filter
      this.filters[1].counter = readCounter; // Read filter
      this.filterInbox();
    });
  }

  async markAllAsRead(filteredInbox: InboxMessage[]) {
    if (this.markingAllAsRead) {
      return;
    }
    this.markingAllAsRead = true;
    for (const inboxMessage of filteredInbox) {
      if (!inboxMessage.read) {
        await this.markAsRead(inboxMessage);
      }
    }
    this.markingAllAsRead = false;
  }

  async markAsRead(inboxMessage: InboxMessage) {
    if (inboxMessage.read) {
      return;
    }
    const inboxRef = doc(
      db,
      `users/${dataStore.rootUser.id}/inbox/${inboxMessage.id}`
    );
    await updateDoc(inboxRef, {
      read: true,
    });
  }

  filterInbox() {
    this.filteredInbox = this.inbox.filter((inboxMessage) => {
      const filters = Object.assign([], this.filterForm.value.filters);
      if (filters.indexOf('read') != -1 && inboxMessage.read) {
        return true;
      }
      if (filters.indexOf('unread') != -1 && !inboxMessage.read) {
        return true;
      }
      return false;
    });
  }
}
