import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Font } from 'src/app/interfaces';

export interface DialogData {
  font: Font;
}

@Component({
  selector: 'app-delete-font',
  templateUrl: './delete-font.component.html',
  styleUrls: ['./delete-font.component.scss'],
})
export class DeleteFontComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit(): void {}
}
