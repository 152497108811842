import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import { environment } from '../environments/environment';
import { registerLocaleData, CurrencyPipe } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
registerLocaleData(localeNl);

import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatMenuModule } from '@angular/material/menu';
import { MatChipsModule } from '@angular/material/chips';
import {
  MatFormFieldModule,
  MatFormFieldDefaultOptions,
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
} from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatBadgeModule } from '@angular/material/badge';
import { MatExpansionModule } from '@angular/material/expansion';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { LoginComponent } from './auth/login/login.component';
import { RegistrationComponent } from './auth/registration/registration.component';
import { FirstTimeComponent } from './auth/first-time/first-time.component';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import {
  AngularFireAuthModule,
  LANGUAGE_CODE,
} from '@angular/fire/compat/auth';
import { AngularFireAuthGuardModule } from '@angular/fire/compat/auth-guard';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ColorPickerModule } from 'ngx-color-picker';
import { SwiperModule } from 'swiper/angular';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import {
  NgxMatNativeDateModule,
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CustomDateAdapter } from './custom-date-adapter';
import {
  CalendarModule,
  DateAdapter as CalendarDateAdapter,
  CalendarDateFormatter,
  CalendarNativeDateFormatter,
} from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { DashboardTownshipComponent } from './dashboard-township/dashboard-township.component';
import { VoucherManagementComponent } from './voucher-management/voucher-management.component';
import { OrganisationManagementComponent } from './organisation-management/organisation-management.component';
import { VoucherGroupDetailComponent } from './voucher-management/voucher-group-detail/voucher-group-detail.component';
import { VoucherGroupEditComponent } from './voucher-management/voucher-group-edit/voucher-group-edit.component';
import { EditOrganisationComponent } from './organisation-management/edit-organisation/edit-organisation.component';
import { MarkAsPaidComponent } from './organisation-management/mark-as-paid/mark-as-paid.component';
import { SettingsComponent } from './settings/settings.component';
import { SendVoucherComponent } from './voucher-management/send-voucher/send-voucher.component';
import { ManageVoucherComponent } from './voucher-management/manage-voucher/manage-voucher.component';
import { CheckTownshipVouchersComponent } from './check-township-vouchers/check-township-vouchers.component';
import { CheckVouchersComponent } from './organisation-management/check-vouchers/check-vouchers.component';
import { CheckVoucherComponent } from './organisation-management/check-vouchers/check-voucher/check-voucher.component';
import { SubmitInvoiceComponent } from './organisation-management/check-vouchers/submit-invoice/submit-invoice.component';
import { InputPaymentreferenceComponent } from './organisation-management/input-paymentreference/input-paymentreference.component';
import { TagsComponent } from './tags/tags.component';
import { EditTagComponent } from './tags/edit-tag/edit-tag.component';
import { DeleteTagComponent } from './tags/delete-tag/delete-tag.component';
import { UpgradeDialogComponent } from './nav-bar/dialogs/upgrade-dialog/upgrade-dialog.component';
import { ContinueComponent } from './settings/dialog/continue-dialog/continue.component';
import { ConfirmationComponent } from './voucher-management/dialogs/confirmation-code/confirmation-code.component';
import { GenerateVouchersComponent } from './voucher-management/dialogs/generate-vouchers/generate-vouchers.component';
import { DiscountComponent } from './organisation-management/dialog/discount/discount.component';
import { OrdersComponent } from './orders/orders.component';
import { OrderComponent } from './orders/dialogs/order/order.component';
import { ForceApiSyncDialogComponent } from './settings/dialog/force-api-sync-dialog/force-api-sync-dialog.component';
import { WebcamModule } from 'ngx-webcam';
import { ReceiptComponent } from './voucher-management/dialogs/receipt/receipt.component';
import { SetupPaymentDialogComponent } from './settings/dialog/setup-payment-dialog/setup-payment-dialog.component';
import { MatStepperModule } from '@angular/material/stepper';
import { SepaComponent } from './payment-references/dialog/sepa/sepa.component';
import { SepaConfirmationComponent } from './payment-references/dialog/sepa-confirmation/sepa-confirmation.component';
import { FileSaverModule } from 'ngx-filesaver';
import { PaymentReferencesComponent } from './payment-references/payment-references.component';
import { ActionListComponent } from './voucher-management/dialogs/action-list/action-list.component';
import { ChangeTownshipDialogComponent } from './dashboard-township/dialog/change-township-dialog/change-township-dialog.component';
import { ConfirmationDialogComponent } from './settings/dialog/confirmation-dialog/confirmation-dialog.component';
import { FontsComponent } from './fonts/fonts.component';
import { EditFontComponent } from './fonts/dialog/edit-font/edit-font.component';
import { DeleteFontComponent } from './fonts/dialog/delete-font/delete-font.component';
import { SelectPdfFontsComponent } from './settings/dialog/select-pdf-fonts/select-pdf-fonts.component';
import { GroupLinkDialogComponent } from './settings/dialog/group-link-dialog/group-link-dialog.component';
import { ExternalVoucherManagementComponent } from './external-voucher-management/external-voucher-management.component';
import { ManageExternalVoucherGroupComponent } from './external-voucher-management/dialogs/manage-external-voucher-group/manage-external-voucher-group.component';
import { EditSepaComponent } from './organisation-management/edit-organisation/edit-sepa/edit-sepa.component';
import { HelpdeskComponent } from './helpdesk/helpdesk.component';
import { UsersComponent } from './users/users.component';
import { ManageUserComponent } from './users/dialogs/manage-user/manage-user.component';
import { DeleteUserComponent } from './users/dialogs/delete-user/delete-user.component';
import { VouchergroupsDialogComponent } from './helpdesk/dialogs/vouchergroups-dialog/vouchergroups-dialog.component';
import { GeneralSettingsComponent } from './general-settings/general-settings.component';
import { ChangeHiddenStatusComponent } from './voucher-management/dialogs/change-hidden-status/change-hidden-status.component';
import { ImportExportComponent } from './settings/dialog/import-export-dialog/import-export.component';
import { HideOrganisation } from './organisation-management/dialog/hide-organisation/hide-organisation.component';
import { FinishVouchergroupComponent } from './voucher-management/dialogs/finish-vouchergroup/finish-vouchergroup.component';
import { EmailDataDialogComponent } from './helpdesk/dialogs/email-data-dialog/email-data-dialog.component';
import { ConfirmDeleteDomainsComponent } from './general-settings/dialog/confirm-delete-domains/confirm-delete-domains.component';
import { CharacteristicsComponent } from './characteristics/characteristics.component';
import { EditCharacteristicsDialogComponent } from './characteristics/dialog/edit-characteristics-dialog/edit-characteristics-dialog.component';
import { GroupLinkComponent } from './group-links/group-links.component';
import { EditGroupLinkDialogComponent } from './group-links/dialog/edit-group-link-dialog/edit-group-link-dialog.component';
import { AddressTypesDialogComponent } from './settings/dialog/address-types-dialog/address-types-dialog.component';
import { AssignmentsComponent } from './assignments/assignments.component';
import { ManageAssignmentComponent } from './assignments/dialogs/manage-assignment/manage-assignment.component';
import { REGION } from '@angular/fire/compat/functions';
import { ThemedVoucherGroupEditComponent } from './voucher-management/themed-voucher-group-edit/themed-voucher-group-edit.component';
import { ThemesComponent } from './themes/themes.component';
import { EditThemesDialogComponent } from './themes/dialogs/edit-themes-dialog/edit-themes-dialog.component';
import { ShareThemedVoucherGroupComponent } from './voucher-management/share-themed-voucher-group/share-themed-voucher-group.component';
import { DeleteGroupLinkComponent } from './group-links/dialog/delete-group-link/delete-group-link.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageCropperComponent } from './themes/dialogs/image-cropper/image-cropper.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { StatisticsManagementComponent } from './statistics/statistics-management/statistics-management.component';
import { WidgetManageComponent } from './statistics/statistics-management/widget-manage/widget-manage.component';
import { WidgetDeleteComponent } from './statistics/statistics-management/widget-delete/widget-delete.component';
import { WidgetDailyComponent } from './statistics/widgets/widget-daily/widget-daily.component';
import { WidgetActionRunningTimeComponent } from './statistics/widgets/widget-action-running-time/widget-action-running-time.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { VouchersClaimedComponent } from './statistics/widgets/vouchers-claimed/vouchers-claimed.component';
import { VouchersAvailableComponent } from './statistics/widgets/vouchers-available/vouchers-available.component';
import { VouchersClaimedPerOrgComponent } from './statistics/widgets/vouchers-claimed-per-org/vouchers-claimed-per-org.component';
import { WidgetTotalClaimedValueComponent } from './statistics/widgets/widget-total-claimed-value/widget-total-claimed-value.component';
import { WidgetHomesPreservedComponent } from './statistics/widgets/widget-homes-preserved/widget-homes-preserved.component';
import { WidgetTransactionsComponent } from './statistics/widgets/widget-transactions/widget-transactions.component';
import { WidgetVouchersSoldPerOrgComponent } from './statistics/widgets/widget-vouchers-sold-per-org/widget-vouchers-sold-per-org.component';
import { VouchersLineChartComponent } from './statistics/widgets/vouchers-line-chart/vouchers-line-chart.component';
import { WidgetTotalWorthSoldVouchersComponent } from './statistics/widgets/widget-total-worth-sold-vouchers/widget-total-worth-sold-vouchers.component';
import { SingleWidgetComponent } from './statistics/single-widget/single-widget.component';
import { ChangePasswordComponent } from './general-settings/dialog/change-password/change-password.component';
import { EditTownshipFileComponent } from './general-settings/dialog/edit-township-file/edit-township-file.component';
import { DeleteTownshipFileComponent } from './general-settings/dialog/delete-township-file/delete-township-file.component';
import { CreateInvoiceComponent } from './general-settings/dialog/create-invoice/create-invoice.component';
import { CalculateMonthlyCostComponent } from './general-settings/dialog/calculate-monthly-cost/calculate-monthly-cost.component';
import { WarningDialogComponent } from './general-settings/dialog/warning-dialog/warning-dialog.component';
import { BurdenOfProofDialogComponent } from './check-township-vouchers/dialogs/burden-of-proof-dialog/burden-of-proof-dialog.component';
import { FillInBopFormDialogComponent } from './voucher-management/manage-voucher/dialogs/fill-in-bop-form-dialog/fill-in-bop-form-dialog.component';
import { ExportReportDialogComponent } from './settings/dialog/export-report-dialog/export-report-dialog-component';
import { VouchersExportTypeComponent } from './voucher-management/dialogs/vouchers-export-type/vouchers-export-type.component';
import { AddUserToTownshipComponent } from './general-settings/dialog/add-user-to-township/add-user-to-township.component';
import { AccountComponent } from './account/account.component';
import { MultiFactorComponent } from './account/multi-factor/multi-factor.component';
import { DeleteMfaConfirmationComponent } from './account/multi-factor/dialogs/delete-mfa-confirmation/delete-mfa-confirmation.component';
import { ConfirmDeleteDialogComponent } from './characteristics/dialog/confirm-delete-dialog/confirm-delete-dialog.component';
import { EditStaffelFileComponent } from './general-settings/dialog/edit-staffel-file/edit-staffel-file.component';
import { MassMailComponent } from './general-settings/dialog/mass-mail/mass-mail.component';
import { PlanningComponent } from './planning/planning.component';
import { DayPartsComponent } from './day-parts/day-parts.component';
import { AppointmentTypesComponent } from './appointment-types/appointment-types.component';
import { PlanningSettingsComponent } from './planning-settings/planning-settings.component';
import { DeleteAppointmentTypeDialogComponent } from './appointment-types/dialogs/delete-appointment-type-dialog/delete-appointment-type-dialog.component';
import { ManageAppointmentTypesComponent } from './appointment-types/manage-appointment-types/manage-appointment-types.component';
import { ManageAppointmentComponent } from './planning/dialogs/manage-appointment/manage-appointment.component';
import { AppointmentDetailsComponent } from './planning/dialogs/appointment-details/appointment-details.component';
import { DayPartDeleteDialogComponent } from './day-parts/dialog/day-part-delete-dialog.component';
import { DayPartEditComponent } from './day-parts/pages/day-part-edit/day-part-edit.component';
import { ManageTeamUserComponent } from './users/pages/manage-team-user/manage-team-user.component';
import { AppointmentInfoCardComponent } from './planning/components/appointment-info-card/appointment-info-card.component';
import { AppointmentDeleteComponent } from './planning/dialogs/appointment-delete/appointment-delete.component';
import { ParticularityDeleteDialogComponent } from './planning-settings/dialog/delete-particularity-dialog/particularity-delete-dialog.component';
import { ManageParticularityComponent } from './planning-settings/pages/manage-particularity/manage-particularity.component';
import { ExceptionEditComponent } from './day-parts/pages/exception-edit/exception-edit.component';
import { ExportAppointmentsComponent } from './planning/dialogs/export-appointments/export-appointments.component';
import { PlanningTeamComponent } from './planning-team/planning-team.component';
import { TeamAppointmentDetailsComponent } from './planning-team/dialogs/team-appointment-details/team-appointment-details.component';
import { TeamAppointmentInfoCardComponent } from './planning-team/components/team-appointment-info-card/team-appointment-info-card.component';
import { ManageReportComponent } from './planning-team/dialogs/manage-report/manage-report.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { ManageScheduleExceptionComponent } from './schedule/pages/manage-schedule-exception/manage-schedule-exception.component';
import { TeamuserExceptionDeleteDialogComponent } from './schedule/dialog/teamuser-exception-delete-dialog/teamuser-exception-delete-dialog.component';
import { CalculateCostCenterComponent } from './general-settings/dialog/calculate-cost-center/calculate-cost-center.component';
import { ReportSentComponent } from './planning-team/dialogs/report-sent/report-sent.component';
import { AppointmentsComponent } from './appointments/appointments.component';
import { InboxComponent } from './inbox/inbox.component';

const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline',
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegistrationComponent,
    FirstTimeComponent,
    VoucherGroupDetailComponent,
    MarkAsPaidComponent,
    SubmitInvoiceComponent,
    InputPaymentreferenceComponent,
    UpgradeDialogComponent,
    GenerateVouchersComponent,
    ReceiptComponent,
    NavBarComponent,
    DashboardTownshipComponent,
    SettingsComponent,
    VoucherManagementComponent,
    VoucherGroupEditComponent,
    OrganisationManagementComponent,
    EditOrganisationComponent,
    SendVoucherComponent,
    ManageVoucherComponent,
    CheckTownshipVouchersComponent,
    CheckVouchersComponent,
    CheckVoucherComponent,
    TagsComponent,
    EditTagComponent,
    DeleteTagComponent,
    ContinueComponent,
    ConfirmationComponent,
    DiscountComponent,
    OrdersComponent,
    OrderComponent,
    ForceApiSyncDialogComponent,
    SetupPaymentDialogComponent,
    SepaComponent,
    SepaConfirmationComponent,
    PaymentReferencesComponent,
    ActionListComponent,
    ChangeTownshipDialogComponent,
    ConfirmationDialogComponent,
    FontsComponent,
    EditFontComponent,
    DeleteFontComponent,
    SelectPdfFontsComponent,
    GroupLinkDialogComponent,
    ExternalVoucherManagementComponent,
    ManageExternalVoucherGroupComponent,
    EditSepaComponent,
    HelpdeskComponent,
    UsersComponent,
    ManageUserComponent,
    DeleteUserComponent,
    VouchergroupsDialogComponent,
    GeneralSettingsComponent,
    ChangeHiddenStatusComponent,
    GroupLinkComponent,
    EditGroupLinkDialogComponent,
    ImportExportComponent,
    HideOrganisation,
    FinishVouchergroupComponent,
    EmailDataDialogComponent,
    ConfirmDeleteDomainsComponent,
    CharacteristicsComponent,
    EditCharacteristicsDialogComponent,
    AddressTypesDialogComponent,
    AssignmentsComponent,
    ManageAssignmentComponent,
    ThemedVoucherGroupEditComponent,
    ThemesComponent,
    EditThemesDialogComponent,
    ShareThemedVoucherGroupComponent,
    DeleteGroupLinkComponent,
    ImageCropperComponent,
    StatisticsComponent,
    StatisticsManagementComponent,
    WidgetManageComponent,
    WidgetDeleteComponent,
    WidgetDailyComponent,
    WidgetActionRunningTimeComponent,
    VouchersClaimedComponent,
    VouchersAvailableComponent,
    VouchersClaimedPerOrgComponent,
    WidgetTotalClaimedValueComponent,
    WidgetHomesPreservedComponent,
    VouchersLineChartComponent,
    WidgetTransactionsComponent,
    WidgetVouchersSoldPerOrgComponent,
    WidgetTotalWorthSoldVouchersComponent,
    SingleWidgetComponent,
    ChangePasswordComponent,
    EditTownshipFileComponent,
    DeleteTownshipFileComponent,
    CreateInvoiceComponent,
    CalculateMonthlyCostComponent,
    WarningDialogComponent,
    BurdenOfProofDialogComponent,
    FillInBopFormDialogComponent,
    ExportReportDialogComponent,
    VouchersExportTypeComponent,
    AddUserToTownshipComponent,
    AccountComponent,
    MultiFactorComponent,
    DeleteMfaConfirmationComponent,
    ConfirmDeleteDialogComponent,
    EditStaffelFileComponent,
    MassMailComponent,
    PlanningComponent,
    DayPartsComponent,
    AppointmentTypesComponent,
    PlanningSettingsComponent,
    DeleteAppointmentTypeDialogComponent,
    ManageAppointmentTypesComponent,
    ManageAppointmentComponent,
    AppointmentDetailsComponent,
    DayPartDeleteDialogComponent,
    DayPartEditComponent,
    ManageTeamUserComponent,
    AppointmentInfoCardComponent,
    AppointmentDeleteComponent,
    ParticularityDeleteDialogComponent,
    ManageParticularityComponent,
    ExceptionEditComponent,
    ExportAppointmentsComponent,
    PlanningTeamComponent,
    TeamAppointmentDetailsComponent,
    TeamAppointmentInfoCardComponent,
    ManageReportComponent,
    ScheduleComponent,
    ManageScheduleExceptionComponent,
    TeamuserExceptionDeleteDialogComponent,
    CalculateCostCenterComponent,
    ReportSentComponent,
    AppointmentsComponent,
    InboxComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFirestoreModule,
    AngularFireAuthGuardModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatSidenavModule,
    MatToolbarModule,
    MatTooltipModule,
    MatMenuModule,
    MatCardModule,
    MatCheckboxModule,
    MatBadgeModule,
    MatChipsModule,
    MatFormFieldModule,
    MatCardModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    NgxMatSelectSearchModule,
    MatTableModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatExpansionModule,
    NgxChartsModule,
    ColorPickerModule,
    MatRadioModule,
    WebcamModule,
    MatStepperModule,
    FileSaverModule,
    NgxMatNativeDateModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    MatMomentDateModule,
    MatNativeDateModule,
    ImageCropperModule,
    DragDropModule,
    ScrollingModule,
    SwiperModule,
    CalendarModule.forRoot({
      provide: CalendarDateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'nl' },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: appearance,
    },
    CurrencyPipe,
    { provide: LANGUAGE_CODE, useValue: 'nl' },
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
    },
    { provide: REGION, useValue: 'europe-west1' },
    { provide: CalendarDateFormatter, useClass: CalendarNativeDateFormatter },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
