import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  updateDoc,
  deleteField,
  deleteDoc,
  onSnapshot,
} from 'firebase/firestore';
import { environment } from 'src/environments/environment';
import { DataStoreExport, GroupLink } from '../interfaces';
import { DeleteGroupLinkComponent } from './dialog/delete-group-link/delete-group-link.component';

import { EditGroupLinkDialogComponent } from './dialog/edit-group-link-dialog/edit-group-link-dialog.component';
import { dataStore, db } from '../app.component';
@Component({
  selector: 'app-group-links',
  templateUrl: './group-links.component.html',
  styleUrls: ['./group-links.component.scss'],
})
export class GroupLinkComponent implements OnInit {
  dataStore: DataStoreExport = dataStore;
  townshipId = dataStore.township.id;
  organisationId = dataStore.organisation?.id;
  env = environment;
  groupLinks: GroupLink[] = [];

  constructor(public db: AngularFirestore, public dialog: MatDialog) {}

  async ngOnInit() {
    const groupLinksRef = collection(
      db,
      `township/${this.townshipId}/groupLinks`
    );
    onSnapshot(groupLinksRef, (querySnapshot) => {
      const groupLinks: GroupLink[] = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data() as GroupLink;
        groupLinks.push({ id: doc.id, ...data });
      });
      groupLinks.sort((a, b) => a.name.localeCompare(b.name));
      this.groupLinks = groupLinks;
    });
  }

  openDialog(event, group?: GroupLink) {
    event.stopPropagation();
    this.dialog.open(EditGroupLinkDialogComponent, {
      width: '500px',
      data: {
        groupLink: group ?? null,
        townshipId: this.townshipId,
      },
    });
  }

  async delete(event, groupLink: GroupLink) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(DeleteGroupLinkComponent, {
      width: '475px',
      autoFocus: false,
      data: { groupLink },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        const allGroupLinks = await getDocs(
          query(
            collection(db, `township/${this.townshipId}/voucherGroups`),
            where('groupLink', '==', groupLink.name)
          )
        );
        const promises: any[] = [];
        allGroupLinks.forEach((groupLink) => {
          promises.push(
            updateDoc(
              doc(
                db,
                `township/${this.townshipId}/voucherGroups`,
                `${groupLink.id}`
              ),
              {
                groupLink: deleteField(),
              }
            )
          );
        });
        promises.push(
          deleteDoc(
            doc(db, `township/${this.townshipId}/groupLinks`, `${groupLink.id}`)
          )
        );
      }
    });
  }
}
