import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { Firestore, arrayUnion } from 'firebase/firestore';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Order, Voucher, Township } from 'src/app/interfaces';
import { doc, getDoc } from 'firebase/firestore';

export interface DialogData {
  orderRef: AngularFirestoreDocument<Order>;
}

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
})
export class OrderComponent implements OnInit {
  form: UntypedFormGroup;
  order: Observable<Order>;
  orderObj: Order;
  loading: boolean;
  usePackingSlipPdf: boolean = false;

  error: boolean;
  errorMessage: string;
  loaded: boolean = false;

  @ViewChild('voucherNumber', { static: false }) ref: ElementRef;

  constructor(
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<OrderComponent>,
    public afs: AngularFirestore
  ) {}

  async ngOnInit() {
    this.order = this.data.orderRef.snapshotChanges().pipe(
      map((actions) => {
        const data = actions.payload.data() as Order;
        data.id = actions.payload['id'];
        if (!data.vouchers) {
          data.vouchers = [];
        }
        return { ...data };
      })
    );
    this.order.subscribe((order) => {
      this.orderObj = order;
      console.log('order', order);
    });
    const townshipId = localStorage.getItem('township');
    const township = (
      await getDoc(doc(this.db.firestore, `township/${townshipId}`))
    ).data() as Township;
    this.usePackingSlipPdf = township.usePackingSlipPdf;

    this.form = this.fb.group({
      voucherNumber: [, Validators.required],
    });
    this.form.controls.voucherNumber.valueChanges.subscribe((val) => {
      // Remove custom error and then check for other errors if this error is there
      if (this.form.controls.voucherNumber.hasError('customError')) {
        this.form.controls.voucherNumber.setErrors(null);
        this.form.controls.voucherNumber.updateValueAndValidity();
      }
    });
    this.loaded = true;
  }
  async markAsDone() {
    this.loading = true;
    await this.data.orderRef.update({
      status: 'done',
    });
    this.dialogRef.close();
  }

  async addVoucher() {
    console.log(this.form.controls.voucherNumber.errors);
    if (!this.loading && this.form.valid) {
      this.loading = true;
      this.error = false;
      const form = this.form.value;
      console.log('form', form);
      const voucher = (await this.getVoucher(form.voucherNumber)) as Voucher;
      if (voucher) {
        console.log('voucher', voucher);
        if (voucher.voucherGroupId === this.orderObj.voucherGroupId) {
          if (!voucher.activateDate && voucher) {
            await this.activateVoucher(form.voucherNumber);
            this.form.reset();
            this.loading = false;
            if (this.ref) {
              this.ref.nativeElement.focus(); // focus to bon nummer input
            }
          } else {
            this.showError('Deze bon is al geactiveerd.');
          }
        } else {
          this.showError('Deze bon zit niet in de correcte campagne.');
        }
      } else {
        this.showError('Deze bon bestaat niet.');
      }
    }
  }

  async activateVoucher(voucherNumber) {
    console.log('should activate', voucherNumber);
    const voucherRef = this.db.doc<Voucher>(
      'township/' +
        localStorage.getItem('township') +
        '/vouchers/' +
        voucherNumber
    );
    const batch = this.db.firestore.batch();
    batch.update(voucherRef.ref, {
      activateDate: new Date(),
      orderId: this.orderObj.id,
    });
    batch.update(this.data.orderRef.ref, {
      vouchers: arrayUnion(voucherNumber),
    });
    await batch.commit();
    return;
  }

  showError(message: string) {
    this.error = true;
    this.form.controls.voucherNumber.setErrors({ customError: true });
    this.errorMessage = message;
    this.ref.nativeElement.focus();
    this.ref.nativeElement.select();
    this.loading = false;
  }

  async getVoucher(voucherNumber: string) {
    const voucherRef = doc(
      this.db.firestore,
      `township/${localStorage.getItem('township')}/vouchers/${voucherNumber}`
    );
    const voucher = (await getDoc(voucherRef)).data() as any;
    if (voucher) {
      console.log('voucher', { ...voucher });
      if (voucher.activateDate) {
        voucher.activateDate = voucher.activateDate.toDate();
      }
      if (voucher.claimDate) {
        voucher.claimDate = voucher.claimDate.toDate();
      }
      if (voucher.paidDate) {
        voucher.paidDate = voucher.paidDate.toDate();
      }
    }
    return voucher;
  }

  isFocus() {
    if (this.form.valid) {
      this.addVoucher();
    }
  }

  openPackingSlipPdf() {
    window.open(this.orderObj.packingSlipUrl);
  }
}
