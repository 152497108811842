import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  collection,
  query,
  where,
  getDocs,
  collectionGroup,
} from 'firebase/firestore';
import moment from 'moment';
import { db } from 'src/app/app.component';
import { StaffelFile, VoucherGroup } from 'src/app/interfaces';
import * as XLSX from 'xlsx';
import * as _ from 'lodash';

interface DialogData {
  staffel: StaffelFile;
}

@Component({
  selector: 'app-calculate-cost-center',
  templateUrl: './calculate-cost-center.component.html',
  styleUrls: ['./calculate-cost-center.component.scss'],
})
export class CalculateCostCenterComponent implements OnInit {
  dateForm: UntypedFormGroup;
  years = [];
  months = [
    { month: 'Januari', period: 1 },
    { month: 'Februari', period: 2 },
    { month: 'Maart', period: 3 },
    { month: 'April', period: 4 },
    { month: 'Mei', period: 5 },
    { month: 'Juni', period: 6 },
    { month: 'Juli', period: 7 },
    { month: 'Augustus', period: 8 },
    { month: 'September', period: 9 },
    { month: 'Oktober', period: 10 },
    { month: 'November', period: 11 },
    { month: 'December', period: 12 },
  ];
  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: UntypedFormBuilder,
    private snackbar: MatSnackBar,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    console.log('staffel:', this.data.staffel);

    this.dateForm = this.fb.group({
      year: [, [Validators.required]],
      month: [, [Validators.required]],
    });
    this.getYears();
  }

  getYears() {
    const year = new Date().getFullYear();
    this.dateForm.controls.year.setValue(year);
    for (let i = 0; i < 5; i += 1) {
      this.years.push(Number(year) - i);
    }
    return this.years.sort((a, b) => a - b);
  }

  async calculateCostCenter() {
    this.loading = true;
    const costCenters = await this.getCostCenters();
    console.log('costCenters', costCenters);
    const vouchers = await this.getVouchers(costCenters);
    console.log('vouchers', vouchers);
    const calculatedCostCenters = [];
    costCenters.forEach((costCenter) => {
      let totalAmount = 0;
      vouchers.forEach((voucher) => {
        if (
          voucher.costCenter === costCenter.costCenter &&
          voucher.townshipId === costCenter.townshipId &&
          voucher.amountToPayOrg
        ) {
          totalAmount += voucher.amountToPayOrg;
        }
      });
      calculatedCostCenters.push({
        costCenter: costCenter.costCenter,
        townshipId: costCenter.townshipId,
        totalAmount: Number(totalAmount.toFixed(2)),
      });
    });
    console.log('calculatedCostCenters', calculatedCostCenters);
    const costCentersForExport = [];
    calculatedCostCenters.forEach((calculatedCostCenter) => {
      const township = this.data.staffel.townships.find((township) => {
        return township.id === calculatedCostCenter.townshipId;
      });
      costCentersForExport.push({
        townshipName: township.name,
        period: `${this.dateForm.value.month}-${this.dateForm.value.year}`,
        costCenter: calculatedCostCenter.costCenter,
        commission: calculatedCostCenter.totalAmount,
      });
    });

    // const groupedCalculatedCostCenters = _.groupBy(
    //   costCentersForExport,
    //   'townshipName'
    // );
    // console.log('GROUPED?', groupedCalculatedCostCenters);

    // // TODO: Object.groupBy is supported from Typescript 5.4, need to upgrade to Angular 17.3 to support Typescript 5.4
    // // const groupedCalculatedCostCenters = Object.groupBy(
    // //   costCentersForExport,
    // //   ({ townshipName }) => townshipName
    // // );
    // // console.log('groupedCalculatedCostCenters', groupedCalculatedCostCenters);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    // for (const [townshipName, costCenters] of Object.entries(
    //   groupedCalculatedCostCenters
    // )) {

    costCentersForExport.sort((a, b) =>
      a.townshipName.localeCompare(b.townshipName)
    );

    const costCentersJSON = [];
    for (const costCenter of costCentersForExport) {
      // if (townshipName === costCenter.townshipName) {
      const exportCostCenter = {};
      exportCostCenter['Gemeente'] = costCenter.townshipName;
      exportCostCenter['Periode'] = costCenter.period;
      exportCostCenter['Kostenplaats'] = costCenter.costCenter;
      exportCostCenter['Grondslag voor courtage'] = costCenter.commission;
      costCentersJSON.push(exportCostCenter);
      // }
    }
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(costCentersJSON);
    // Trim townshipname because sheetname is limited to 31 chars
    let sheetName = this.data.staffel.name;
    if (sheetName.length > 31) {
      sheetName = sheetName.substring(0, 31);
    }
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    // }
    XLSX.writeFile(
      wb,
      `Kostenplaats ${this.data.staffel.name} export ${this.dateForm.value.month}-${this.dateForm.value.year}.xlsx`
    );
    this.loading = false;
  }

  async getCostCenters(): Promise<
    [{ townshipId: string; costCenter: string }]
  > {
    const townshipIds = this.data.staffel.townships.map((township) => {
      return township.id;
    });
    const voucherGroupQuery = query(
      collectionGroup(db, 'voucherGroups'),
      where('costCenter', '!=', null)
    );
    const voucherGroupsDocs = (await getDocs(voucherGroupQuery)).docs;
    const filteredVoucherGroups = [];
    voucherGroupsDocs.forEach((voucherGroupDoc) => {
      const voucherGroup = voucherGroupDoc.data() as VoucherGroup;
      if (
        townshipIds.includes(voucherGroupDoc.ref.parent.parent.id) &&
        !filteredVoucherGroups.find((filteredVoucherGroup) => {
          return (
            filteredVoucherGroup.townshipId ===
              voucherGroupDoc.ref.parent.parent.id &&
            filteredVoucherGroup.costCenter === voucherGroup.costCenter
          );
        })
      ) {
        filteredVoucherGroups.push({
          townshipId: voucherGroupDoc.ref.parent.parent.id,
          costCenter: voucherGroup.costCenter,
        });
      }
    });

    return filteredVoucherGroups as [
      { townshipId: string; costCenter: string }
    ];
  }

  async getVouchers(
    voucherGroups: [{ townshipId: string; costCenter: string }]
  ) {
    const date = new Date(
      this.dateForm.value.year,
      this.dateForm.value.month - 1
    );
    const startOfMonth = moment(date).startOf('month').toDate();
    const endOfMonth = moment(date).endOf('month').toDate();
    const vouchers = [];
    for (const voucherGroup of voucherGroups) {
      const voucherQuery = query(
        collection(db, `township/${voucherGroup.townshipId}/vouchers`),
        where('costCenter', '==', voucherGroup.costCenter),
        where('paidDate', '>=', startOfMonth),
        where('paidDate', '<=', endOfMonth)
      );
      const voucherDocs = (await getDocs(voucherQuery)).docs;
      for (const doc of voucherDocs) {
        const data = doc.data();
        vouchers.push({
          ...data,
          id: doc.id,
          townshipId: voucherGroup.townshipId,
        });
      }
    }
    return vouchers;
  }
}
