import { doc, getDoc, Timestamp } from 'firebase/firestore';
import moment from 'moment-timezone';
import { Township, VoucherGroup } from './interfaces';
import { environment } from '../environments/environment';
import { VoucherStatus } from './enums';

export function getFileExtension(file: File) {
  const fname = file.name;
  return fname.slice(((fname.lastIndexOf('.') - 1) >>> 0) + 2);
}

export function sleep(ms: number) {
  if (ms < 0) {
    return;
  }
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function calculateValidUntil(
  activateDate: Date,
  voucherGroup: VoucherGroup
) {
  console.log('calculateValidUntil activateDate', activateDate);
  console.log('calculateValidUntil voucherGroup', voucherGroup);
  let validUntilDate;
  if (voucherGroup) {
    if (voucherGroup.validUntilDate) {
      try {
        validUntilDate = voucherGroup.validUntilDate.toDate();
      } catch {
        validUntilDate = voucherGroup.validUntilDate;
      }
    }
    if (voucherGroup.validUntilTime && activateDate) {
      const activateDateWithoutHours = new Date(
        activateDate.getFullYear(),
        activateDate.getMonth(),
        activateDate.getDate()
      );
      const validDate = new Date(activateDateWithoutHours);
      if (
        voucherGroup.validUntilTimeType === 'days' &&
        voucherGroup.validUntilTimeValue
      ) {
        validDate.setDate(
          validDate.getDate() + voucherGroup.validUntilTimeValue
        );
      } else if (
        voucherGroup.validUntilTimeType === 'weeks' &&
        voucherGroup.validUntilTimeValue
      ) {
        validDate.setDate(
          validDate.getDate() + voucherGroup.validUntilTimeValue * 7
        );
      } else if (
        voucherGroup.validUntilTimeType === 'months' &&
        voucherGroup.validUntilTimeValue
      ) {
        validDate.setMonth(
          validDate.getMonth() + voucherGroup.validUntilTimeValue
        );
      } else if (
        voucherGroup.validUntilTimeType === 'years' &&
        voucherGroup.validUntilTimeValue
      ) {
        validDate.setFullYear(
          validDate.getFullYear() + voucherGroup.validUntilTimeValue
        );
      }
      if (!validUntilDate || validDate < validUntilDate) {
        validUntilDate = validDate;
      }
      // validUntilDate is the hardcoded expire date
      // validDate is the other expire vouchergroup option
      console.log('validUntilDate', validUntilDate);
      console.log('validDate', validDate);
      console.log('validUntilDate VoucherGroup.validUntilTime', validUntilDate);
    }
    let validUntilString;
    let reversedString;
    let unixTimestamp;
    if (validUntilDate) {
      if (voucherGroup.validUntilTime) {
        validUntilDate = new Date(validUntilDate);
      }
      const validUntilDateMoment = moment
        .tz(validUntilDate, 'Europe/Amsterdam')
        .hour(23)
        .minute(59)
        .second(59);
      unixTimestamp = validUntilDateMoment.unix();
      validUntilDate = new Date(
        validUntilDateMoment.format('YYYY-MM-DD HH:mm')
      );
      console.log('validUntilDate In cutof', validUntilDate);

      let day = validUntilDate.getDate().toString();
      if (day.length === 1) {
        day = `0${day}`;
      }
      let month = (validUntilDate.getMonth() + 1).toString();
      if (month.length === 1) {
        month = `0${month}`;
      }
      const year = validUntilDate.getFullYear().toString();
      validUntilString = `${day}-${month}-${year}`;
      reversedString = `${year}-${month}-${day}`;
      console.log('string', validUntilString);
      console.log('reversedString', reversedString);
    }
    return {
      date: validUntilDate as Date,
      string: validUntilString,
      reversedString,
      unix: unixTimestamp,
      timestamp: unixTimestamp
        ? Timestamp.fromMillis(unixTimestamp * 1000)
        : null,
    };
  }
}

export function shortenNumber(number?: number) {
  if (!number) {
    number = 0;
  }
  if (number > 999999) {
    return `${Math.floor(number / 1000000).toFixed(0)}M`;
  }
  if (number > 999) {
    return `${Math.floor(number / 1000).toFixed(0)}K`;
  }
  return number.toFixed(2).replace('.', ',');
}

export async function iframeUrl(
  townshipId: string,
  db: any,
  language?: string
) {
  const township = (
    await getDoc(doc(db, `township/${townshipId}`))
  ).data() as Township;

  if (!language) {
    language = '';
  } else {
    language = language + '.';
  }
  let iframeUrlEnv;
  if (environment.iframeUrl.includes('frame.lokalebon.nl')) {
    iframeUrlEnv = environment.iframeUrl.split('frame.lokalebon.nl');
  } else if (environment.iframeUrl.includes('frame.duurzaamwonenbon.nl')) {
    iframeUrlEnv = environment.iframeUrl.split('frame.duurzaamwonenbon.nl');
  } else {
    iframeUrlEnv = environment.iframeUrl.split('frame.hallobon.nl');
  }

  let url;
  if (township.voucherPrefix == `groene` && township.usesGroeneAppWebsite) {
    url = `${iframeUrlEnv[0]}groeneappaanvraag.nl`;
  } else if (environment.iframeUrl.includes('frame.lokalebon.nl')) {
    url = `${iframeUrlEnv[0]}${language}frame.lokalebon.nl`;
  } else if (environment.iframeUrl.includes('frame.duurzaamwonenbon.nl')) {
    url = `${iframeUrlEnv[0]}${language}frame.duurzaamwonenbon.nl`;
  } else {
    url = `${iframeUrlEnv[0]}${language}frame.hallobon.nl`;
  }
  return url;
}

export function arrayRemove(arr: any[], value: any) {
  return arr.filter(function (ele) {
    return ele != value;
  });
}

export function encodeRouteParam(param: string) {
  const temp = window.btoa(param);
  return encodeURIComponent(temp);
}

export function decodeRouteParam(param: string) {
  const temp = decodeURIComponent(param);
  return window.atob(temp);
}

export function getVoucherStatusName(status: string) {
  switch (status) {
    case VoucherStatus.available:
      return 'Beschikbaar';
    case VoucherStatus.activated:
      return 'Geactiveerd';
    case VoucherStatus.partiallyClaimed:
      return 'Gedeeltelijk geclaimd';
    case VoucherStatus.claimed:
      return 'Geclaimd';
    case VoucherStatus.paid:
      return 'Betaald';
    case VoucherStatus.expired:
      return 'Vervallen';
    case VoucherStatus.blocked:
      return 'Geblokkeerd';
    default:
      return status;
  }
}
