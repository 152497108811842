import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  updateDoc,
  deleteField,
  deleteDoc,
  onSnapshot,
} from 'firebase/firestore';
import { MatDialog } from '@angular/material/dialog';
import { DataStoreExport, Tag } from '../interfaces';
import { EditTagComponent } from './edit-tag/edit-tag.component';
import { DeleteTagComponent } from './delete-tag/delete-tag.component';
import { dataStore, db } from '../app.component';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
})
export class TagsComponent implements OnInit {
  dataStore: DataStoreExport = dataStore;
  townshipId = dataStore.township.id;
  organisationId = dataStore.organisation?.id;
  tags: Tag[] = [];

  constructor(
    public db: AngularFirestore,
    public afAuth: AngularFireAuth,
    public dialog: MatDialog
  ) {}

  async ngOnInit() {
    const tagsRef = collection(db, `township/${this.townshipId}/tags`);
    onSnapshot(tagsRef, (querySnapshot) => {
      const tags: Tag[] = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data() as Tag;
        tags.push({ id: doc.id, ...data });
      });
      tags.sort((a, b) => a.name.localeCompare(b.name));
      this.tags = tags;
    });
  }
  openEditTag(event, tag?: Tag) {
    event.stopPropagation();
    this.dialog.open(EditTagComponent, {
      width: '500px',
      autoFocus: false,
      data: { tag, townshipId: this.townshipId },
    });
  }
  async delete(event, tag: Tag) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(DeleteTagComponent, {
      width: '475px',
      autoFocus: false,
      data: { tag },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        const allTags = await getDocs(
          query(
            collection(db, `township/${this.townshipId}/tags`),
            where('id', '==', tag.id)
          )
        );
        const promises: any[] = [];
        allTags.forEach((Tag) => {
          promises.push(
            updateDoc(
              doc(db, `township/${this.townshipId}/tags`, `${Tag.id}`),
              {
                Tag: deleteField(),
              }
            )
          );
        });
        promises.push(
          deleteDoc(doc(db, `township/${this.townshipId}/tags`, `${tag.id}`))
        );
      }
    });
  }
}
