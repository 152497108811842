import { Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder } from '@angular/forms';
import { db } from '../app.component';
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  Timestamp,
  where,
} from 'firebase/firestore';
import { Appointment, PlanningUser } from '../interfaces';
import { getAuth } from 'firebase/auth';
import moment from 'moment';
import { ActionType } from '../enums';
import { MatDialog } from '@angular/material/dialog';
import { TeamAppointmentDetailsComponent } from '../planning-team/dialogs/team-appointment-details/team-appointment-details.component';
import { ManageReportComponent } from '../planning-team/dialogs/manage-report/manage-report.component';
// import { ManageReportComponent } from '../planning-team/dialogs/manage-report/manage-report.component';

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.scss'],
})
export class AppointmentsComponent implements OnInit {
  loaded: boolean = false;
  townshipId: string = localStorage.getItem('township');
  userId: string;
  user: PlanningUser;
  moment = moment;
  filterForm = this.fb.group({
    search: [''],
    filters: new FormControl(['today']),
  });
  filters: any[] = [
    { val: 'today', name: 'Vandaag' },
    { val: ActionType.created, name: 'Nieuw' },
    { val: ActionType.done, name: 'Afgehandeld' },
    { val: 'expired', name: 'Verlopen' },
  ];
  appointments: Appointment[] = [];
  filteredAppointments: Appointment[] = [];

  constructor(private fb: UntypedFormBuilder, public dialog: MatDialog) {}

  async ngOnInit() {
    const auth = getAuth();
    this.userId = auth.currentUser.uid;
    this.user = (
      await getDoc(
        doc(db, `township/${this.townshipId}/planningUsers/${this.userId}`)
      )
    ).data() as PlanningUser;
    await this.getAppointments();
    this.loaded = true;
  }

  async getAppointments() {
    const appointmentsQuery = query(
      collection(db, `township/${this.townshipId}/appointments`),
      where('planningUserIds', 'array-contains', this.userId),
      orderBy('start', 'desc')
    );
    onSnapshot(appointmentsQuery, (querySnapshot) => {
      const appointments = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        appointments.push({
          id: doc.id,
          ...data,
          start: data.start.toDate(),
          end: data.end.toDate(),
        } as Appointment);
      });
      this.appointments = appointments;
      this.filterAppointments();
    });
  }

  filterAppointments() {
    this.filteredAppointments = this.appointments.filter((appointment) => {
      const filters = Object.assign([], this.filterForm.value.filters);
      if (appointment?.status == ActionType.canceled) {
        return false;
      }
      const searchString = this.filterForm.value.search.toLowerCase();
      const street: string = `${appointment?.street ?? ''} ${
        appointment?.houseNumber ?? ''
      }${appointment?.houseNumberAddition ?? ''}`;
      const postal: string = `${appointment?.postal ?? ''} ${
        appointment?.houseNumber ?? ''
      }${appointment?.houseNumberAddition ?? ''}`;
      if (
        searchString != '' &&
        !postal.toLowerCase().includes(searchString) &&
        !street?.toLowerCase().includes(searchString) &&
        !appointment?.city?.toLowerCase().includes(searchString) &&
        !appointment?.userEmail?.toLowerCase().includes(searchString) &&
        !appointment?.userName?.toLowerCase().includes(searchString) &&
        !appointment?.appointmentTypeName?.toLowerCase().includes(searchString)
      ) {
        return false;
      }
      if (filters.length == 0) {
        return true;
      }
      if (filters.indexOf('today') != -1) {
        if (moment(appointment.start).isSame(moment(), 'day')) {
          return true;
        }
        filters.splice(filters.indexOf('today'), 1);
      }
      if (filters.indexOf('expired') != -1) {
        if (
          moment(appointment.start).isBefore(moment(), 'day') &&
          appointment?.status != ActionType.done
        ) {
          return true;
        }
        filters.splice(filters.indexOf('expired'), 1);
      }
      if (filters.length > 0 && filters.indexOf(appointment?.status) != -1) {
        return true;
      }
      return false;
    });
  }

  appointmentDetails(appointment: Appointment) {
    this.dialog.open(TeamAppointmentDetailsComponent, {
      data: {
        appointment: appointment,
        planningUsers: [this.user],
      },
      disableClose: true,
      panelClass: ['fullscreen-dialog'],
    });
  }

  createReport(appointment) {
    const dialogRef = this.dialog.open(ManageReportComponent, {
      data: {
        appointment: appointment,
      },
      disableClose: true,
      panelClass: ['fullscreen-dialog'],
    });
  }

  getMapsUrl(appointment) {
    const addressString = `${appointment.street ?? ''} ${
      appointment.houseNumber
    }${appointment.houseNumberAddition}, ${appointment.postal} ${
      appointment.city ?? ''
    }, Netherlands`;
    return `https://maps.google.com/?q=${addressString}`;
  }

  getTimeString(start: Date, end: Date) {
    return moment(start).format('HH:mm') + ' - ' + moment(end).format('HH:mm');
  }

  getDateString(date: Date) {
    return this.ucFirst(moment(date).locale('nl').format('dd DD MMMM YYYY'));
  }

  ucFirst(string: string) {
    return String(string).charAt(0).toUpperCase() + String(string).slice(1);
  }
}
