import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { numberInput } from 'angular-custom-validators';
import { getAuth } from 'firebase/auth';
import { collection, doc, getDoc, setDoc } from 'firebase/firestore';
import moment from 'moment';
import { db } from 'src/app/app.component';
import { DayPartException, PlanningUser } from 'src/app/interfaces';

@Component({
  selector: 'app-exception-edit',
  templateUrl: './exception-edit.component.html',
  styleUrls: ['./exception-edit.component.scss'],
})
export class ExceptionEditComponent implements OnInit {
  townshipId: string = localStorage.getItem('township');
  saving: boolean = false;
  loaded: boolean = false;
  isPlanner: boolean = false;
  exception: DayPartException;
  minHour: number = 0;
  maxHour: number = 23;

  exceptionForm: UntypedFormGroup = this.fb.group({
    title: new UntypedFormControl('', Validators.required),
    date: new UntypedFormControl(null, Validators.required),
    description: new UntypedFormControl(''),
    allDay: new UntypedFormControl(false),
    startHour: new UntypedFormControl(null, [
      (control: AbstractControl) => Validators.min(this.minHour)(control),
      (control: AbstractControl) => Validators.max(this.maxHour)(control),
      numberInput(false, false, 0),
    ]),
    startMinutes: new UntypedFormControl(null, [
      Validators.min(0),
      Validators.max(59),
      numberInput(false, false, 0),
    ]),
    endHour: new UntypedFormControl(null, [
      (control: AbstractControl) => Validators.min(this.minHour)(control),
      (control: AbstractControl) => Validators.max(this.maxHour)(control),
      numberInput(false, false, 0),
    ]),
    endMinutes: new UntypedFormControl(null, [
      Validators.min(0),
      Validators.max(59),
      numberInput(false, false, 0),
    ]),
    repeat: new UntypedFormControl(false),
    repeatValue: new UntypedFormControl(''),
  });

  repeatValues = [
    ['year', 'Jaarlijks'],
    ['month', 'Maandelijks'],
    ['week', 'Wekelijks'],
  ];

  constructor(
    private fb: UntypedFormBuilder,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    const auth = getAuth();
    const user = (
      await getDoc(
        doc(
          db,
          `township/${this.townshipId}/planningUsers/${auth.currentUser.uid}`
        )
      )
    ).data() as PlanningUser;
    if (user?.rights === 'planner') {
      this.isPlanner = true;
    }
    const id = this.route.snapshot.paramMap.get('id');
    if (id && id != 'new') {
      if (this.isPlanner) {
        this.router.navigate(['day-parts'], { state: { tabIndex: 1 } });
      }
      const ref = doc(
        db,
        `township/${this.townshipId}/dayPartsExceptions/${id}`
      );
      this.exception = {
        id: id,
        ...(await getDoc(ref)).data(),
      } as DayPartException;
      this.exceptionForm.patchValue(this.exception);
      this.exceptionForm.patchValue({ date: this.exception.date.toDate() });
    }

    this.toggleHandler(null, true);
    this.loaded = true;
  }

  toggleHandler(controlName, init = false) {
    const controls = this.exceptionForm.controls;
    const timeControls = ['startHour', 'startMinutes', 'endHour', 'endMinutes'];

    if (controlName == 'repeat' || init) {
      if (controls.repeat.value) {
        controls.repeatValue.addValidators(Validators.required);
      } else {
        controls.repeatValue.removeValidators(Validators.required);
        controls.repeatValue.updateValueAndValidity();
      }
    }

    if (controlName == 'allDay' || init) {
      if (!this.exceptionForm.controls.allDay.value) {
        for (const control of timeControls) {
          controls[control].addValidators(Validators.required);
        }
      } else {
        for (const control of timeControls) {
          controls[control].removeValidators(Validators.required);
          controls[control].updateValueAndValidity();
        }
      }
    }
  }

  async save() {
    this.exceptionForm.markAllAsTouched();
    if (!this.exceptionForm.valid) {
      return;
    }
    const saveObj = this.exceptionForm.value;

    if (!saveObj.repeat) {
      saveObj.repeatValue = null;
    }
    if (saveObj.allDay) {
      saveObj.startHour = null;
      saveObj.startMinutes = null;
      saveObj.endHour = null;
      saveObj.endMinutes = null;
    } else if (this.checkTimeOverlap(saveObj)) {
      this.snackbar.open(
        'De starttijd moet plaatsvinden voor de eindtijd',
        'X',
        {
          duration: 5000,
        }
      );
      return;
    }
    let docRef;
    if (this.exception) {
      docRef = doc(
        collection(db, `/township/${this.townshipId}/dayPartsExceptions`),
        this.exception.id
      );
    } else {
      docRef = doc(
        collection(db, `/township/${this.townshipId}/dayPartsExceptions`)
      );
    }
    try {
      await setDoc(docRef, saveObj, { merge: true });
    } catch (error) {
      console.log(error);
      this.snackbar.open(
        'Er is iets fout gegaan met het opslaan van de gegevens',
        'X',
        {
          duration: 5000,
        }
      );
      return;
    }
    this.router.navigate(['day-parts'], { state: { tabIndex: 1 } });
  }

  checkTimeOverlap(obj) {
    const saveStartTime = moment().set({
      hour: obj.startHour,
      minutes: obj.startMinutes,
    });
    const saveEndTime = moment().set({
      hour: obj.endHour,
      minutes: obj.endMinutes,
    });
    if (saveStartTime >= saveEndTime) return true;
    return false;
  }

  getError(control) {
    const field = this.exceptionForm.get(control);
    if (field.touched || !field.pristine) {
      let error: string;
      if (field.hasError('required')) {
        error = 'Dit veld is verplicht';
      }
      if (field.hasError('min')) {
        if (control == 'startHour' || control == 'endHour') {
          error = `De waarde moet ${this.minHour} of hoger zijn`;
        } else {
          error = 'De waarde moet 0 of hoger zijn';
        }
      }
      if (field.hasError('max')) {
        if (control == 'startHour' || control == 'endHour') {
          error = `De waarde moet ${this.maxHour} of lager zijn`;
        } else {
          error = 'De waarde moet 59 of lager zijn';
        }
      }
      if (field.hasError('numberInput')) {
        error = 'De waarde mag niet decimaal zijn';
      }
      return error;
    }
    return '';
  }
}
