import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Firestore, deleteField } from 'firebase/firestore';
import { Tag } from 'src/app/interfaces';
import { doc, setDoc } from 'firebase/firestore';
import { db } from 'src/app/app.component';

export interface DialogData {
  tag: Tag;
  townshipId?: string;
}

@Component({
  selector: 'app-edit-tag',
  templateUrl: './edit-tag.component.html',
  styleUrls: ['./edit-tag.component.scss'],
})
export class EditTagComponent implements OnInit {
  newTag = false;
  tagId: string;
  tagForm: UntypedFormGroup;

  constructor(
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditTagComponent>
  ) {}

  ngOnInit() {
    this.tagForm = this.fb.group({
      name: [, Validators.required],
    });
    if (this.data.tag) {
      this.tagId = this.data.tag.id;
      this.tagForm.patchValue(this.data.tag);
    } else {
      this.tagId = this.db.createId();
      this.newTag = true;
    }
  }

  async save() {
    const saveObj = { ...this.tagForm.value } as Tag;
    // Make sure no "null" values are attempting to be saved, also if something is null it will be deleted from the database.
    Object.keys(saveObj).forEach((key) => {
      if (saveObj[key] == null) {
        saveObj[key] = deleteField();
      } else if (
        typeof saveObj[key] === 'string' &&
        saveObj[key].length === 0
      ) {
        saveObj[key] = deleteField();
      }
    });
    console.log('saveObj', saveObj);
    await setDoc(
      doc(db, `/township/${this.data.townshipId}/tags/${this.tagId}`),
      saveObj,
      {
        merge: true,
      }
    );
    this.dialogRef.close();
  }

  getError(name) {
    const field = this.tagForm.get(name);
    if (field.touched || !field.pristine) {
      if (field.hasError('required')) {
        return 'Dit veld moet ingevuld zijn.';
      }
      return '';
    }
  }
}
