import { Component, OnInit, Inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ibanValidator, numberInput } from 'angular-custom-validators';
import {
  collection,
  deleteField,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
} from 'firebase/firestore';
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from 'firebase/storage';
import moment from 'moment';
import { CommissionType } from 'src/app/enums';
import {
  CommissionSettings,
  MonthlyCostDoc,
  StaffelFile,
} from 'src/app/interfaces';

interface DialogData {
  currentTownshipId: string;
  townshipFileId: string | null;
  townshipFileName?: string;
}

@Component({
  selector: 'app-confirm-delete-domains',
  templateUrl: './edit-township-file.component.html',
  styleUrls: ['./edit-township-file.component.scss'],
})
export class EditTownshipFileComponent implements OnInit {
  commissionForm: UntypedFormGroup;
  commissionTypes = CommissionType;
  invoiceTemplateTownship: string = '';
  invoiceTemplateOrganisation: string = '';
  dialogTitle: string = this.data.townshipFileId
    ? `Exploitantenbestand aanpassen`
    : 'Exploitantenbestand aanmaken';
  townshipId: string = this.data.townshipFileId
    ? this.data.townshipFileId
    : this.data.currentTownshipId;
  townships: any[] = [];
  commissionFiles: CommissionSettings[] = [];
  commissionSettings: CommissionSettings;
  loading: boolean = false;
  saving: boolean = false;
  originalValues = {
    btwPercentage: 21,
    commissionType: CommissionType.Provided,
    commissionPercentageTownship: 0,
  };
  staffelId: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<EditTownshipFileComponent>,
    public dialog: MatDialog,
    private db: AngularFirestore,
    private st: AngularFireStorage,
    private fb: UntypedFormBuilder
  ) {}

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.commissionForm = this.fb.group({
      townshipName: [, [Validators.required]],
      operatorName: ['Wemaron B.V.', [Validators.required]],
      operatorBankAccount: [
        'NL13RABO0374511292',
        [ibanValidator, Validators.required],
      ],
      commissionPercentageTownship: [
        0,
        [numberInput(false, true, 2), Validators.required],
      ],
      commissionPercentageOrganisation: [
        0,
        [numberInput(false, true, 2), Validators.required],
      ],
      commissionType: [this.commissionTypes.Provided, [Validators.required]],
      btwPercentage: [21, [numberInput(false, true, 2), Validators.required]],
      perVoucherCost: [0, [numberInput(false, true, 4), Validators.required]],
      perMonthCost: [0, [numberInput(false, true, 2), Validators.required]],
    });

    await this.getDBData();
    this.loading = false;
  }

  async save() {
    this.saving = true;
    const saveObj = { ...this.commissionForm.value };

    Object.keys(saveObj).forEach((key: any) => {
      if (
        key === 'btwPercentage' ||
        key === 'perVoucherCost' ||
        key === 'perMonthCost' ||
        key === 'commissionPercentageTownship' ||
        key === 'commissionPercentageOrganisation'
      ) {
        saveObj[key] = Number(
          this.commissionForm.value[key].toString().replace(',', '.')
        );
      }
    });

    if (
      saveObj.btwPercentage !== this.originalValues.btwPercentage ||
      saveObj.commissionType !== this.originalValues.commissionType ||
      saveObj.commissionPercentageTownship !==
        this.originalValues.commissionPercentageTownship
    ) {
      await this.setInvoiceNumberOldCosts();
    }

    saveObj.townshipId = this.commissionForm.controls.townshipName.value;
    saveObj.townshipName = this.townships.find(
      (township) => township.id === saveObj.townshipId
    ).name;

    switch (
      this.townships.find((township) => township.id === saveObj.townshipId)
        .voucherPrefix
    ) {
      case 'groene':
        saveObj.townshipEnvironment = '../../assets/images/logos/groenebon.png';
        break;
      case 'lokale':
        saveObj.townshipEnvironment = '../../assets/images/logos/lokalebon.png';
        break;
      case 'toegangs':
        saveObj.townshipEnvironment =
          '../../assets/images/logos/toegangsbon.png';
        break;
      case 'duurzaamwonen':
        saveObj.townshipEnvironment =
          '../../assets/images/logos/duurzaambon.png';
        break;
    }

    await setDoc(
      doc(
        this.db.firestore,
        `globalSettings/townships/commissionSettings/${saveObj.townshipId}`
      ),
      { ...saveObj },
      { merge: true }
    );

    saveObj.date = new Date();

    await setDoc(
      doc(
        this.db.firestore,
        `globalSettings/townships/commissionSettings/${saveObj.townshipId}/history/${saveObj.date}`
      ),
      { ...saveObj },
      { merge: true }
    );

    this.saving = false;
    this.dialogRef.close();
  }

  downloadInvoice(url: string) {
    window.open(url, '_blank');
  }

  async deleteInvoice(invoiceType: string) {
    try {
      let dbFieldName;
      let storageFileName;
      switch (invoiceType) {
        case 'township':
          dbFieldName = 'invoiceTemplateTownship';
          storageFileName = 'FACTUURT-Template.pdf';
          break;
        case 'organisation':
          dbFieldName = 'invoiceTemplateOrganisation';
          storageFileName = 'FACTUURO-Template.pdf';
          break;
      }
      const pathRef = ref(
        this.st.storage,
        `/${this.townshipId}/pdf-templates/${storageFileName}`
      );
      await deleteObject(pathRef);
      await setDoc(
        doc(
          this.db.firestore,
          `globalSettings/townships/commissionSettings/${this.townshipId}`
        ),
        {
          [dbFieldName]: deleteField(),
        },
        { merge: true }
      );
      this[dbFieldName] = null;
      await this.setInvoiceNumberOldCosts();
    } catch (error) {
      console.log(error);
    }
  }

  async uploadInvoiceTemplate(event, invoiceType: string) {
    try {
      let dbFieldName;
      let storageFileName;
      switch (invoiceType) {
        case 'township':
          dbFieldName = 'invoiceTemplateTownship';
          storageFileName = 'FACTUURT-Template.pdf';
          break;
        case 'organisation':
          dbFieldName = 'invoiceTemplateOrganisation';
          storageFileName = 'FACTUURO-Template.pdf';
          break;
      }
      const file = event.target.files[0] as File;
      console.log(file);
      const pathRef = ref(
        this.st.storage,
        `/${this.townshipId}/pdf-templates/${storageFileName}`
      );
      console.log('pathref', pathRef);
      const result = await uploadBytes(pathRef, file);
      const fileDownloadUrl = await getDownloadURL(pathRef);
      console.log('result', result);
      await setDoc(
        doc(
          this.db.firestore,
          `globalSettings/townships/commissionSettings/${this.townshipId}`
        ),
        {
          [dbFieldName]: fileDownloadUrl,
        },
        { merge: true }
      );
      this[dbFieldName] = fileDownloadUrl;
      await this.setInvoiceNumberOldCosts();
    } catch (error) {
      console.log(error);
    }
  }

  openFileInput(htmlId) {
    const element: HTMLElement = document.getElementById(htmlId) as HTMLElement;
    element.click();
  }

  async setInvoiceNumberOldCosts() {
    const newInvoiceNumber: string = `WMN${moment(new Date()).format(
      'YYYYMMDDHHmmss'
    )}`;
    (
      await getDocs(
        collection(
          this.db.firestore,
          `globalSettings/townships/commissionSettings/${this.townshipId}/monthlyCosts`
        )
      )
    ).forEach(async (monthlyCost) => {
      const monthlyCostData = {
        ...monthlyCost.data(),
        id: monthlyCost.id,
      } as MonthlyCostDoc;

      if (!monthlyCostData.invoiceNumber) {
        await setDoc(
          doc(
            this.db.firestore,
            `globalSettings/townships/commissionSettings/${this.townshipId}/monthlyCosts/${monthlyCostData.id}`
          ),
          {
            invoiceNumber: newInvoiceNumber,
          },
          { merge: true }
        );
      }
    });
  }

  async getDBData() {
    (
      await getDocs(
        query(collection(this.db.firestore, 'township'), orderBy('name'))
      )
    ).forEach((township) => {
      const townshipData = { ...township.data(), id: township.id };
      this.townships.push(townshipData);
    });

    (
      await getDocs(
        collection(
          this.db.firestore,
          `globalSettings/townships/commissionSettings`
        )
      )
    ).forEach((commissionFile) => {
      const commissionFileData = {
        ...commissionFile.data(),
        id: commissionFile.id,
      } as CommissionSettings;
      if (
        this.data.townshipFileId &&
        this.data.townshipFileId === commissionFileData.id
      ) {
        this.commissionSettings = commissionFileData;
      }
      this.commissionFiles.push(commissionFileData);
    });

    if (this.data.townshipFileId) {
      this.commissionForm.patchValue(this.commissionSettings);
      this.originalValues = {
        btwPercentage: this.commissionSettings.btwPercentage,
        commissionType: this.commissionSettings
          .commissionType as CommissionType,
        commissionPercentageTownship:
          this.commissionSettings.commissionPercentageTownship,
      };
      this.commissionForm.controls.townshipName.setValue(
        this.data.townshipFileId
      );
      this.commissionForm.controls.townshipName.disable();
      if (this.commissionSettings) {
        this.invoiceTemplateTownship =
          this.commissionSettings.invoiceTemplateTownship;

        this.invoiceTemplateOrganisation =
          this.commissionSettings.invoiceTemplateOrganisation;
      }
    } else {
      this.townships = this.townships.filter(
        (township) =>
          !this.commissionFiles.find(
            (commissionFile) => township.id === commissionFile.id
          ) && !township.hideSource === true
      );
      if (this.townships.find((township) => township.id === this.townshipId)) {
        this.commissionForm.controls.townshipName.setValue(this.townshipId);
      }
      this.originalValues = {
        btwPercentage: this.commissionForm.controls.btwPercentage.value,
        commissionType: this.commissionForm.controls.commissionType.value,
        commissionPercentageTownship:
          this.commissionForm.controls.commissionPercentageTownship.value,
      };
    }

    this.staffelId = this.townships.find(
      (township) => township.id === this.townshipId
    )?.staffelId;

    if (this.staffelId) {
      const staffelData = (
        await getDoc(
          doc(
            this.db.firestore,
            `globalSettings/staffelFiles/staffels/${this.staffelId}`
          )
        )
      ).data() as StaffelFile;
      this.commissionForm.controls.commissionPercentageTownship.setValue(
        staffelData.commissionDiscount.find((discount) => {
          return (
            staffelData.totalValue >= discount.startValue &&
            staffelData.totalValue <= discount.endValue
          );
        }).percentage
      );

      this.commissionForm.controls.commissionPercentageTownship.disable();
      this.commissionForm.addControl(
        'staffelName',
        new UntypedFormControl({ value: staffelData.name, disabled: true }, [
          Validators.required,
        ])
      );

      this.originalValues = {
        btwPercentage: this.commissionForm.controls.btwPercentage.value,
        commissionType: this.commissionForm.controls.commissionType.value,
        commissionPercentageTownship:
          this.commissionForm.controls.commissionPercentageTownship.value,
      };
    }
  }
}
